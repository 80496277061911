<template>
    <div>
        <Renderer :ref="ref_value" :config="root_config" @dataUpdateEvent="handleUpdateEvent"/>
    </div>
</template>

<script>
import { scorecardComputed } from '@/state/helpers';
import Renderer from '../../../widgets/treeTable/renderer.vue';

export default {
    data:() => ({
        scorecard_productivity:{
            enabled: false,
            value: 0
        },
        scorecard_values:{
            enabled: false,
            value: 0
        },
        scorecard_objectives:{
            enabled: false,
            value: 0
        },
    }),
    props:{
        ref_value:{
            default: () => {
                return window.webix.uid();
            },
        }
    },
    components:{
        Renderer,
    },
    watch:{
        total_weightings:{
            immediate: true,
            handler(value){
                this.$emit('updateSectionWeightingTotal', value);
            }
        }
    },
    computed:{
        ...scorecardComputed,
        //* objectives
        total_weightings(){
            let total = 0;
            if(this.scorecard_productivity.enabled){
                total += Number(this.scorecard_productivity.value);
            }
            if(this.scorecard_values.enabled){
                total += Number(this.scorecard_values.value);
            }
            if(this.scorecard_objectives.enabled){
                total += Number(this.scorecard_objectives.value);
            }

            return total;
        },
        objective_section(){
            if(this.loaded_template == null){
                return null;
            }
            let section = this.loaded_template.sections.find( section => { return section.type_name == 'objectives'; })
            return section;
        },
        objective_section_value(){
            return this.objective_section ? this.objective_section.section_weighting : null;
        },
        objective_section_enabled(){
            return this.objective_section ? true : false;
        },

        //* values
        values_section(){
            if(this.loaded_template == null){
                return null;
            }
            let section = this.loaded_template.sections.find( section => { return section.type_name == 'values'; })
            return section;
        },
        values_section_value(){
            return this.values_section ? this.values_section.section_weighting : null;
        },
        values_section_enabled(){
            return this.values_section ? true : false;
        },

        //* productivity
        productivity_section(){
            if(this.loaded_template == null){
                return null;
            }
            let section = this.loaded_template.sections.find( section => { return section.type_name == 'productivity'; })
            return section;
        },
        productivity_section_value(){
            return this.productivity_section ? this.productivity_section.section_weighting : null;
        },
        productivity_section_enabled(){
            return this.productivity_section ? true : false;
        },

        root_config(){
            let cfg = {
                id: 'root_scorecard_builder',// scorecard ID
                expanded: true,
                dark_header: true,
                child_type: 'component', // component or row or custom_component
                hide_header: false, // should only check for true and undefined
                borders:['right'],
                columns:[
                    {
                        id: 'name',
                        width: 'fillspace',
                        header: {
                            classes: ['d-flex', 'my-auto' , 'ps-2'],
                            components: [
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        value: 'Total Score',
                                    }
                                },
                            ]
                            
                        },
                        row:{
                            components: [
                                {
                                    component_id: 'text_label',
                                }
                            ],
                        }
                    },
                    {
                        id: 'total_score',
                        width: '370px',                     
                        header: {
                            text: '',
                            classes: ['d-flex', 'ms-auto', 'pe-2'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        no_wrap: true,
                                        value: '(Sum of : Values, Objectives, Productivity)',
                                    }
                                },
                            ]
                        }
                    }
                ],
                children:[
                    this.values_config,
                    this.objectives_config,
                    this.productivity_config,
                ],
            }
            return cfg;
        },
        values_config(){
            let cfg = {
                ref: 'scorecard_values',
                id: 'scorecard_values',
                expanded: true,
                type: 'values',
                master_checkbox_variable_id: 'checkbox',
                child_type: 'row', // component or row
                hide_header: false, // should only check for true and undefined
                columns:[
                    {
                        id: 'name',
                        width: 'fillspace',
                        header: {
                            classes:['d-flex','my-auto','purple_dark', 'ps-2'],
                            text: '',
                            components: [
                                {
                                    component_id: 'checkbox_label',
                                    variable_id: 'checkbox',
                                    meta:{
                                        value: this.values_section_enabled,
                                    },
                                    element_classes: ['me-2']
                                },
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        value: 'Values',
                                    }
                                },
                            ]
                        },
                        row:{
                            classes:['d-flex','row_borders'],
                            components: [
                                {
                                    component_id: 'text_label',
                                }
                            ],
                        }
                    },
                    {
                        id: 'weight',
                        width: '220px',                     
                        header: {
                            text: '',
                            classes: ['d-flex', 'ms-auto', 'pe-2'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        no_wrap: true,
                                        value: '* ( set GROUP WEIGHT )',
                                    }
                                },
                                {
                                    component_id: 'text_input',
                                    meta:{
                                        value: this.values_section_value,
                                        placeholder: ' - ',
                                        width: '40'
                                    },
                                    variable_id: 'section_weighting'
                                }
                            ]
                        }
                    }
                ],
                children:[],
            }
            return cfg;
        },
        objectives_config(){
            let cfg = {
                ref: 'scorecard_objectives',
                id: 'scorecard_objectives',
                expanded: false,
                type: 'values',
                child_type: 'row', // component or row
                hide_header: false, // should only check for true and undefined
                columns:[
                    {
                        id: 'name',
                        width: 'fillspace',
                        header: {
                            classes:['d-flex','my-auto','purple_dark', 'ps-2'],
                            text: '',
                            components: [
                                {
                                    component_id: 'checkbox_label',
                                    variable_id: 'checkbox',
                                    meta:{
                                        value: this.objective_section_enabled,
                                    },
                                    element_classes: ['me-2']
                                },
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        no_wrap: true,
                                        value: 'Objectives',
                                    }
                                },
                            ]
                        },
                        row:{
                            classes:['d-flex','row_borders'],
                            components: [
                                {
                                    component_id: 'text_label',
                                }
                            ],
                        }
                    },
                    {
                        id: 'weight',
                        width: '220px',                     
                        header: {
                            text: '',
                            classes: ['d-flex', 'ms-auto', 'pe-2'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        no_wrap: true,
                                        value: '* ( set GROUP WEIGHT )',
                                    }
                                },
                                {
                                    component_id: 'text_input',
                                    meta:{
                                        placeholder: ' - ',
                                        width: '40',
                                        value: this.objective_section_value,
                                    },
                                    variable_id: 'section_weighting'
                                }
                            ]
                        }
                    }
                ],
                children:[],
            }
            return cfg;
        },
        productivity_config(){
            let cfg = {
                ref: 'scorecard_productivity',
                id: 'scorecard_productivity',
                expanded: false,
                type: 'values',
                child_type: 'row', // component or row
                hide_header: false, // should only check for true and undefined
                columns:[
                    {
                        id: 'name',
                        width: 'fillspace',
                        header: {
                            classes:['d-flex','my-auto','purple_dark', 'ps-2'],
                            text: '',
                            components: [
                                {
                                    component_id: 'checkbox_label',
                                    variable_id: 'checkbox',
                                    meta:{
                                        value: this.productivity_section_enabled,
                                    },
                                    element_classes: ['me-2']
                                },
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        no_wrap: true,
                                        value: 'Productivity',
                                    }
                                },
                            ]
                        },
                        row:{
                            classes:['d-flex','row_borders'],
                            components: [
                                {
                                    component_id: 'text_label',
                                }
                            ],
                        }
                    },
                    {
                        id: 'weight',
                        width: '220px',                     
                        header: {
                            text: '',
                            classes: ['d-flex', 'ms-auto', 'pe-2'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        no_wrap: true,
                                        value: '* ( set GROUP WEIGHT )',
                                    }
                                },
                                {
                                    component_id: 'text_input',
                                    meta:{
                                        value: this.productivity_section_value,
                                        placeholder: ' - ',
                                        width: '40'
                                    },
                                    variable_id: 'section_weighting'
                                }
                            ]
                        }
                    }
                ],
                children:[],
            }
            return cfg;
        },
    },
    methods:{
        handleUpdateEvent(event_payload){
            console.log('UPDATE EVENT', event_payload);
            if(event_payload.data.column_index == 0){
                // use id to map to data property
                this[event_payload.id].enabled = event_payload.data.value;
            }
            else{
                this[event_payload.id].value = event_payload.data.value;
            }
            // if(event_payload.id == 'scorecard_productivity'){
            //     if(data.)
            // }
            // if(event_payload.id == 'scorecard_objectives'){
                
            // }
            // if(event_payload.id == 'scorecard_values'){
                
            // }
        },
        returnComponentData(){
            let scorecard_objectives = this.$refs[this.ref_value].$refs['scorecard_objectives'][0].getComponentData('header')[0];
            let scorecard_values = this.$refs[this.ref_value].$refs['scorecard_values'][0].getComponentData('header')[0];
            let scorecard_productivity = this.$refs[this.ref_value].$refs['scorecard_productivity'][0].getComponentData('header')[0];
            
            let sections = [];

            if(scorecard_objectives.checkbox){
                sections.push({
                    id: -1,
                    type_name: 'objectives',
                    order:null,
                    section_weighting: scorecard_objectives.section_weighting,
                })
            }
            if(scorecard_values.checkbox){
                sections.push({
                    id: -1,
                    type_name: 'values',
                    order:null,
                    section_weighting: scorecard_values.section_weighting,
                })
            }
            if(scorecard_productivity.checkbox){
                sections.push({
                    id: -1,
                    type_name: 'productivity',
                    order:null,
                    section_weighting: scorecard_productivity.section_weighting,
                })
            }

            //* here we set the order index of valid selected sections
            sections.forEach( (section, index) => {
                section.order = index + 1;
            })
            return sections;
        },
    },
    mounted(){},
}
</script>

<style>

</style>