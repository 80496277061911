var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"m-1"},[_vm._m(0),_c('div',{staticClass:"d-flex w-100",staticStyle:{"justify-content":"end"}},[(_vm.selectedId != -1)?_c('b-button',{staticClass:"me-1 dropdown-menu-end",attrs:{"size":"sm","variant":"danger","right":""},on:{"click":_vm.deleteCard}},[_c('strong',[_vm._v("Delete Selected Card")])]):_vm._e(),_c('b-button',{staticClass:"me-1 dropdown-menu-end",staticStyle:{"color":"#50A5F1"},attrs:{"size":"sm","variant":"outline","right":""},on:{"click":_vm.uploadFile}},[_vm._v(" add Document to Scorecard + ")]),_c('b-form-file',{staticClass:"mt-3",staticStyle:{"display":"none"},attrs:{"variant":"outline","id":"uploadFile","accept":".jpg, .png, .pdf","browse-text":"Add Document +"},on:{"change":function($event){return _vm.filesChange()}},model:{value:(_vm.document),callback:function ($$v) {_vm.document=$$v},expression:"document"}})],1),_c('div',[_c('span',{},[_vm._v("Uploaded by You")]),_c('b-alert',{staticClass:"m-2",attrs:{"variant":"primary","show":_vm.userData.length == 0}},[_vm._v(" No Documents for User ")])],1),(_vm.userData.length > 0)?_c('Swiper',{attrs:{"id":"userSwiper","slides-per-view":4,"space-between":20,"allowTouchMove":false,"draggable":false,"loop":false,"pagination":{
        el: '.swiper-pagination2',
        clickable: true,
      },"breakpoints":{
      0: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      1090: {
        slidesPerView: 2,
        spaceBetween: 0
      },
      1440: {
        slidesPerView: 3,
        spaceBetween: 0
      },
      1780: {
        slidesPerView: 4,
        spaceBetween: 0
      },
      2200: {
        slidesPerView: 5,
        spaceBetween: 0
      }
    },"navigation":{
      nextEl: '.user-next',
      prevEl: '.user-prev',
    }},on:{"swiper":_vm.onSwiper,"slideChange":_vm.onSlideChange}},_vm._l((_vm.userData),function(item){return _c('SwiperSlide',{key:item.id,staticClass:"test",class:{ test_2: true }},[_c('b-card',{class:_vm.selectedId == item.id ? 'selected_doc_card m-2 p-2 document_card_styles' : 'm-2 p-2 document_card_styles',on:{"click":function($event){return _vm.selectCard(item.id)}}},[_c('div',{staticClass:"d-flex",staticStyle:{"justify-content":"space-between"}},[_c('span',{staticClass:"d-flex",staticStyle:{"flex-direction":"column"}},[_c('span',{staticStyle:{"margin":"2px"}},[_vm._v(" "+_vm._s(item.document_name)+" ")]),_c('span',{staticStyle:{"margin":"2px"}},[_vm._v(" "+_vm._s(_vm.getDate(item.created_at))+" ")]),_c('span',{staticStyle:{"margin":"2px"}},[_vm._v(" "+_vm._s(_vm.getUser(item.upload_user_id))+" ")])])])])],1)}),1):_vm._e(),(_vm.userData.length > 0)?_c('div',{staticClass:"carousel__controls"},[_c('font-awesome-icon',{staticClass:"my-button-prev user-prev",attrs:{"icon":"fa-angle-left"}}),_c('div',{staticClass:"swiper-pagination2"}),_c('font-awesome-icon',{staticClass:"my-button-next user-next",attrs:{"icon":"fa-angle-right"}})],1):_vm._e()],1),_c('div',[_c('div',{staticClass:"m-1"},[_c('span',[_vm._v("Uploaded by Manager")]),_c('b-alert',{staticClass:"m-2",attrs:{"variant":"primary","show":_vm.managerData.length == 0}},[_vm._v(" No Documents for Manager ")])],1),(_vm.managerData.length > 0 && !_vm.loading)?_c('Swiper',{attrs:{"slides-per-view":4,"space-between":20,"loop":false,"pagination":{
        el: '.swiper-pagination1',
        clickable: true,
      },"breakpoints":{
        0: {
          slidesPerView: 1,
          spaceBetween: 0
        },
        1090: {
          slidesPerView: 2,
          spaceBetween: 0
        },
        1440: {
          slidesPerView: 3,
          spaceBetween: 0
        },
        1780: {
          slidesPerView: 4,
          spaceBetween: 0
        },
        2200: {
          slidesPerView: 5,
          spaceBetween: 0
        }
      },"navigation":{
      nextEl: '.manager-next',
      prevEl: '.manager-prev',
    }},on:{"swiper":_vm.onSwiper,"slideChange":_vm.onSlideChange}},_vm._l((_vm.managerData),function(item){return _c('SwiperSlide',{key:item.id,staticClass:"test",class:{ test_2: true }},[_c('b-card',{class:_vm.selectedId == item.id ? 'selected_doc_card m-2 p-2 document_card_styles' : 'm-2 p-2 document_card_styles',on:{"click":function($event){return _vm.selectCard(item.id)}}},[_c('div',{staticClass:"d-flex",staticStyle:{"justify-content":"space-between"}},[_c('span',{staticClass:"d-flex",staticStyle:{"flex-direction":"column"}},[_c('span',{staticStyle:{"margin":"2px"}},[_vm._v(" "+_vm._s(item.document_name)+" ")]),_c('span',{staticStyle:{"margin":"2px"}},[_vm._v(" "+_vm._s(_vm.getDate(item.created_at))+" ")]),_c('span',{staticStyle:{"margin":"2px"}},[_vm._v(" "+_vm._s(_vm.getUser(item.upload_user_id))+" ")])])])])],1)}),1):_vm._e(),(_vm.managerData.length > 5)?_c('div',{staticClass:"carousel__controls"},[_c('font-awesome-icon',{staticClass:"my-button-prev manager-prev",attrs:{"icon":"fa-angle-left"}}),_c('div',{staticClass:"swiper-pagination1"}),_c('font-awesome-icon',{staticClass:"my-button-next manager-next",attrs:{"icon":"fa-angle-right"}})],1):_vm._e()],1),(_vm.show_document_modal)?_c('ScorecardDocDetails',{attrs:{"data":_vm.modal_payload,"type":_vm.viewType}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-2"},[_vm._v("Showing "),_c('b',[_vm._v("All Documents")]),_vm._v(" uploaded on your "),_c('b',[_vm._v("Performance Scorecard")]),_vm._v(" across all "),_c('b',[_vm._v("items, sections and categories.")]),_c('br'),_vm._v(" You cannot remove documents that "),_c('b',[_vm._v("were added by your Manager or ")])])
}]

export { render, staticRenderFns }