<template>
    <div class="element-wrapper d-flex " >
        <span  class="element-content d-flex my-auto">
            <span :style="getValidationColor">*</span>
            <b-form-input :disabled="hasRowData == false" :style="getInputWidth" :placeholder="getPlaceholderText" size="sm" v-model="local_value"></b-form-input>
            <!-- <font-awesome-icon :style="'margin-left: 80px;'" v-show="meta.reset_icon" class="field_reset_icon" icon="fa-undo"></font-awesome-icon> -->
        </span>

    </div>
        
</template>

<script>

import treeComponentMixin from '@/mixins/treeComponentMixin.js'
export default {
    name: 'text_input',
    mixins: [ treeComponentMixin ],
    inject:['renderer_config'],
    props:{
        meta:{},
        row_data:{},
        header_row_data:{},
    },
    components:{},
    data:() => ({
        local_value: '',
        has_mounted: false,
    }),
    watch:{
        local_value(new_value){
            if(this.has_mounted){
                this.$emit('updatedValue',new_value );
            }
        }
    },

    computed:{
        isMasterCheckboxDisabled(){
            if(this.header_row_data == undefined){
                return false;
            }

            let is_disabled = false;
            let has_master_checkbox = this.renderer_config.master_checkbox_variable_id
            if(has_master_checkbox && this.header_row_data[this.renderer_config.master_checkbox_variable_id] == false){
                is_disabled = true;
            }
            return is_disabled
        },
        getInputWidth(){
            if(this.meta && this.meta.width){
                return `width: ${this.meta.width}px`;
            }
            else{
                return 'width: 260px;'
            }
        },
        hasRowData(){
            return this.row_data != undefined;
        },
        getValidationColor(){
            let color_style = 'color: transparent;';
            if(this.hasRowData && !this.local_value){
                color_style = 'color: red;';
            }
            return color_style;
        },
        getPlaceholderText(){
            let placeholder_text = '';
            if(this.hasRowData && this.meta.placeholder){
                placeholder_text = this.meta.placeholder;
            }
            return placeholder_text;
        }
    },
    methods:{
        handleClick(){
            console.log(this.new_row)
        }
    },
    mounted(){
        if(this.meta){
            this.local_value = this.meta.value
        }
        this.$nextTick(()=>{
            this.has_mounted = true;
        })
        if(this.meta.placeholder == ' - '){
            console.log('ROW DATA', this.row_data)
        }
        
    },
}
</script>

<style>
/* .scored_card_red {
    background-color: #F46A6A
}
.amber {
    background-color: #FFA800;
}
.green {
    background-color: #34C38F;
} */

.badge_styles_larger {
    max-width: 116px !important;
    font-size: 9px !important;
}

/* .badge_styles {
    color: white;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    font-size: 10px;
    display: flex;
    margin: 0 auto;
    min-width: 40px;
    min-height: 20px;
    border-radius: 0.15rem;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    text-wrap: nowrap;
} */
/* .row_height {
    min-height: 50px;
} */
/* .line {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
} */

.field_reset_icon{
    margin-top: auto;
    margin-bottom: auto;
    border: 1px solid grey;
    border-radius: 0.55rem;
    width: 8px;
    height: 8px;
    padding: 4px;
}


</style>