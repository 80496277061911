var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"m-1"},[_vm._m(0),_c('div',[_c('span',{staticClass:"mt-2"},[_vm._v("KPIs")]),_c('b-alert',{staticClass:"m-2",attrs:{"variant":"primary","show":_vm.userData.length == 0}},[_vm._v(" No KPIs for User ")])],1),(_vm.userData.length > 0)?_c('Swiper',{attrs:{"id":"kpiSwiper","slides-per-view":4,"space-between":20,"allowTouchMove":false,"draggable":false,"loop":false,"pagination":{
        el: '.swiper-pagination2',
        clickable: true,
      },"breakpoints":{
        0: {
          slidesPerView: 1,
          spaceBetween: 10
        },
        1090: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        1440: {
          slidesPerView: 4,
          spaceBetween: 10
        },
        1780: {
          slidesPerView: 5,
          spaceBetween: 10
        },
        2200: {
          slidesPerView: 5,
          spaceBetween: 10
        }
      },"navigation":{
        nextEl: '.user-next',
        prevEl: '.user-prev',
      }},on:{"swiper":_vm.onSwiper,"slideChange":_vm.onSlideChange}},_vm._l((_vm.userData),function(item){return _c('SwiperSlide',{key:item.id},[_c('b-card',{staticClass:"kpi_card_styles",on:{"click":function($event){return _vm.selectCard(item.id)}}},[(item.tracking.length > 1 && !_vm.loading)?_c('apexchart',{attrs:{"type":"line","height":"100","width":"337","options":_vm.chartOptions,"series":[{name: 'Activity',data: _vm.sparkline_series(item)}]}}):_c('b-alert',{staticClass:"mt-1 mb-0",attrs:{"show":""}},[_vm._v(" Not enough data to display graph ")])],1)],1)}),1):_vm._e(),(_vm.userData.length > 0)?_c('div',{staticClass:"carousel__controls"},[_c('font-awesome-icon',{staticClass:"my-button-prev user-prev",attrs:{"icon":"fa-angle-left"}}),_c('div',{staticClass:"swiper-pagination2"}),_c('font-awesome-icon',{staticClass:"my-button-next user-next",attrs:{"icon":"fa-angle-right"}})],1):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-2 mt-2"},[_vm._v(" Showing "),_c('b',[_vm._v("five")]),_vm._v(" KPI graphs for for a "),_c('b',[_vm._v("Productivity Target Item")]),_vm._v(" in a "),_c('b',[_vm._v("Yearly Review")]),_vm._v(" on your "),_c('b',[_vm._v("Performance Scorecard.")])])
}]

export { render, staticRenderFns }