<template>
    <div class="treeTableRenderer" :id="config.id">
        <div class="w-100" style="display:inline-flex; align-items: center;">
            <slot name="prepend"></slot>
            
            <div v-if="showHeader" class="d-flex w-100" :class="getHeaderClass" :style="getHeaderHeight">
                <!-- //* START building header columns -->
                <div v-for="(column, column_index) in config.columns" :key="column.id" class="d-flex border-row border-light" :style="getStyles(column)">
                    <div v-if="column.header" :class="getClasses(column, 'header')">
                        {{ column.header.text }}
                        <!-- insert dynamic elements if available -->
                        <span v-for="(component, idx) in column.header.components"
                            :ref="component.component_id"
                            :class="component.element_classes"
                            :is="component.component_id"
                            :meta="component.meta"
                            :parent_settings="settings"
                            :row_data="getHeaderRowData"
                            @updatedValue="updateHeaderValueEvent(column_index, idx, $event)"
                            @handleClickAction="handleClickAction"
                            :key="idx">
                        </span>
                    </div>
                </div>
            </div>
            <div v-if="!showHeader && local_config.children.length < 0" style="height: 10px; width: 100%;" class="d-flex bg-danger bg-soft"></div>
            
            
            <div class="ms-1" v-if="config.detailed_view_button">
                <b-button @click="setView(config.type)" size="sm">></b-button>
            </div>
        </div>


        
        <b-collapse class="m-0 p-0 ms-1" v-model="settings.is_expanded" > <!-- style="padding-right: 24px !important" -->
            <transition name="fade">
            <section v-show="settings.is_expanded">
                <div v-if="config.child_type == 'component'">
                    <draggable v-model="local_config.children" group="components" ghost-class="item_row_ghost" @start="is_dragingg=true" @end="is_dragingg=false">
                        <!-- eslint-disable-next-line -->
                        <Renderer @showDetailedView="$emit('showDetailedView', $event)" v-for="(config, config_index) in local_config.children" :ref="getSubComponentRef(config)" :key="config.id" :config="config" class="mx-1" @dataUpdateEvent="emitDataUpdatedEvent($event)">
                            
                        </Renderer>
                    </draggable>
                </div>
                <div v-else class="ps-2">
                    <!-- Temporary height values given to be removed. Also added the classes method to center the row elements -->
                    <draggable v-model="local_mapped_data" group="rows" ghost-class="item_row_ghost" @start="is_dragingg=true" @end="is_dragingg=false">
                        <!-- <transition-group name="fade"> -->
                        <span  v-for="(child, child_index) in local_mapped_data" :key="child.id" @click.stop="setView(config.type, child.id)"
                                class="d-flex row_border_left row_item row_item_hover"
                                :class="[isRowSelected(child.id), child.new_row ? 'new_row_grey' : '']"
                                @click="handleRowClick(child)"
                                :style="[getRowHeight, getStyles(child.row_array[0])]"
                                @mouseover="row_hover_id = child.id" @mouseleave="row_hover_id = null">
                            <span v-for="(element, idx) in child.row_array" :key="idx" class="d-flex" :class="getClasses(element)" :style="getStyles(element)"  > <!-- :style="getStyles(element)" -->
                                <span v-for="(component, component_index) in element.row.components" :key="component_index"
                                    :is="component.component_id"
                                    :row_position="{index: child_index, length: child.array_length}"
                                    :class="component.element_classes"
                                    :meta="component.meta"
                                    :header_row_data="getHeaderRowData"
                                    :row_data="local_config.children[child_index]"
                                    @updatedValue="updateRowValueEvent(child_index, idx, component_index, component.variable_id, $event)"
                                    @handleClickAction="handleClickAction"
                                    @handleAction="handleElementAction"
                                >
                                </span>
                            </span>
                            <transition name="fade">
                                <span v-show="false && row_hover_id == child.id" class="view_details_button" @click.stop="setView(config.type, child.id)">
                                    <font-awesome-icon class="view_details_button_content" icon="fa-angle-right"></font-awesome-icon>
                                </span>
                            </transition>
                        </span>
                        <!-- </transition-group> -->
                    </draggable>
                </div>
            </section>
        </transition>
        </b-collapse>
        <div  style="height: 5px; width: 100%;">
            <div v-for="column in config.columns" :key="column.id" :style="getStyles(column)">
                
                <div v-if="column.header">
                    
                    <div style="height: 5px;" class="w-100" @click="logConfig(column)"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { isEmpty } from 'lodash'
import './treeTable.css'
import Renderer from '@/components/widgets/treeTable/renderer.vue'
import expand_arrow from './header_elements/expand_arrow.vue'
import display_badge from './header_elements/display_badge.vue'
import checkbox_label from './header_elements/checkbox_label.vue'
import text_label from './header_elements/text_label.vue'
import comment_label from './header_elements/comment_label.vue'
import tags from './header_elements/tags.vue'
import header_value_label from './header_elements/header_value_label.vue'
import info_icon from './header_elements/info_icon.vue'
import toggle_label from './header_elements/toggle_label.vue'
import item_count from './header_elements/item_count.vue'
import empty_block from './header_elements/empty_block.vue'
import selection_checkbox from './header_elements/selection_checkbox.vue'
import add_remove_button from './header_elements/add_remove_button.vue'
import text_input from './header_elements/text_input.vue'
import review_status from './header_elements/review_status.vue'
//* misc imports
import { cloneDeep } from 'lodash';
import { treeComponentMethods, treeComponentComputed } from '@/state/helpers';
import draggable from 'vuedraggable'
export default {
    name: 'Renderer',
    components:{
        //Renderer is the current component, that is used recursively 
        Renderer,
        draggable,
        // child components that get inserted using :is
        expand_arrow,
        display_badge,
        checkbox_label,
        comment_label,
        text_label,
        tags,
        header_value_label,
        info_icon,
        toggle_label,
        item_count,
        empty_block,
        selection_checkbox,
        add_remove_button,
        text_input,
        review_status
    },
    data:() => ({
        row_hover_id: null,
        settings:{
            is_expanded: false,
            selected_id: -1,
        },
        is_dragingg: false,
        local_config: {},
        local_mapped_data:[],
        showSlideContainer: false
    }),
    provide() {
        return {
            renderer_config: this.local_config,
        }
    },
    props:{
        depth: {
            default: () => { return 0; }
        },
        config:{
            required: true,
        },
        view_data:{
            default: () => { return [1,2,3]},
        }
    },
    watch:{
        config:{
            deep: true,
            immediate: true,
            handler(){
                console.log('WATCH local_config')
                //this.mapRowData();
                this.local_config = cloneDeep(this.config);

            }
        },
        'local_config.children'(){
            //console.log('WATCH config.children');
            this.mapRowData(true);
        },
        local_mapped_data(){
            console.log('Local Mapped Data updated');
        },
        
        // mapped_data:{
        //     immediate: true,
        //     handler(){
        //         this.local_mapped_data = cloneDeep(this.mapped_data);
        //     }
        // }
    },
    computed:{
        ...treeComponentComputed,
        getHeaderRowData(){
            let item = {};
            this.local_config.columns.forEach( column => {
                column.header.components.forEach( element => {
                    if(element.variable_id){
                        item[element.variable_id] = element.meta.value;
                    }
                })
            })
            return item;
        },
        getHeaderClass(){
            let class_list = '';
            if(this.config.child_type == 'component'){
                class_list += this.local_config.children.length == 0 ? 'header_border_closed ' : 'header_border ';
            }
            else{
                class_list += this.local_mapped_data.length == 0 ? 'header_border_closed ' : 'header_border ';
            }
            class_list += this.config.dark_header ? 'purple_dark_fill header_text' : 'white_fill';
            return class_list;
        },
        getHeaderHeight(){
            //* return height for header
            let height = this.config.header_height
            ? this.config.header_height
            : '35px;';

            return `height: ${height}`;
        },
        showHeader(){
            if(this.local_config.hide_header){
                return false;
            }
            else{
                return true;
            }
        },
        getRowHeight(){
            //* return height for row
            let height = this.config.row_height
            ? this.config.row_height
            : '35px;';

            return `height: ${height}; margin-left: 2px; margin-right: 2px;`;
        },
    },
    methods:{
        ...treeComponentMethods,
        updateHeaderValueEvent(column_index, element_index, value){
            this.$set(
                this.local_config.columns[column_index].header.components[element_index].meta,
                'value',
                value
            )

            //* emit update event to parent
            let payload = {
                type: 'header_value',
                id: this.config.id,
                data:{
                    column_index: column_index,
                    element_index: element_index,
                    value: value,
                }
            }
            this.emitDataUpdatedEvent(payload);
        },
        emitDataUpdatedEvent(payload){
            this.$emit('dataUpdateEvent', payload);
        },
        updateRowValueEvent(row_index, column_index, element_index, variable_id, value){
            //* update local config that holds data array
            this.$set(
                this.local_config.children[row_index],
                variable_id,
                value
            )
            //* update the local mapped data object for the item
            this.$set(
                this.local_mapped_data[row_index].row_array[column_index].row.components[element_index].meta,
                'value',
                value
            );
            let payload = {
                type: 'row_value',
                id: this.config.id,
                data:{
                    column_index: column_index,
                    row_index: row_index,
                    value: value,
                    variable_id: variable_id
                }
            }
            this.emitDataUpdatedEvent(payload);
        },
        getSubComponentRef(sub_config){
            // return ref, or use random uid as fallback;
            return sub_config.ref ? sub_config.ref : window.webix.uid();
        },
        getComponentData(type = 'data_rows'){
            let data = [];
            switch(type){
                case 'data_rows':{
                    //* data row elements
                    this.local_mapped_data.forEach( row => {
                        if(row.new_row){
                            return;
                        }
                        let data_object = {
                            id: row.id,
                        }
                        row.row_array.forEach( column => {
                            column.row.components.forEach( element => {
                                //* only target elements with meta object
                                if(element.meta != undefined){

                                    data_object[element.variable_id] = element.meta.value
                                }
                            })
                        })
                        data.push(data_object);
                    })
                    break;
                }
                case 'header':{
                    //* header elements
                    let data_object = {};
                    // this.local_config.columns[column_index].header.components[element_index].meta
                    this.local_config.columns.forEach( column => {
                        column.header.components.forEach( element => {

                            if(element.variable_id != undefined){
                                data_object[element.variable_id] = element.meta.value;

                            }
                        })
                    })
                    if(isEmpty(data_object) == false){
                        data.push(data_object)
                    }
                    break;
                }
                case 'child_table_headers':{
                    //* header elements that are children of a table (nested tables)
                    break;
                }
                case 'child_table_data_rows':{
                    break;
                }
                default:{
                    break;
                }
            }

            return data;
        },
        handleRowClick(click){
            if(click.id == undefined){
                return;
            }

            this.settings.selected_id = click.id;
        },
        setView(type, id = null) {
            let payload = {
                type: type,
                id: id
            }
            //this.setComponentViewState(payload)   
            this.$emit('showDetailedView', payload);
        },
        handleClickAction(action_payload){
            const action = action_payload.action;
            switch(action){
                case 'expand':{
                    this.settings.is_expanded = action_payload.value;
                    break;
                }
                default:{
                    console.log('Handle Action', action);
                }
            }
        },
        handleElementAction(action_payload){
            switch(action_payload.action){
                case 'delete_row':{
                    let index = this.local_config.children.findIndex( item => {
                        return item.id == action_payload.id;
                    })
                    if(index != -1){
                        this.local_config.children.splice(index, 1);
                    }
                    break;
                }
                case 'add_row':{
                    let empty_child = {
                        id: window.webix.uid(),
                        new_row: true,
                    }
                    this.local_config.children.push(empty_child);
                    break;
                }
                default:{
                    console.log('Unhandled Action', action_payload);
                    break;
                }
            }
        },
        saveComponentState(){
            let payload = {
                component_id: this.config.id,
                component_state: this.settings,
            }
            this.setComponentState(payload);
        },

        logConfig(conf){
            console.log('CONFIG', conf);
        },

        //helper methods
        getWidth(width){
            switch(width){
                case 'fillspace': {
                    return ["flex-grow: 1;", `min-width: 200px;`];
                }
                default : {
                    return [`width: ${width};`, `min-width: ${width};`, `max-width: ${width};`];
                }
            }
        },
        isRowSelected(id){
            if(!this.config.enable_row_selection){
                return;
            }
            return id == this.settings.selected_id ? 'item_row_active' : ''
        },
        getStyles(column, is_header){
            let styles = [];
            if(is_header && column.header && column.header.styles){
                styles.push(...column.header.styles)
            }

            if(!is_header && column.row && column.row.styles){
                styles.push(...column.row.styles)
            }

            if(column.width == undefined)(
                console.warn('Config error: no width set on column')
            )
            else{
                styles.push(...this.getWidth(column.width));
            }
            //* put styles into string
            let flat_styles = '';
            styles.forEach( style => {
                flat_styles += style
            });
            return flat_styles;
        },
        getClasses(column, is_header){
            let classes = [];

            if(is_header && column.header && column.header.classes){
                classes.push(...column.header.classes)
            }

            if(!is_header && column.row && column.row.classes){
                classes.push(...column.row.classes)
            }
            return classes;
        },
        getElementPlacement(column_header){
            if(column_header.center == undefined){
                return '';
            }
            else{
                return 'mx-auto my-auto';
            }
        },

        getRowColumns(){
            let row_columns = [];
            // build base row object
            this.config.columns.forEach(( col )=>{
                //* check if column has row object
                if(col.row){
                    // get config properties of column
                    let row = cloneDeep(col);
                    if(row.header){
                        delete row.header;
                    }
                    row_columns.push(row);
                }
                //* Finished building row object 'template'
            })
            return row_columns;
        },
        getEmptyColumns(){
            let row = {
                //set row id, or use index if id is not included in data array
                id: window.webix.uid(),
                new_row: true,
                row_array: [],
                array_length: 0, // set length before pushing 
            };
            row.row_array = cloneDeep(this.getRowColumns());
            return row;
        },

        // insertEmptyRow(){
        //     let row = this.getEmptyColumns();
        //     this.local_mapped_data.push(row);
        // }

        mapRowData(use_local = false){
            if(this.config.child_type == 'component'){
                // * no need to map if children are components
                return [];
            }

            let cfg = use_local ? this.local_config : this.config;
            console.log('CONFIG', cfg);
            
            //* base row columns used to map the row data
            let row_columns = this.getRowColumns();

            //* final array of row objects
            let rows = [];

            //* loop over row data and build new row with mapped data
            
            if(cfg.children == undefined){
                console.error(`Component with id: "${this.config.id}" is missing 'children' property`)
            }
            else{
                cfg.children.forEach( (child_object, index) => {
                    let row = {
                        //set row id, or use index if id is not included in data array
                        id: child_object.id != undefined ? child_object.id : index+1,
                        row_array: [],
                        array_length: 0, // set length before pushing 
                    };
                    row.row_array = cloneDeep(row_columns);
                    
                    //let row_array = cloneDeep(row_columns);
                    row.row_array.forEach( (item) => {
                        item.row.components.forEach( component => {
                                    // if(component.variable_id == 'calculated_total_weight'){
                                    //     alert('calculated_total_weight')
                                    // }
                                    if(index == 0 && cfg.id == 'weighting_values'){
                                        console.log('weighting_values', index, component, child_object, component.variable_id ,child_object[component.variable_id])
                                    }
                            if(child_object[component.variable_id] != undefined){

                                if(component.meta == undefined){
                                    component['meta'] = {};
                                }
                                component.meta['value'] = child_object[component.variable_id]
                            }
                        })
                    })
                    //* set length property for any index based actions
                    row.array_length = this.config.children.length;
                    rows.push(row);
                })

                if(this.config.include_empty_row){
                    let row = this.getEmptyColumns();
                    rows.push(row);
                }
            }
            this.local_mapped_data = rows;
        }
    },
    mounted(){
        //* fetch saved settings from vuex, else use default options from config
        if(this.component_states[this.config.id]){
            this.settings = cloneDeep(this.component_states[this.config.id])
        }
        else{
            if(this.config.expanded){
                this.settings.is_expanded = true;
            }
        }
        this.mapRowData();
    },
    beforeDestroy(){
        this.saveComponentState();
    }
}
</script>

<style>
/* .border-row{
    background: rgb(248, 153, 226);
    background: linear-gradient(90deg, rgb(245, 74, 205) 0%, rgb(177, 242, 255) 100%);
}
.row_borders{
    background: rgb(9, 121, 54);
    background: linear-gradient(90deg, rgb(123, 253, 197) 0%, rgba(154, 213, 255, 0.953) 100%);
} */
 .row_item>span:not(.empty_element):not(.view_details_button){
    background-color: white;
 }

 .view_details_button{
    position: absolute;
    right:40px;
    

    /* width: 0px;
    height: 10px;
    display: inline-block;
    margin-left: -20px; */
 }
 .view_details_button_content {
    background-color: white;
    /* top: 50%;
    left: 100%; 
    transform: translateY(-50%);  */
    margin-left: 0px; /* Adjust for the button width */
    width: 15px; 
    height: 15px;
    border: 1px solid grey;
    border-radius: 0.2rem;
}

.row_item_hover:hover{
    font-weight: 600 !important;
    cursor: pointer;
}
</style>