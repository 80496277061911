<template>
<div class="comment_styles">
    <span class="fa-stack fa-2x">
      <font-awesome-icon style="color: #BEC8D8" :icon="['fas', 'comment-alt']" />
      <span class="fa fa-stack-1x">
          <!-- <span class="blue_dark" style="font-size:9px; margin-top:-4px; margin-left:-36px; display:block;">
              {{ getValue(meta) }}
          </span> -->
    </span>
    </span>	
</div>
</template>

<script>
import treeComponentMixin from '@/mixins/treeComponentMixin.js'
export default {
    name: 'comment_label',
    mixins: [ treeComponentMixin ],
    props:{
        meta:{}
    },
    data:() => ({
    }),
    computed:{
        //* get display value

    },
    methods:{},
    mounted(){
        console.log('meta',this.meta)
    },
}
</script>

<style>
.comment_styles {
    min-height: 50px;
}
</style>