<template>
    <div>
        <ComponentHeader class="mb-3" back-button @exit="$emit('exit')" title="Performance Scorecard" description="The performance scorecard shows your data and results for the selected role and period of your employment.  Use your scorecard to keep track of what is required of you within your role. You can make notes on individual items, your manger also has access to your scorecard and may also add notes in preparation for a review.">
            <template #extra-content>
                <HeaderCard
                    @editWeighting="handleEditWeighting"
                    @submitScorecard="handleSubmitScorecard"
                />
            </template>
            <template #extra-content2>
                <ScorecardDates />
            </template>
        </ComponentHeader>
        <TotalScore ref="totalScore"/>
        <Qualifiers v-if="is_qualifiers_enabled"/>
        <Modifiers  v-if="is_modifiers_enabled"/>
        <EditWeightingsModal v-if="show_weighting_modal"></EditWeightingsModal>
    </div>
</template>

<script>
import EditWeightingsModal from '@/components/scorecard/tableComponents/employee/modals/editWeightingModal.vue'
import { scorecardMethods, scorecardComputed } from '@/state/helpers';

import TotalScore from './tableComponents/employee/totalScore.vue';
import Qualifiers from './tableComponents/employee/qualifiers.vue';
import Modifiers from './tableComponents/employee/modifiers.vue';

import HeaderCard from './tableComponents/employee/headerCard.vue';
import ComponentHeader from '../widgets/header/componentHeader.vue';
import ScorecardDates from '../scorecard/pageComponents/scorecardDates.vue'

export default {
    provide() {
        return {
            is_values_enabled: this.is_values_enabled,
            is_productivity_enabled: this.is_productivity_enabled,
            is_objectives_enabled: this.is_objectives_enabled,
        }
    },
    components:{
        ComponentHeader,
        EditWeightingsModal,
        HeaderCard,
        TotalScore,
        Qualifiers,
        Modifiers,
        ScorecardDates
    },
    data:() => ({
        show_weighting_modal: false,
    }),
    computed:{
        ...scorecardComputed,
        is_values_enabled(){
            let secton = this.loaded_user_scorecard.sections.findIndex( section => {
                return section.type_name == 'values';
            })
            return secton != -1;
        },
        is_productivity_enabled(){
            let secton = this.loaded_user_scorecard.sections.findIndex( section => {
                return section.type_name == 'productivity';
            })
            return secton != -1;
        },
        is_objectives_enabled(){
            let secton = this.loaded_user_scorecard.sections.findIndex( section => {
                return section.type_name == 'objectives';
            })
            return secton != -1;
        },
        is_modifiers_enabled(){
            return this.loaded_user_scorecard.modifiers.length != 0;
        },
        is_qualifiers_enabled(){
            return this.loaded_user_scorecard.qualifiers.length != 0;
        },
    },
    methods:{
        ...scorecardMethods,
        handleSubmitScorecard(){
            this.submitScorecard({
                scorecard_period_id: this.loaded_user_scorecard.scorecard_period_id
            })
        },
        handleEditWeighting(){
            //* get data arrays for objectives, productivity and values - for modal
            this.setWeightModalData(this.$refs.totalScore.getComponentData());
            
            this.show_weighting_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('scorecardWeightingModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "scorecardWeightingModal") {
                        this.show_weighting_modal = false;
                    }
                });
            })
            
        }
    },
    mounted(){
        
    },
}
</script>

<style>

</style>