<template>
    <div class="element-wrapper d-flex my-auto" :class="getBorderByIndex" >
        <span class="element-content">
            <b-form-checkbox v-model="local_value" value="true" unchecked-value="false"></b-form-checkbox>
        </span>
    </div>
</template>

<script>
export default {
    name: "selection_checkbox",
    data:() => ({
        local_value: '',
        has_mounted: false,
    }),
    props:{
        row_position:{

        }
    },
    watch:{
        local_value(new_value){
            if(this.has_mounted){
                console.log('emit')
                this.$emit('updatedValue',new_value );
            }
        }
    },
    computed:{
        getBorderByIndex(){
            let index = this.row_position.index;
            let length = this.row_position.length;
            
            if(index == 0 && length == 1){
                return `selection_checkbox_top 
                        selection_checkbox_bottom 
                        selection_checkbox_left
                        selection_checkbox_right`;
            }
            if(index == 0){
                return `selection_checkbox_top 
                        selection_checkbox_left
                        selection_checkbox_right`;
            }
            if(index == length - 1){
                return `selection_checkbox_bottom
                        selection_checkbox_left
                        selection_checkbox_right`;
            }

            return `selection_checkbox_left
                    selection_checkbox_right`;
        }
    },
    methods:{},
    mounted(){
        if(this.meta){
            this.local_value = this.meta.value
        }
        this.$nextTick(()=>{
            this.has_mounted = true;
        })
        alert(this.local_value)
    },
}
</script>

<style>
    .selection_checkbox_top{
        border-top: 1px solid grey;
    }
    .selection_checkbox_bottom{
        border-bottom: 1px solid grey;
    }
    .selection_checkbox_left{
        border-left: 1px solid grey;
    }
    .selection_checkbox_right{
        border-right: 1px solid grey;
    }
</style>