<template>
<b-modal size="lg" id="scorecardDocDetails" title='File Viewer'  hide-footer>
    <div>
        <img src="https://stratosfia.s3.af-south-1.amazonaws.com/media/2k05ee32G9xRH0jcqGbpz5DmER72hwNCXDAS7An3.png" style="width: 200px; height: 200px;">
        <b-button @click="data.fetch_url"></b-button>
{{ data }}
    </div>
</b-modal>
</template>
<script>
export default {
props: {
    data: {
    required: true
    },
    type: {
    required: true
    }
},
components: {
},
methods: {
    async fetchBlob(url) {
    const response = await fetch(url);

    // Here is the significant part 
    // reading the stream as a blob instead of json
    return response.blob();
    }
},
data: () => ({
    value: 5
}),
mounted() {
    let myBlob = this.fetchBlob(this.data.s3_src);
    console.log(myBlob)
    // var URL = window.URL
    // var imageURL = URL.createObjectURL(myBlob);
    // document.querySelector("#image").src = imageURL;
}
}
</script>

<style lang="scss">
</style>