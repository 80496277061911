<template>
    <div class="" style="position: absolute;height: 50px; width: 100%;">
        <svg v-if="hasRightLine" class="h-line" height="3" width="100%">
            <line x1="50%" y1="1.5" x2="100%" y2="1.5" stroke="blue" stroke-width="3"/>
        </svg>
        <svg v-if="hasLeftLine" class="h-line" height="3" width="100%">
            <line x1="0%" y1="1.5" x2="50%" y2="1.5" stroke="blue" stroke-width="3"/>
        </svg>
        <svg v-if="hasDownLine && !is_last_element" class="v-line" height="100%" width="3">
            <line x1="1.5" y1="50px" x2="1.5" y2="26.5px" stroke="blue" stroke-width="3"/>
        </svg>
        <svg v-if="hasUpLine" class="v-line" height="100%" width="3">
            <line x1="1.5" y1="0px" x2="1.5" y2="26.5px" stroke="blue" stroke-width="3"/>
        </svg>
        <!-- <svg class="vh-line-down" width="13px" height="11px">
            <path d="M2,2 Q8,2 8,8" 
                fill="none" 
                stroke="blue" 
                stroke-width="3"/>
        </svg> -->
    </div>
</template>

<script>
export default {
    data:() => ({}),
    props:{
        connection_lines:{},
        row_index:{},
        row_length:{},
    },
    computed:{
        hasLeftLine(){
            return this.connection_lines.left;
        },
        hasRightLine(){
            return this.connection_lines.right;
        },
        hasUpLine(){
            return this.connection_lines.up;
        },
        hasDownLine(){
            return this.connection_lines.down;
        },
        is_last_element(){ 
            return (this.row_index + 1) == this.row_length;
        }
    },
    methods:{},
    mounted(){},
}
</script>

<style>
.h-line{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
}
.v-line{
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    z-index: -1;
}
.vh-line{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: -1;
}
.vh-line-down{
    /*margin-top: px;*/
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: -1;
}
</style>