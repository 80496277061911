<template>
  <div>
    <div class="m-1">
      <!-- <div class="mb-2">
          Showing <b>five</b> KPI graphs for for a <b>Productivity Target Item</b> in a <b>Yearly Review</b> on your <b>Performance Scorecard.</b> 
        </div> -->
      <div>
        <span class="mt-2">Sub Objectives</span>
        <b-alert class="m-2" variant="primary" :show="userData.length == 0">
          No Sub Objectives for User
        </b-alert>
      </div>
      <Swiper id="userSwiper" v-if="userData.length > 0" :slides-per-view="4" :space-between="20"
        :allowTouchMove="false" :draggable="false" :loop="false" :pagination="{
          el: '.swiper-pagination2',
          clickable: true,
        }" :breakpoints="{
          0: {
            slidesPerView: 1,
            spaceBetween: 0
          },
          1090: {
            slidesPerView: 2,
            spaceBetween: 0
          },
          1440: {
            slidesPerView: 3,
            spaceBetween: 0
          },
          1780: {
            slidesPerView: 4,
            spaceBetween: 0
          },
          2200: {
            slidesPerView: 5,
            spaceBetween: 0
          }
        }" :navigation="{
          nextEl: '.user-next',
          prevEl: '.user-prev',
        }" @swiper="onSwiper" @slideChange="onSlideChange">
        <SwiperSlide v-for="item in userData" :key="item.id" class="test" :class="{ test_2: true }">
          <b-card @click="selectCard(item.id)"
            :class="selectedId == item.id ? 'selected_doc_card m-2 p-2 document_card_styles' : 'm-2 p-2 document_card_styles'">
            <div class="d-flex" style="justify-content: space-between;">
              <span class="d-flex" style="flex-direction: column;">
                <span class="" style="margin: 2px">
                  {{ item.document_name }}
                </span>
                <span class="" style="margin: 2px">
                  {{ getDate(item.created_at) }}
                </span>
                <span class="" style="margin: 2px">
                  {{ getUser(item.upload_user_id) }}
                </span>
              </span>
              <div>
                <font-awesome-icon class="" icon="fa-angle-right"></font-awesome-icon>
              </div>
            </div>
          </b-card>
        </SwiperSlide>
      </Swiper>
      <div class="carousel__controls" v-if="userData.length > 0">
        <font-awesome-icon class="my-button-prev user-prev" icon="fa-angle-left"></font-awesome-icon>
        <div class="swiper-pagination2"> </div>
        <font-awesome-icon class="my-button-next user-next" icon="fa-angle-right"></font-awesome-icon>
      </div>
    </div>
  </div>

</template>

<script>
import { Navigation, Pagination } from 'swiper'
import { scorecardComputed, scorecardMethods, activityComputed, activityMethods, levelComputed } from "@/state/helpers";
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'

// Import Swiper styles
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation, Pagination])

export default {
  props: {
    viewType: {
      required: true
    }
  },
  components: {
    // eslint-disable-next-line
    Swiper,
    // eslint-disable-next-line
    SwiperSlide,
  },
  computed: {
    ...scorecardComputed,
    ...activityComputed,
    ...levelComputed
  },
  watch: {
    details_page_data() {
      this.loading = true
      this.$nextTick(() => {
        this.kpis = this.details_page_data.kpis
        this.loading = false
      })
    }
  },
  mounted() {
    this.kpis = this.details_page_data.kpis
  },
  data: () => ({
    selectedItem: -1,
    total: [],
    managerData: [],
    userData: [],
    document: null,
    selectedId: -1,
    body: '',
    is_dragingg: false,
    loading: false
  }),
  methods: {
    ...scorecardMethods,
    ...activityMethods,
    //   getUser(id) {
    //       let user_object = this.all_users.find((user) => {
    //           return user.id == id;
    //       });
    //       return user_object.name;
    //   },
    //   getDate(date) {
    //      //let dateValue = new Date(date)
    //      return format(parseISO(date), 'dd/MMM/yyyy')
    //   },
    refresh() {
      let params = {}
      switch (this.viewType) {
        case 'productivity': {
          params = { target_id: this.details_page_data.id, scorecard_period_id: this.objective_viewing_quarter };
          this.loadProductivityDetails(params).then(() => {
            this.userData = this.details_page_data.employee_docs
            this.managerData = this.details_page_data.manager_docs
          })
          break;
        }
        case 'values': {
          params = { value_id: this.details_page_data.id, scorecard_period_id: this.objective_viewing_quarter };
          this.loadValuesDetails(params).then(() => {
            this.userData = this.details_page_data.employee_docs
            this.managerData = this.details_page_data.manager_docs
          })
          break;
        }
        case 'objectives': {
          params = { objective_id: this.details_page_data.id, scorecard_period_id: this.objective_viewing_quarter };
          this.loadObjectiveDetails(params).then(() => {
            this.userData = this.details_page_data.employee_docs
            this.managerData = this.details_page_data.manager_docs
          })
          break;
        }
      }
    },
    uploadFile() {
      document.getElementById('uploadFile').click()
    },
    onSwiper(swiper) {
      console.log(swiper)
    },
    onSlideChange() {
      console.log('slide change')
    },
    selectCard(id) {
      this.selectedId = id
    }

  }
}
</script>

<style lang="scss">
.img-fluid {
  max-width: 100%;
  height: auto;
}

.w-100 {
  width: 100%;
}

.ml-auto,
.mx-auto {
  margin-left: auto;
}

.mr-auto,
.mx-auto {
  margin-right: auto;
}

.document_card_styles {
  border: 1px solid #BFC8D6 !important;
  border-radius: 0.2rem;
  max-height: 100px
}

.selected_doc_card {
  border: 2px solid #50A5F1 !important;
}

.carousel {
  &__controls {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-top: 1px;
  }
}

// .my-button-prev,
// .my-button-next {
//     height: 26px !important;
//     width: 34px !important;
// }

.swiper-pagination {
  width: unset;
  position: unset;
  display: flex;
}

#userSwiper {
  max-height: 116px;
}

.swiper-pagination-bullet {
  margin: 4px !important;
}
</style>