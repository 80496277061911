<template>
    <!-- <transition name="slide-fade" mode="out-in"> -->
        <div v-if="show" class="global-details-container" :style="is_child_container ? 'z-index:1450; background-color: transparent !important;' : ''">
            <div class="width: 100vw;" style="display: flex; justify-content: center;">
                    <div  class=""  :style="is_child_container ? 'width: 100vw;' : 'width: 100vw;'" style="height: 100vh;">
                        <transition name="slide-fade" mode="out-in">
                            <div v-if="local_show" class="border border-grey bg-light" style="height: 100vh; overflow-x: hidden; overflow-y: auto;">
                                <div class="d-flex mt-2" :class="is_child_container ? 'w-100': 'w-100'" style="justify-content: center;">
                                    <div style="width: 100%; min-width: 1366px; overflow-x: auto; max-width: 1920px;" class="p-3">
                                        <slot>
                                        </slot>
                                    </div>
                                </div>
                            </div>  
                    </transition>
                    </div>
            </div>
            
        </div>
<!-- </transition> -->
</template>

<script>
import { uxToggleMethods, uxToggleComputed} from '@/state/helpers'
export default {
    props:{
        show:{
            default: () => { return true }
        }
    },
    watch:{
        show:{
            immediate: true,
            handler(value){
                this.$nextTick(()=>{
                    this.local_show = value;
                })
            }
        }
    },
    data:() => ({
        is_child_container:false,
        local_show: false,
    }),
    computed:{
        ...uxToggleComputed,
    },
    methods:{
        logMe(){
            alert(1);
        },
        ...uxToggleMethods,
        handleExit(){
            this.local_show = false;
            this.exitCleanUp();
            setTimeout(()=>{
                this.$emit('exit');
            }, 1000)
        },

        exitCleanUp(){
            if(this.is_child_container == false){
                //* only remove vuex flag if the container is not a child
                this.setDetailedViewContainerState(false);
            }
        }
    },
    mounted(){
        if(this.detailed_view_container_active){
            //* set flag so that the vuex flag doesnt get unset in the destroy hook
            this.is_child_container = true;
        }
        else{
            this.setDetailedViewContainerState(true);
        }
    },
    beforeDestroy(){
        this.exitCleanUp();
        
    }
}
</script>

<style >
.half{
    width: 50% !important;
    height: 50% !important;
    background-color: transparent !important;
}
.global-details-container{
    z-index: 1040;
    position: fixed; /* Stay in place */
  top: 0;
  left: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
}

/* Slide-in transition */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: transform 0.4s ease-in-out;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100%);
}

.slide-fade-enter-to {
  transform: translateX(0);
}

.slide-fade-leave {
  transform: translateX(0);
}

.slide-fade-leave-to {
  transform: translateX(100%);
}
</style>