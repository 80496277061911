<template>
    <div>
        <b-row class="m-0 p-0 w-100">
            <b-col class="m-0 p-0" cols="5" v-if="!hasYearSelected">
                <b-alert show>
                    Year selection required to view scorecard 
                </b-alert>
            </b-col>
            <b-col class="m-0 p-0" cols="5" v-if="!hasValidPeriodSelected">
                    <b-alert show>
                        Select Q1-Q4 or H1-H2 to view scorecard
                    </b-alert>
            </b-col>
        </b-row>
        <div class="d-flex p-1">
            <b-button variant="outline-danger" size="sm" v-show="search_user_names != ''" @click="search_user_names = ''">
                <font-awesome-icon :icon="['fas', 'times']" />
            </b-button>
            <b-form-input size="sm" v-model="search_user_names" placeholder="Search Employees"></b-form-input>
            
            <b-button class="ms-auto" size="sm" title="enable scroll view" variant="outline" @click="grid_view = !grid_view">
                <font-awesome-icon :icon="['fas', 'th']" />
            </b-button>   
        </div>
        <div v-if="search_user_names != ''" class="d-flex p-1 ps-2">
            Search Showing
            &nbsp;
            <strong>{{ employe_cards_list.length }}</strong>
            &nbsp;
            of 
            &nbsp;
            <strong>{{ employee_cards.length }}</strong>
            &nbsp;
            Cards
        </div>
        <div v-if="!grid_view"  class="d-flex flex-wrap justify-content-center" :class="getDisabledState">
            <div v-for="(item, idx) in employe_cards_list" :key="idx" >
               <employeeCard :item="item" @handleSelect="handleScorecardClick" :loading="is_loading"/>
            </div>
        </div>
        <div v-if="grid_view" > 
            <SwiperComponent :data="employe_cards_list" :type="'employees'" @handleSelect="handleScorecardClick" />
        </div>
    </div>
    
</template>

<script>
//eslint-disable-next-line
import { _ , uniqBy, cloneDeep, flatten, filter} from 'lodash';
//import carouselList from './carouselList.vue';
import { levelComputed, activityComputed, scorecardComputed, scorecardMethods, peopleMethods, peopleComputed } from '@/state/helpers';
import SwiperComponent from './swiperComponent.vue';
import employeeCard from './employeeCard.vue';
import Swal from 'sweetalert2';
export default {
    props: {
        filteredCards: {
            required: true
        }
    },
    components: {
        // carouselList,
        SwiperComponent,
        employeeCard
    },
    data:() => ({
        is_loading: false,
        grid_view: false,
    }),
    computed:{
        ...activityComputed,
        ...levelComputed,
        ...scorecardComputed,
        ...peopleComputed,
        search_user_names:{
            get(){
                return this.search_employee_scorecard_names;
            },
            set(newValue){
                this.setSearchEmployeeScorecardNamesText(newValue);
            }
        },
        employee_cards(){
            if(this.filteredCards) {
                return this.filteredCards
            }
            return [];
        },
        employe_cards_list(){
            return filter(this.employee_cards, (card) => {
                //* Convert both the search input and name to lowercase for case-insensitive search
                return ( !this.search_user_names || card.name.toLowerCase().includes(this.search_user_names.toLowerCase()));
            });
        },
        hasYearSelected(){
            return this.selected_milestone != null;
        },
        hasValidPeriodSelected(){
            return this.objective_viewing_quarter != 0;
        },
        getDisabledState(){
            if(!this.hasYearSelected || !this.hasValidPeriodSelected){
                return 'bg-secondary bg-soft disabled-cursor';
            }
            return '';
        }
    },
    methods:{
        ...scorecardMethods,
        ...peopleMethods,
        logMe(item){
            console.log('ITEM', item);
        },
        handleScorecardClick(user_id, job_id){
            if(!this.hasYearSelected || !this.hasValidPeriodSelected){
                return;
            }
            let job = this.companyJobs.find((job) => {
                return job.id == job_id
            })
            let id = null
            if(job.scorecard_templates && job.scorecard_templates.length > 0) {
                id = job.scorecard_templates[0].id
            }
            this.is_loading = true;
            let scorecard_params = {
                user_id: user_id,
                job_id: job_id,
                template_id: id,
            }
            console.log(scorecard_params)
            
            this.setSelectedScorecardUserID(user_id);
            if(job) {
                if(job.scorecard_templates == 0) {
                    Swal.fire({
                        title: "No Scorecard Linked to Job",
                        // text: "Link Job on ",
                        icon: "warning",
                    })
                    this.is_loading = false;
                } else {
                    this.loadUserScorecard(scorecard_params)
                    .then(()=>{
                        this.is_loading = false;
                        this.$emit('openScorecard');
                    })
                    .catch(()=>{
                        this.is_loading = false;
                    })
                }
            }
            
           
            
        },
        getAvatarSource(item){
            console.log('GetAvatarSource', item);
        }
    },
    mounted(){
        this.loadCompanyJobs(this.company_id)
    },
}
</script>

<style>
.disabled-cursor{
    opacity: 0.5;
    cursor: not-allowed;  
    cursor: -moz-not-allowed;
    cursor: -webkit-not-allowed;
}
</style>