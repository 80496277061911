<template>
    <span class="d-flex" style="align-items: center; color:#C8C8C8">
        <font-awesome-icon :title="getValue(meta)" style="width:20px" :icon="['fas', 'info-circle']" />
    </span>
</template>

<script>
import treeComponentMixin from '@/mixins/treeComponentMixin.js'
export default {
    name: 'info_icon',
    mixins: [ treeComponentMixin ],
    data:() => ({}),
    props:{
        meta:{}
    },
    computed:{
    },
    methods:{
    },
    mounted(){},
}
</script>

<style>

</style>