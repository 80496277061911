<template>
  <div>
    <span class="p-0 cursor-pointer font-size-16" variant="light" size="sm" @click="emitClickAction">
        <span v-show="isElementExpanded(parent_settings)">
            <font-awesome-icon class="fa-fw" icon="fa-angle-up"></font-awesome-icon>
        </span>
        <span v-show="!isElementExpanded(parent_settings)">
            <font-awesome-icon class="fa-fw" icon="fa-angle-down"></font-awesome-icon>
        </span>
    </span>
  </div>
</template>

<script>
import treeComponentMixin from '@/mixins/treeComponentMixin.js'
export default {
    name: 'expand_arrow',
    mixins: [ treeComponentMixin ],
    props:{
        meta:{},
        parent_settings: {}
    },
    methods:{
        emitClickAction(){
            // emit the opposite of the expanded state
            let action = {
                action: 'expand',
                value: !this.isElementExpanded(this.parent_settings)
            }
            this.$emit('handleClickAction', action);
        }
    },
    computed:{
    }
}
</script>

<style>

</style>