<template>
    <div class="w-100 mb-4">
        <div class="header_card_container_no_footer d-flex flex-column">
            <div class="d-flex">
                <b-img
                    fluid alt onerror="src='/img/user.png'" id="image" style="width: 50px; height: 50px"
                    :src="user.avatar_src"
                    class=" smaller-image-height avatar-md rounded-circle img-thumbnail header-profile-user-view imgStyles"
                ></b-img>
                <div class="my-auto m-2" style="max-width: 170px;">
                    <div style="line-height: 18px;" class="header_card_title">{{ user.name }}</div>
                    <!-- <div style="line-height: 18px;" class="header_card_title">Surname</div> -->
                </div>

                <div class="ms-2 my-auto d-flex">
                    <span class="w-100" style="width: 60% !important">
                        <b-dropdown id="cardHeaderItem" class="" no-caret variant="outline" style="border: 1px solid grey; min-width: 200px; border-radius: 0.4rem;" size="sm" v-model="selectedId" @change="changeItem" :text="getName">
                            <b-dropdown-item 
                            v-for="item in options" 
                            @click="changeItem(item.job_id)"
                            :key="item.job_id" 
                            style="width:100%">{{ item.title }}</b-dropdown-item>
                            <template #button-content>
                                <div class="" style="display:inline-flex;justify-content: space-between; width: 98%; align-items: center;">
                                    <div class="">
                                        {{ getName }}
                                    </div>
                                    <div class="" style="width: 10px; margin-top: 3px !important;">
                                        <font-awesome-icon :icon="['fas', 'angle-down']" style="height: 16px !important; width: 16px !important; color: #50A5F1" />
                                    </div>
                                </div> 
                            </template>
                        </b-dropdown>
                    </span>
                </div>

            </div>
            <b-row class="w-100 m-2 d-flex" >
                <div class="col-3 d-flex" style="flex-direction: column;">
                    <span>{{ getViewingPeriod }} Scorecard Completion :</span>
                    <!-- <span>Status: <span v-if="loaded_user_scorecard.final_scorecard_submitted"></span></span> -->
                    <span>Your Reviews</span>
                    <span class="d-flex">
                        <b-progress :variant="getColor(loaded_user_scorecard.scorecard_stats.employee_submitted)" class="mt-2 w-50" :max="100" style="border: 1px solid grey">
                            <b-progress-bar :value="loaded_user_scorecard.scorecard_stats.employee_submitted"></b-progress-bar>
                        </b-progress>
                        <span class="m-1 ms-2">
                            %{{ loaded_user_scorecard.scorecard_stats.employee_submitted }}
                        </span>
                    </span>
                    
                    <span>Manager Review</span>
                    <span class="d-flex">
                        <b-progress :variant="getColor(loaded_user_scorecard.scorecard_stats.manager_submitted)"  class="mt-2 w-50" :max="100" style="border: 1px solid grey">
                            <b-progress-bar :value="loaded_user_scorecard.scorecard_stats.manager_submitted"></b-progress-bar>
                        </b-progress>
                        <span class="m-1 ms-2">
                            %{{ loaded_user_scorecard.scorecard_stats.manager_submitted }}
                        </span>
                    </span>
                    <span>Final Review</span>
                    <span class="d-flex">
                        <b-progress :variant="getColor(loaded_user_scorecard.scorecard_stats.final_submitted)" class="mt-2 w-50" :max="100" style="border: 1px solid grey">
                            <b-progress-bar :value="loaded_user_scorecard.scorecard_stats.final_submitted"></b-progress-bar>
                        </b-progress>
                        <span class="m-1 ms-2">
                            %{{ loaded_user_scorecard.scorecard_stats.final_submitted }}
                        </span>
                    </span>
                </div>
                <div class="col-3 d-flex" style="flex-direction: column;">
                    <span>Summary Review Notes :</span>
                    <span>Not Submitted</span>
                    <span class="mt-2">Bonus Status :</span>
                    <span>(Qualifiers not met {{ getViewingPeriod }})</span>
                </div>
                <div class="col-4 d-flex" style="flex-direction: column;">
                    <span>Org Structure : </span>
                    <span>Division : <span style="color: #CB6CC3">{{ loaded_user_scorecard.job_data.job.level.name }}</span></span>
                    <span>Reporting to : <span style="color: #CB6CC3">{{ getReportsTo() }}</span></span>
                    <span class="mt-2">Scorecard Template :</span>
                    <span>Name: <span style="color: #CB6CC3">{{ loaded_user_scorecard.name }}</span></span>
                    <span>Creation Date : {{ formatDate(loaded_user_scorecard.created_at) }}</span>
                </div>
                <div class="col-2 d-flex" style="flex-direction: column;">
                    <span>Employment Dates :</span>
                    <span>Start : {{ formatDate(loaded_user_scorecard.job_data.created_at) }}</span>
                    <span>End : -</span>
                    <span class="mt-2">Role Dates :</span>
                    <span>Start : {{ formatDate(loaded_user_scorecard.job_data.start_date) }}</span>
                    <span>End : -</span>
                </div>
            </b-row>
            <div class="d-flex mt-auto">
                    <b-button disabled variant="info" size="sm" class="ms-auto">Summary Review Notes (00)</b-button>
                    <b-button v-if="canShowWeightingButton" @click="submitScorecardCheck()" variant="info" size="sm" class="ms-1">Edit Weightings</b-button>
                    <b-button v-if="canShowSubmitButton" @click="$emit('submitScorecard')" variant="info" size="sm" class="ms-1">Submit <strong>{{ getViewingPeriod }}</strong> Scorecard</b-button>
                </div>
        </div>
        <div class="header_card_container_footer card_footer_green d-flex">
            <span class="ms-auto my-auto text-white">
                Final Score
            </span>
            <span class="ms-2 my-auto text-white font-size-20">-</span>
        </div>
    </div>
</template>

<script>
import '../headerCard.css';
//import { cloneDeep, flatten, uniqBy } from 'lodash';
import { scorecardComputed, scorecardMethods, levelComputed, activityComputed, peopleComputed } from '@/state/helpers';
import { format, parseISO} from 'date-fns'
import Swal from 'sweetalert2';
export default {
    data:() => ({
       user: {},
       options: [],
       selectedId: -1
    }),
    computed:{
        ...scorecardComputed,
        ...levelComputed,
        ...activityComputed,
        ...peopleComputed,
        canShowSubmitButton(){
            return this.viewingUser == 'manager';
        },
        canShowWeightButton(){
            return this.viewingUser == 'manager';
        },
        getViewingPeriod(){
            let scorecard_period_type = this.loaded_user_scorecard.scorecard_period_type;
            let scorecard_period_key = this.loaded_user_scorecard.scorecard_period_key;

            switch(scorecard_period_type){
                case 'quarter':{
                    return `Q${scorecard_period_key}`;
                }
                case 'half_year':{
                    return `H${scorecard_period_key}`;
                }
                case 'year':{
                    return 'Year';
                }
                default:{
                    //* should never be this case
                    console.log('Unhandled case for getViewingPeriod');
                    return '';
                }
            }
        },
        getName() {
            let value = ''
            this.options.forEach(module => {
                console.log(JSON.parse(JSON.stringify(module)))
                console.log(this.selectedId)
                if(module.job_id == this.selectedId) {
                    value = module.title
                }
            })
            return value
        },
       
    },
    methods:{
        ...scorecardMethods,
        submitScorecardCheck(){
            if(this.loaded_user_scorecard.scorecard_stats.final > 100){
                this.$swal.fire('Please complete all FINAL reviews before submitting the scorecard');
            }
            else{
                this.$emit('editWeighting');
            }
        },
        formatDate(date) {
            return format(parseISO(date), 'dd/MMM/yyyy')
        },
        getReportsTo() {
            let id = this.loaded_user_scorecard.job_data.reports_to_user_id
            let user_object = this.all_users.find((user) => {
                return user.id == id;
            });
            if(user_object) {
                return user_object.name
            }
        },
        getUser(id) {
            // let all_users = cloneDeep(this.all_users);
            // const flat_users = flatten(Object.values(this.menu_users));
            // all_users.forEach( user => {
            //     user['jobs'] = [];
            //     let matched_users = flat_users.filter(item => item.id == user.id);
            //     matched_users.forEach( matched_user => {
            //         matched_user.jobs.forEach( job => {
            //             user.jobs.push({
            //                 value: job.job_id,
            //                 text: job.title,
            //             })
            //         })
            //     })
            //     user.jobs = uniqBy(user.jobs, 'value');
            // })
            let user_object = this.scorecards.find((user) => {
                return user.pivot.user_id == id;
            });
            console.log(user_object)
            this.options = user_object.jobs
            this.selectedId = this.loaded_user_scorecard.job_data.job_id
            this.user = user_object;
        },
        getColor(item) {
            if (item > 60) {
            return 'success'
            } else if (item > 30) {
            return 'warning'
            } else if (item > 10) {
            return 'danger'
            }
            return ''
        },
        changeItem(job_id) {
             let job = this.companyJobs.find((job) => {
                return job.id == id
            })
            let id = null
            if(job.scorecard_templates && job.scorecard_templates.length > 0) {
                id = job.scorecard_templates[0].id
            }
            this.is_loading = true;
            let scorecard_params = {
                user_id: this.user.id,
                job_id: job_id,
                template_id: id,
            }
            this.setSelectedScorecardUserID(this.user.id);
            if(job) {
                if(job.scorecard_templates == 0) {
                    Swal.fire({
                        title: "No Scorecard Linked to Job",
                        // text: "Link Job on ",
                        icon: "warning",
                    })
                    this.is_loading = false;
                } else {
                    this.loadUserScorecard(scorecard_params)
                    .then(()=>{
                        this.is_loading = false;
                        this.$emit('openScorecard');
                    })
                    .catch(()=>{
                        this.is_loading = false;
                    })
                }
            }
        }
    },
    mounted(){
        this.getUser(this.loaded_user_scorecard.job_data.user_id)
    },
}
</script>

<style>

</style>