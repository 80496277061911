<template>
    <div class="btn-group pt-0" role="group">
        <div class="d-flex w-100" style="flex-direction: column; max-width: 300px; justify-content: end;">
            <span class="m-1 btn-group">
                <span class="w-100">
                <span class="m-1" style="color: #8881B3">
                    Select Year
                </span>
                    <b-dropdown id="yearTypeDropdown" toggle-class='scorecard_date_toggle_class' variant="outline" no-caret menu-class="scorecard_custom_date_menu_class" class="scorecard_decorated_select">
                            <template #button-content>
                                <div v-if="company_milestones != null && hasMilestoneSelected" style="display:inline-flex;justify-content: space-between; width: 98%; align-items: center;">
                                    <div style="width: 30px"></div>
                                    <div class="" style="">
                                        {{ selectedMilestoneName }}
                                    </div>
                                    <div class="" style="width: 35px; margin-top: 3px !important;">
                                        <font-awesome-icon :icon="['fas', 'angle-down']" style="height: 16px !important; width: 16px !important; color: #CBC3FF" />
                                    </div>
                                </div>
                                
                                <span v-if="!hasMilestoneSelected">{{selectedMilestoneName}}</span>
                            </template>
                            
                            <b-dropdown-item class="year_select_item" v-for="milestone in available_milestones" :key="milestone.id" @click="setPinnedMilestone(milestone.id)" :class="pinned_milestone_id == milestone.id ? 'bg-primary bg-soft':''">
                                <div class="d-flex w-100">
                                    <span>
                                        {{ getMilestoneString(milestone) }}
                                    </span>
                                </div>
                            </b-dropdown-item>
                            <b-dropdown-item class="year_select_item" v-show="hasMilestoneSelected" variant="danger" @click="setPinnedMilestone(null)">
                                <font-awesome-icon class="me-1" icon="fa-times"/>
                                Clear Selected Year
                            </b-dropdown-item>
                            <b-dropdown-text v-show="available_milestones.length == 0" style="text-align: center; min-height: 27.5px;" class="pt-3 ps-2 pe-2">
                                <b-alert show variant="danger">
                                    <font-awesome-icon class="me-1" icon=" fa-info" />
                                    The Company has no year selected
                                </b-alert>
                                
                            </b-dropdown-text>
                    </b-dropdown>
                </span>
            </span>
        </div>
        <div class="ms-1 mt-3 pt-2 w-100" style="display: flex; flex-direction: column; justify-content: end; min-width: 300px;">
            <span class="btn-group ps-1 pe-1 d-flex" style="justify-content: center;">
                <span class="cursor-pointer">
                    <i class="bi bi-hexagon" :class="isPeriodSelected(1)" data-content="Q1" @click="setViewingQuarter(1)" ></i>
                    <i class="bi bi-hexagon" :class="isPeriodSelected(2)" data-content="Q2" @click="setViewingQuarter(2)" ></i>
                    <i class="bi bi-hexagon" :class="isPeriodSelected(3)" data-content="Q3" @click="setViewingQuarter(3)" ></i>
                    <i class="bi bi-hexagon" :class="isPeriodSelected(4)" data-content="Q4" @click="setViewingQuarter(4)" ></i>
                </span>
                <span style="padding-left: 0.3rem">
                    <i class="bi bi-hexagon" :class="isPeriodSelected(5)" data-content="H1" @click="setViewingQuarter(5)" ></i>
                    <i class="bi bi-hexagon" :class="isPeriodSelected(6)" data-content="H2" @click="setViewingQuarter(6)" ></i>
                </span>
                <span style="margin-left: 0.3rem">
                    <i class="bi bi-hexagon" :class="isPeriodSelected(7)" data-content="YR" @click="setViewingQuarter(7)" ></i>
                </span>
            </span>
        </div>
    </div>
</template>

<script>
import { activityComputed, activityMethods, scorecardComputed, scorecardMethods } from '@/state/helpers'
import _ from 'lodash'
export default {
name: "scorecardDateSelector",
components:{
},
props: {
    viewType: {
        required: true
    }
},

data: () => ({
    show_modal: false,
    selected_filter: 'YR',
    selected_year_filter: 'Calendar',
    selected_year: 2024,
    selected_year_type: 1,
    year_options: [{ value: 2024, text: '2024-2025' },{ value: 2023, text: '2023-2024' },{ value: 2022, text: '2022-2023' }],
    year_type_options: [{ value: 1, text: 'Calendar Year ' },{ value: 2, text: 'Financial Year' },{ value: 3, text: 'Rolling Year' }] 
}),
computed:{
    ...activityComputed,
    ...scorecardComputed,
    canUserEdit(){
            return this.$hasPerm('milestones-manager');
    },
    selectedMilestoneProgress(){
        if(this.pinned_milestone_id == -1){
            return 0
        }
        else{
            let milestone = this.company_milestones.find( item => item.id == this.pinned_milestone_id);
            if(!milestone){
                return 0;
            }
            else{
                return milestone.progress;
            }
        }
    },
    selectedMilestoneName(){
        if(this.pinned_milestone_id == -1){
            return 'No year selected';
        }
        else{
            let milestone = this.company_milestones.find( item => item.id == this.pinned_milestone_id);
            if(!milestone){
                return '';
            }
            else{
                if(milestone.name != null) {
                    let till = Number(milestone.year) + 1
                    return `${milestone.year} - ${till}`;
                } else {
                    return `${milestone.year}`;
                }
                
            }
        }
    },
    canShowComponent(){
        let milestone = this.company_milestones.find( item => item.id == this.pinned_milestone_id);
        return milestone ? true : false;
    },
    hasMilestoneSelected(){
        return this.pinned_milestone_id == -1 ? false : true;
    },
    available_milestones(){
        if(this.company_milestones == null){
            //* milestones not loaded yet - early exit
            return [];
        }

        let data = [];
        //if(this.activity_data != null){
            data = this.company_milestones.map( item => {
                return {
                    id: item.id,
                    name: item.name,
                    year: item.year,
                }
            })
        //}
        return _.orderBy(data, ['year', 'name']);
    },
    getYear() {
        let value = ''
        this.year_options.forEach(year => {
            if(year.value == this.selected_year) {
                value = year.text
            }
        })
        return value
    },
    getYearType() {
        let value = ''
        this.year_type_options.forEach(type => {
            if(type.value == this.selected_year_type) {
                value = type.text
            }
        })
        return value
    }
},
methods:{
    ...activityMethods,
    ...scorecardMethods,
    isPeriodSelected(period){
        return this.objective_viewing_quarter == period
        ? 'scorecard_selected' : 'scorecard_default'
    },
    setFilter(value) {
        this.selected_filter = value
    },
    setYearFilter(value) {
        this.selected_year_filter = value
    },
    findScorecardPeriodID(quarter){
        //* backend period values -> 1-4 for quarter, 1-2 for half_year, 1 for year
        let backend_period = 1;
        let period_type = '';
        if(quarter <= 4){
            period_type = 'quarter';
            backend_period = quarter;
        }
        else if(quarter <= 6){
            period_type = 'half_year';
            //* map half_year (5 - 6) to values 1 and 2
            backend_period = quarter == 5 ? 1 : 2;
        }
        else{
            period_type = 'year';
        }

        let found_period = this.loaded_user_scorecard.scorecard_periods.find( period => {
            return period.period_type == period_type && period.period_key == backend_period;
        })
        return found_period.id;


    },
    setViewingQuarter(quarter){
        //this.$plausible.trackEvent('Changed Viewing Period');
        this.setObjectiveQuarter(quarter)
        //this.$refs.datepickerFilterDropdown.hide();
        this.filterObjectivesByQuarter();

        
        let params = {
            scorecard_period_id: this.findScorecardPeriodID(quarter)
        }
        switch(this.viewType){
            case 'productivity':{
                params['target_id'] = this.details_page_data.id;
                this.loadProductivityDetails(params).then(() => {
                    this.show_detailed_view = true;
                })
                break;
            }
            case 'values':{
                params['value_id'] = this.details_page_data.id;
                this.loadValuesDetails(params).then(() => {
                    this.show_detailed_view = true;
                })
                break;
            }
            case 'objectives':{
                params['objective_id'] = this.details_page_data.id;
                this.loadObjectiveDetails(params).then(() => {
                    this.show_detailed_view = true;
                })
                break;
            }
        }

        this.loadUserScorecard({
            user_id: this.loaded_user_scorecard.job_data.user_id,
            job_id: this.loaded_user_scorecard.job_data.job_id,
            template_id: this.selected_scorecard_template_id
        })
    },
    setMilestoneEvent: _.debounce(function(milestone_id){
        this.setPinnedMilestone(milestone_id);
    }, 50),
    showAddMilestoneModal(milestone_payload, event = null){
        if(event != null){
            event.stopPropagation();
        }
        this.milestone_modal_payload = milestone_payload;
        this.show_modal = true;
        this.$nextTick(()=>{
            this.$bvModal.show('milestoneModal');
            this.$root.$once("bv::modal::hidden", (event) => {
                if (event.type == "hidden" && event.componentId == "milestoneModal") {
                    this.show_modal = false;
                }
            });
        })
        
    },
    getMilestoneString(milestone_object){
        let till = Number(milestone_object.year) + 1
        return `${milestone_object.year} - ${till}`;    
    },
    getMilestoneYear(milestone_object){
            return `${milestone_object.year}`;    
    },
    setPinnedMilestone(milestone_id){
        // check for unsaved data first
        const has_unsaved_changes = this.$allTablesUnsavedChangesCheck();
        if(has_unsaved_changes){
            //* stop here if unsaved changes are found in webix tables
            return;
        }
        let must_clear_selection = milestone_id == null || this.pinned_milestone_id == milestone_id;
        if(must_clear_selection){
            //* need to unpin milestone
            this.setPinnedMilestoneId(-1);
            this.setSelectedMilestone(null);
            //alert('What are you doing?')
            //this.setSelectedObjective(null);
            //this.loadActivityData(this.selected_level.id)
        }
        else{
            let milestone = this.available_milestones.find( item => item.id == milestone_id);
            if(milestone){
                // let old_milestone_id = _.clone(this.pinned_milestone_id)
                this.setPinnedMilestoneId(milestone_id);
                this.setSelectedMilestone(milestone);
                //this.setSelectedObjective(null);
                //if(old_milestone_id != milestone.id){
                //    this.loadActivityData(this.selected_level.id)
                //}
                
            }
        }
    },
    restoreCurrentYearSelection(){
        let current_year = new Date().getFullYear().toString();
        let current_year_milestone = _.find(this.company_milestones, {year:current_year});
        let last_selected_quarter = window.localStorage.last_selected_quarter;

        if(current_year_milestone){
            //* only set quarter if a year was restored from localStorage
            if(last_selected_quarter){
                this.setObjectiveQuarter(last_selected_quarter)
            }
            this.setPinnedMilestone(current_year_milestone.id);
        }
        else{
            //* need to save a milestone year for current year if none exists
            alert('Need to create milestone for current year')
        }
    }
},
mounted(){
    //this.setLoading(false);
    let has_company_level_id = this.company_id != null;
    let has_no_milestones_loaded = this.company_milestones == null;

    if(has_company_level_id && has_no_milestones_loaded){
        this.loadCompanyMilestones(this.company_id).then(()=>{
            //* Get and set the milestone for the current year ( replaced core.default_milestone check)
            this.restoreCurrentYearSelection();
        })
    }
    this.$eventHub.$on('selectedNewMilestone', this.setMilestoneEvent);
},
}

</script>

<style>
.selected_date_style {
border: 2px solid #50A5F1
}
.selected_date_style:focus {
border: 2px solid #50A5F1
}

.quarter_rounded {
width: 1.7rem;
height: 1.7rem;
font-size: 0.6rem !important;
font-weight: 500;
border-radius: 50% !important;
}

.year_rounded {
width: 6rem;
height: 1.7rem;
font-size: 0.7rem !important;
border-radius: 15px !important;
}

.scorecard_decorated_select {
    text-align: center; 
    /* color: #CBC3FF !important; */
    border-radius: 0.5rem; 
    font-size: 0.7rem !important;
    width: 100%
}
.scorecard_date_toggle_class {
    text-align: center; ;
    /* color: #CBC3FF !important; */
    border: 1px solid #CBC3FF;
    padding: 1px !important;
    padding-bottom: 4px !important;
    border-radius: 0.5rem; 
    font-size: 0.75rem !important;
    width: 100%
}
.scorecard_custom_date_menu_class {
    /* color: #CBC3FF !important; */
    margin-left: 3px;
    padding: 0px;
    margin-top: 0px !important;
    border: 1px solid #CBC3FF;
    width: 100%
}

/* .decorated_select:hover {
border: 1px solid #CBC3FF
} */
.scorecard_decorated_select:focus {
border: 1px solid #CBC3FF
}
.scorecard_decorated_select .dropdown-toggle {
border: 1px solid #CBC3FF !important;
}
.scorecard_decorated_select .btn-secondary:focus {
box-shadow: none;
}
.arrow-grey {
border: 10px solid #CBC3FF;
border-width: 0 3px 3px 0;
display: inline-block;
padding: 3px;
}

.scorecard_default::before {
display: inline-flex;
justify-content: center;
vertical-align: middle;
content: attr(data-content);
padding-top: 6px;
padding-right: 4px;
background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' fill='%23D0CCE680' stroke='%23736BA5' stroke-width='0.5' class='bi bi-hexagon-fill' style='transform: rotate(90deg)' viewBox='0 0 17 16'><path fill-rule='evenodd' d='M8.5.134a1 1 0 0 0-1 0l-6 3.577a1 1 0 0 0-.5.866v6.846a1 1 0 0 0 .5.866l6 3.577a1 1 0 0 0 1 0l6-3.577a1 1 0 0 0 .5-.866V4.577a1 1 0 0 0-.5-.866z'/></svg>");
background-repeat: no-repeat;
background-size: 2rem 2rem;
font-style: initial;
width: 36px;
height: 36px
}
.scorecard_selected::before {
display: inline-flex;
justify-content: center;
vertical-align: middle;
content: attr(data-content);
padding-top: 6px;
padding-right: 4px;
background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' fill='%23736BA5' stroke='%2350A5F1' stroke-width='0.8' class='bi bi-hexagon-fill' style='transform: rotate(90deg)' viewBox='0 0 17 16'><path fill-rule='evenodd' d='M8.5.134a1 1 0 0 0-1 0l-6 3.577a1 1 0 0 0-.5.866v6.846a1 1 0 0 0 .5.866l6 3.577a1 1 0 0 0 1 0l6-3.577a1 1 0 0 0 .5-.866V4.577a1 1 0 0 0-.5-.866z'/></svg>");
background-repeat: no-repeat;
background-size: 2rem 2rem;
font-style: initial;
width: 36px;
height: 36px
}

.scorecard_selected{
    color: white;
}
</style>
