<template>
  <div>
    <div>
      <Swiper
      :slides-per-view="6"
      :space-between="0"
      :loop="false"
      :pagination="{
          el: '.swiper-pagination',
          clickable: true,
      }"
      :breakpoints="{
        0: {
          slidesPerView: 1,
          spaceBetween: 0
        },
        1336: {
          slidesPerView: 3,
          spaceBetween: 0
        },
        1846: {
          slidesPerView: 4,
          spaceBetween: 0
        },
        2200: {
          slidesPerView: 4,
          spaceBetween: 0
        }
      }"
      :navigation="{
          nextEl: '.manager-next',
          prevEl: '.manager-prev',
      }"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
      <SwiperSlide
        v-for="item in data"
        :key="item.id"
      >
         <EmployeeCard :item="item" />
      </SwiperSlide>
    </Swiper>
    <div class="carousel__controls">
        <font-awesome-icon class="my-button-prev manager-prev" icon="fa-angle-left"></font-awesome-icon>
        <div class="swiper-pagination"> </div>
        <font-awesome-icon class="my-button-next manager-next" icon="fa-angle-right"></font-awesome-icon>
    </div>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination } from 'swiper'

import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'

//Import Swiper styles
import 'swiper/swiper-bundle.css'
import EmployeeCard from './employeeCard.vue';

SwiperCore.use([Navigation, Pagination])

export default {
  props: {
    data: {
      required: true
    },
    type: {
      required: true
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    EmployeeCard
  },
  methods: {
    onSwiper (swiper) {
      console.log(swiper)
    },
    onSlideChange () {
      console.log('slide change')
    }
  },
  data: () => ({
    value: 5
  })
}
</script>

<style lang="scss">
.img-fluid {
  max-width: 100%;
  height: auto;
}
.w-100 {
  width: 100%;
}
.ml-auto, .mx-auto {
  margin-left: auto;
}
.mr-auto, .mx-auto {
  margin-right: auto;
}
.document_card_styles {
  border: 1px solid grey;
  border-radius: 0.2rem;
  height: 100px
}
.carousel {
  &__controls {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      margin-top: 1px;
  }
}
.my-button-prev,
.my-button-next {
  height: 36px;
  width: 44px;
}

.swiper-pagination {
  width: unset;
  position: unset;
  display: flex;
}

.swiper-pagination-bullet {
 margin: 4px !important;
}
</style>