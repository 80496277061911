<template>
    <div>
        <b-row>
            <b-col cols="6" :class="getStatusClass('employee')">
                <div class="d-flex">
                    <b-img
                        fluid alt onerror="src='/img/user.png'" id="image" style="width: 50px; height: 50px"
                        :src="getUserProfilePicture(loaded_user_scorecard.job_data.user_id)"
                        class=" smaller-image-height avatar-md rounded-circle img-thumbnail header-profile-user-view imgStyles"
                    ></b-img>
                    <div class="my-auto m-2" style="max-width: 170px;">
                        <div style="line-height: 18px;" class="header_card_title">{{ getUserName(loaded_user_scorecard.job_data.user_id) }}</div>
                        <!-- <div style="line-height: 18px;" class="header_card_title">Surname</div> -->
                    </div>
                </div>
                

                <div>{{ employee_description }}</div>

                <div v-if="!disableScores && showEmployeeRating && isForCurrentUser">
                    <RatingDropdown v-if="scorecardPeriod(1)" :value="employee.q1" :comment_length="employee_comments_count" @updated="saveEmployeeRating('q1', $event)" @addComment="insertEmptyNote('employee')"></RatingDropdown>
                    <RatingDropdown v-if="scorecardPeriod(2)" :value="employee.q2" :comment_length="employee_comments_count" @updated="saveEmployeeRating('q2', $event)" @addComment="insertEmptyNote('employee')"></RatingDropdown>
                    <RatingDropdown v-if="scorecardPeriod(3)" :value="employee.q3" :comment_length="employee_comments_count" @updated="saveEmployeeRating('q3', $event)" @addComment="insertEmptyNote('employee')"></RatingDropdown>
                    <RatingDropdown v-if="scorecardPeriod(4)" :value="employee.q4" :comment_length="employee_comments_count" @updated="saveEmployeeRating('q4', $event)" @addComment="insertEmptyNote('employee')"></RatingDropdown>
                </div>

                <div class="d-flex" v-if="!hasEmployeeSubmittedComments && isForCurrentUser">
                    <b-button @click="showSubmitNotesPrompt('employee')" size="sm" variant="info" :disabled="employee_comments_count == 0" class="ms-auto">
                        Submit Notes For Review
                    </b-button>
                </div>

                <div class="d-flex" v-if="!hasEmployeeSubmittedComments && isForCurrentUser">
                    <span>Add more notes below</span>
                    <b-button @click="insertEmptyNote('employee')" variant="whitee" class="ms-auto">
                        <span class="text-info">Add note</span>
                    </b-button>
                </div>
                <div v-for="(comment, idx) in employee_comments" :key="comment.id">
                    <ReviewNoteCard :ref="comment.id" :id="comment.id"
                    @insertSavedComment="handleInsertSavedComment('employee', $event)"
                    @removeComment="handleRemoveComment('employee', idx)"
                    :comment="comment"
                ></ReviewNoteCard>
                </div>
            </b-col>
            <b-col cols="6" :class="getStatusClass('manager')">
                <div class="d-flex">
                    <b-img
                        fluid alt onerror="src='/img/user.png'" id="image" style="width: 50px; height: 50px"
                        :src="getUserProfilePicture(loaded_user_scorecard.job_data.reports_to_user_id)"
                        class=" smaller-image-height avatar-md rounded-circle img-thumbnail header-profile-user-view imgStyles"
                    ></b-img>
                    <div class="my-auto m-2" style="max-width: 170px;">
                        <div style="line-height: 18px;" class="header_card_title">{{ getUserName(loaded_user_scorecard.job_data.reports_to_user_id) }}</div>
                        <!-- <div style="line-height: 18px;" class="header_card_title">Surname</div> -->
                    </div>
                </div>
                

                <div>{{ manager_description }}</div>
                <div v-if="!disableScores && showManagerRating && isForManagerUser">
                    <RatingDropdown v-if="!showFinalRatingDropdown && scorecardPeriod(1)" :value="manager.q1" :comment_length="manager_comments_count" @updated="saveManagerRating('q1', $event)" @addComment="insertEmptyNote('manager')"></RatingDropdown>
                    <RatingDropdown v-if="!showFinalRatingDropdown && scorecardPeriod(2)" :value="manager.q2" :comment_length="manager_comments_count" @updated="saveManagerRating('q2', $event)" @addComment="insertEmptyNote('manager')"></RatingDropdown>
                    <RatingDropdown v-if="!showFinalRatingDropdown && scorecardPeriod(3)" :value="manager.q3" :comment_length="manager_comments_count" @updated="saveManagerRating('q3', $event)" @addComment="insertEmptyNote('manager')"></RatingDropdown>
                    <RatingDropdown v-if="!showFinalRatingDropdown && scorecardPeriod(4)" :value="manager.q4" :comment_length="manager_comments_count" @updated="saveManagerRating('q4', $event)" @addComment="insertEmptyNote('manager')"></RatingDropdown>
                    <RatingDropdown v-if="showFinalRatingDropdown" :value="null" :comment_length="manager_comments_count" @updated="saveFinalRating($event)" @addComment="insertEmptyNote('manager')"></RatingDropdown>
                </div>
                <div class="d-flex" v-if="showManagerCommentButton && isForManagerUser">
                    <b-button @click="showSubmitNotesPrompt('manager')" size="sm" variant="info" :disabled="manager_comments_count == 0" class="ms-auto">
                        Submit Notes For Review
                    </b-button>
                </div>

                <div class="d-flex" v-if="showManagerCommentButton  && isForManagerUser">
                    <span>Add more notes below</span>
                    <b-button @click="insertEmptyNote('manager')" variant="whitee" class="ms-auto">
                        <span class="text-info">Add note</span>
                    </b-button>
                </div>
                <div v-for="(comment, idx) in manager_comments" :key="comment.id">
                    <ReviewNoteCard :ref="comment.id" :id="comment.id" show-final-state
                        @insertSavedComment="handleInsertSavedComment('manager', $event)"
                        @removeComment="handleRemoveComment('manager', idx)"
                        :comment="comment"
                    ></ReviewNoteCard>
                </div>
            </b-col>
        </b-row>
        <b-modal v-if="this.show_modal" id="reviewNotSubmissionPrompt" title="Submit Notes">
            <div v-html="getSubmitPromptText(modal_type)">

            </div>
            <template #modal-footer>
                <div class="d-flex">
                    <b-button :disabled="is_submitting_comments" @click="$bvModal.hide('reviewNotSubmissionPrompt')" size="sm" class="ms-auto" variant="info">Close</b-button>
                    <b-button :disabled="is_submitting_comments" @click="submitNotes(modal_type);" size="sm" class="ms-1" variant="info">Submit Notes For Review</b-button>
                </div>
            </template>
            <b-overlay no-wrap :show="is_submitting_comments"></b-overlay>
        </b-modal>
    </div>
</template>

<script>
import RatingDropdown from '../reviewDropdown.vue';
import ReviewNoteCard from '../reviewNoteCard.vue'
import { scorecardComputed, scorecardMethods, adminComputed, levelComputed } from '@/state/helpers';
import { cloneDeep, debounce, capitalize } from 'lodash';
export default {
    components:{
        RatingDropdown,
        ReviewNoteCard,
    },
    props:{
        disableScores:{
            type: Boolean,
            default: () => {
                return false
            },
        },
    },
    data:() => ({
        is_submitting_comments: false,
        show_modal: false,
        modal_type: null,
        employee:{
            q1:null,
            q2:null,
            q3:null,
            q4:null,
            comment: null,
        },
        manager:{
            q1:null,
            q2:null,
            q3:null,
            q4:null,
            comment: null,
        },
        employee_comments:[],
        manager_comments:[],
    }),
    watch:{
        details_page_data:{
            immediate: true,
            handler(newData){
                this.employee_comments = cloneDeep(newData.employee_comments);
                this.manager_comments = cloneDeep(newData.manager_comments);

                this.processScores();
            }
        }
    },

    computed:{
        ...scorecardComputed,
        ...adminComputed,
        ...levelComputed,
        showEmployeeRating(){
            let employee_submitted = this.details_page_data.comment_status.user_submitted;
            let manager_submitted = this.details_page_data.comment_status.manager_submitted
            let final_submitted = this.details_page_data.comment_status.final_submitted;
            if(this.viewingUserType == 'manager'){
                //* only show employee rating when employee and manager has submitted
                return final_submitted || employee_submitted && manager_submitted;
            }
            else{
                //* Always show employees rating
                return true;
            }
            
        },
        showManagerRating(){
            let employee_submitted = this.details_page_data.comment_status.user_submitted;
            let manager_submitted = this.details_page_data.comment_status.manager_submitted
            let final_submitted = this.details_page_data.comment_status.final_submitted;
            if(this.viewingUserType == 'employee'){
                //* only show manager rating when employee and manager has submitted
                return final_submitted || employee_submitted && manager_submitted;
            }
            else{
                //* Always show employees rating
                if(manager_submitted){
                    return this.currentPeriodScores.manager != null ? false : true;
                }
                if(final_submitted){
                    return this.currentPeriodScores.final != null ? false : true;
                }
                return true;
            }
            //return this.details_page_data.comment_status.manager_submitted == false || this.details_page_data.comment_status.final_submitted == false;
        },
        showFinalRatingDropdown(){
            let employee_submitted = this.details_page_data.comment_status.user_submitted;
            let manager_submitted = this.details_page_data.comment_status.manager_submitted
            let final_submitted = this.details_page_data.comment_status.final_submitted;
            return employee_submitted && manager_submitted && !final_submitted;
        },
        employee_comments_count(){
            return this.countExititngComments(this.employee_comments);
        },
        manager_comments_count(){
            return this.countExititngComments(this.manager_comments);
        },
        currentPeriodScores(){
            let period = this.loaded_user_scorecard.scorecard_period_key;
            return this.details_page_data.scores[period];
        },
        comment_status(){
            let employee_submitted = this.details_page_data.comment_status.user_submitted;
            let manager_submitted = this.details_page_data.comment_status.manager_submitted
            let final_submitted = this.details_page_data.comment_status.final_submitted;

            if(!employee_submitted){
                return 'employee_comment';
            }
            else if(employee_submitted && !manager_submitted){
                return 'manager_comment';
            }
            console.log(final_submitted);
            return 'na'
        },

        isForCurrentUser(){
            return this.loaded_user_scorecard.job_data.user_id == this.core.id;
        },
        isForManagerUser(){
            return this.viewingUserType == 'manager';
            //return this.loaded_user_scorecard.job_data.reports_to_user_id == this.core.id
        },
        /*
        let status = this.meta.value
            if(status.final_submitted){
                //* if final review is submitted (use and manager should already be submitted)
                //* show 'Final' msg
                return `<span class="font-weight-bold text-success">Final Review Submitted</span> 
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check"><path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" class=""></path></svg>
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check"><path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" class=""></path></svg>
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check"><path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" class=""></path></svg>`;
            }
            else if(status.manager_submitted && status.user_submitted){
                //* if final is unsubmitted but user+manager has submitted
                return `<span class="font-weight-bold text-warning">Final Review Due</span>`;
            }
            switch(this.meta.viewing_user){
                case 'employee':{
                    let line_1 = status.manager_submitted ? 'Manager Review Submitted' : 'Manager Review Due';
                    let line_2 = status.user_submitted ? 'Your Review Submitted' : 'Your Review Due';
                    return `<span class="font-weight-bold">
                                <div class="${this.getElementColor(status.manager_submitted)}">
                                    ${line_1}
                                    <svg v-if="${status.manager_submitted}" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check"><path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" class=""></path></svg>
                                </div>
                                <div class="${this.getElementColor(status.user_submitted)}">
                                    ${line_2}
                                    <svg v-if="${status.user_submitted}" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check"><path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" class=""></path></svg>
                                </div>
                            </span>`
                }
                case 'manager':{
                    let line_1 = status.manager_submitted ? 'Your Review Submitted' : 'Your Review Due';
                    let line_2 = status.user_submitted ? 'Employee Review Submitted' : 'Employee Review Due';
                    return `<span class="font-weight-bold">
                                <div class="${this.getElementColor(status.manager_submitted)}">
                                    ${line_1}
                                    <svg v-if="${status.manager_submitted}" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check"><path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" class=""></path></svg>
                                </div>
                                <div class="${this.getElementColor(status.user_submitted)}">
                                    ${line_2}
                                    <svg v-if="${status.user_submitted}" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check"><path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" class=""></path></svg>
                                </div>
                            </span>`
                }
                default:{
                    return '';
                }
            }
                */
        
        //eslint-disable
        employee_description(){
            // eslint-disable-next-line
            let final_submitted = this.details_page_data.comment_status.final_submitted;
            // eslint-disable-next-line
            let employee_submitted = this.details_page_data.comment_status.employee_submitted;
            // eslint-disable-next-line
            let manager_submitted = this.details_page_data.comment_status.manager_submitted;
            //eslint-disable-next-line
            let item_type = capitalize(this.details_page_type);
            //eslint-disable-next-line
            let period = capitalize(this.loaded_user_scorecard.scorecard_period_type);

            if(this.viewingUserType == 'employee'){
                if(employee_submitted && !manager_submitted){
                    return `Your Self Assessment Review Notes for this ${item_type} Item in a ${period} Review on your Performance Scorecard are submitted. You cannot now change your input.`
                }
                if(!employee_submitted && !manager_submitted){
                    return `Your Self Assessment Review Notes for this ${item_type} Item in a ${period} Review on your Performance Scorecard are now due. Once you have submitted you cannot change your input.`
                }
                if(!employee_submitted && manager_submitted){
                    return `Your Self Assessment Review Notes for this ${item_type} Item in a ${period} Review on your Performance Scorecard are now due. Once you have submitted you cannot change your input.`
                }
                if(employee_submitted && manager_submitted && !final_submitted){
                    return `Your Self Assessment Review Notes for this ${item_type} Item in a ${period} Review on your Performance Scorecard are submitted. You cannot now change your input. Your Manager has also submitted their Assessment Notes and this item is ready for Final Review.`
                }
                if(final_submitted){
                    return `Your Self Assessment Review Notes for this ${item_type} Item in a ${period} Review on your Performance Scorecard are submitted. You cannot now change your input.`
                }
                return `${this.viewingUserType} - missing employee_description`;
            }
            else{
                if(employee_submitted && !manager_submitted){
                    return `Your Employee's Self Assessment Review Notes for this ${item_type} Item in a ${period} Review on their Performance Scorecard are submitted.`
                }
                if(!employee_submitted && !manager_submitted){
                    return `Your Employee's Self Assessment Review Notes for this ${item_type} Item in a ${period} Review on their Performance Scorecard are now due.`
                }
                if(!employee_submitted && manager_submitted){
                    return `Your Employee's Self Assessment Review Notes for this ${item_type} Item in a ${period} Review on their Performance Scorecard are now due.`
                }
                if(employee_submitted && manager_submitted && !final_submitted){
                    return `Your Employee's Self Assessment Review Notes for this ${item_type} Item in a ${period} Review on their Performance Scorecard are submitted.`
                }
                if(final_submitted){
                    return `Your Employee's Self Assessment Review Notes for this ${item_type} Item in a ${period} Review on their Performance Scorecard are submitted.`
                }
                return `${this.viewingUserType} - missing employee_description`;
            }

        },
        //eslint-disable
        manager_description(){
            // eslint-disable-next-line
            let final_submitted = this.details_page_data.comment_status.final_submitted;
            // eslint-disable-next-line
            let employee_submitted = this.details_page_data.comment_status.employee_submitted;
            // eslint-disable-next-line
            let manager_submitted = this.details_page_data.comment_status.manager_submitted;
            //eslint-disable-next-line
            let item_type = 'Value';
            //eslint-disable-next-line
            let period = 'Yearly';

            if(this.viewingUserType == 'manager'){
                if(employee_submitted && !manager_submitted){
                    return `Your Assessment Notes for this item are now due. Once you have submitted you cannot change your input. Neither of you will see each other's submissions until you have both Submitted For Review. When this is done this item will be ready for a Final Review with your Employee.`
                }
                if(!employee_submitted && !manager_submitted){
                    return `Your Assessment Notes for this item are now due. Once you have submitted you cannot change your input. Neither of you will see each other's submissions until you have both Submitted For Review. When this is done this item will be ready for a Final Review with your Employee.`
                }
                if(!employee_submitted && manager_submitted){
                    return `Your Assessment Notes for this item are submitted. Neither of you will see each other's submissions until you have both Submitted For Review. When this is done this item will be ready for a Final Review with your Employee.`
                }
                if(employee_submitted && manager_submitted && !final_submitted){
                    return `Your Assessment Notes for this item are submitted. Your Employee has also submitted their Self Assessment Notes. You will now both be able to see each other's submissions and this item is ready for a Final Review with your Employee.`
                }
                if(final_submitted){
                    return `You have submitted your Assessment Notes and Final Review Notes. This item can no longer be edited.`
                }
                return `${this.viewingUserType} - missing manager_description`;
                
            }
            else{
                if(employee_submitted && !manager_submitted){
                    return `Your Manager's Assessment Notes for this item are now due. Neither of you will see each other's submissions until you have both Submitted For Review. When this is done this item will be ready for a Final Review with your Manager.`
                }
                if(!employee_submitted && !manager_submitted){
                    return `Your Manager's Notes for this item are now due. Neither of you will see each other's submissions until you have both Submitted For Review. When this is done this item will be ready for a Final Review with your Manager.`
                }
                if(!employee_submitted && manager_submitted){
                    return `Your Employee has submitted their Assessment Notes for this item. Neither of you will see each other's submissions until you have both Submitted For Review. When this is done this item will be ready for a Final Review with your Manager.`
                }
                if(employee_submitted && manager_submitted && !final_submitted){
                    return `Your Manager has submitted their Assessment Notes for this item. You will now both be able to  see each other's submissions and this item is ready for a Final Review with your Manager.`
                }
                if(final_submitted){
                    return `Your Manager has also submitted their Assessment Notes and Final Review Notes. This item can no longer be edited.`
                }
                return `${this.viewingUserType} - missing manager_description`;
            }
        },
        showManagerCommentButton(){
            if(this.details_page_data.comment_status.manager_submitted && this.details_page_data.comment_status.final_submitted){
                return false;
            }
            
            if(this.details_page_data.comment_status.manager_submitted && !this.details_page_data.comment_status.final_submitted){
                return true;
            }
            return true;
        },
        hasManagerSubmittedComments(){ 
            if(this.details_page_data.comment_status.final_submitted){
                return true;
            }
            
            else{
                return this.details_page_data.comment_status.manager_submitted;
            }
            // if(this.details_page_data.comment_status.manager_submitted && this.details_page_data.comment_status.final_submitted){
            //     return true;
            // }
            // return this.details_page_data.comment_status.final_submitted
        },
        hasEmployeeSubmittedComments(){
            return this.details_page_data.comment_status.user_submitted;
        },
        numberToWordsDictionary(){
            return {
                1: 'One',
                2: 'Two',
                3: 'Three',
                4: 'Four',
                5: 'Five',
                6: 'Six',
                7: 'Seven',
                8: 'Eight',
                9: 'Nine',
                10: 'Ten'
            }
        }
    },
    methods:{
        ...scorecardMethods,
        debugComments(){
            this.loadScorecardSummaryComments(this.loaded_user_scorecard.scorecard_period_id)
        },
        handleRemoveComment(type, index){
            if(type == 'employee'){
                this.employee_comments.splice(index, 1);
            }
            else{
                this.manager_comments.splice(index, 1);
            }
        },
        handleInsertSavedComment(type, payload){
            //* remove old comment card and insert newly saved comment
            if(type == 'employee'){
                let index = this.employee_comments.findIndex( (item) => { return item.id == payload.old_id} );
                this.employee_comments.splice(index, 1);
                this.employee_comments.push(payload.comment);
            }
            else{
                let index = this.manager_comments.findIndex( (item) => { return item.id == payload.old_id} );
                this.manager_comments.splice(index, 1);
                this.manager_comments.push(payload.comment);
            }
        }  ,
        insertEmptyNote(type){
            let comments_length = type == 'employee' ? this.employee_comments.length : this.manager_comments.length;
            if(comments_length >= 10){
                this.$swal.fire('Max comment limit reached');
                return;
            }

            let random_id = window.webix.uid();
            let comment = {
                id: random_id,
                is_new: true,
                comment: ''
            }
            if(type == 'employee'){
                this.employee_comments.push(comment);
            }
            else{
                this.manager_comments.push(comment);
            }
            this.$nextTick(()=>{
                let el = document.getElementById(random_id);
                el.scrollIntoView({
                    behavior: 'smooth'
                });
                this.$refs[random_id][0].focusInput();
            })
        },
        // eslint-disable-next-line
        saveEmployeeRating(quarter, value){
            let params = {
                scorecard_period_id: this.loaded_user_scorecard.scorecard_period_id,
                score: value,
                type: 'employee',
                //value_id: this.details_page_data.id,
                // section_type: 'value'
            }
            params = this.setIdForType(params);
            this.saveScorecardScore(params)
            .then(()=>{
                this.reloadCurrentDetailsPage();
            })
        },
        // eslint-disable-next-line
        saveManagerRating(quarter, value){
            let params = {
                scorecard_period_id: this.loaded_user_scorecard.scorecard_period_id,
                score: value,
                type: 'manager',
                //value_id: this.details_page_data.id,
                // section_type: 'value'
            }
            params = this.setIdForType(params);
            this.saveScorecardScore(params)
            .then(()=>{
                this.reloadCurrentDetailsPage();
            })
        },
        saveFinalRating(value){
            let params2 = {
                scorecard_period_id: this.loaded_user_scorecard.scorecard_period_id,
                score: value,
                type: 'final',
                //value_id: this.details_page_data.id,
                // section_type: 'value'
            }
            params2 = this.setIdForType(params2);
            this.saveScorecardScore(params2)
            .then(()=>{
                this.reloadCurrentDetailsPage();
            })
            
        },
        showSubmitNotesPrompt(type){
            if(type == 'final'){
                //* check comments if any final commests are saved
                let final_comments = this.manager_comments.filter(( comment ) => comment.final_comment );
                if(final_comments.length == 0){
                    this.$swal.fire('Please save a final comment before submitting a final review');
                    return;
                }
            }

            this.show_modal = true;
            this.modal_type = type;
            this.$nextTick(()=>{
                this.$bvModal.show('reviewNotSubmissionPrompt');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "reviewNotSubmissionPrompt") {
                        this.show_modal = false;
                        this.modal_type = null;
                    }
                });
            })
        },
        submitNotes(type){
            //* handle case for final submission
            if(type == 'manager' && this.details_page_data.comment_status.manager_submitted){
                this.submitFinalNotes();
                return;
            }

            this.is_submitting_comments = true;
            let params = {
                scorecard_period_id : this.loaded_user_scorecard.scorecard_period_id,
                item_type : this.details_page_type,
                item_id : this.details_page_data.id,
                comment_type : type
            }
            this.submitScorecardComments(params)
            .then(()=>{
                this.is_submitting_comments = false;
                this.$bvModal.hide('reviewNotSubmissionPrompt');
                this.reloadCurrentDetailsPage();
            })
            .catch(()=>{
                this.is_submitting_comments = false;
            })
        },
        submitFinalNotes(){
            this.is_submitting_comments = false;
            let params = {
                scorecard_period_id : this.loaded_user_scorecard.scorecard_period_id,
                item_type : this.details_page_type,
                item_id : this.details_page_data.id,
                comment_type : 'final'
            }
            this.submitScorecardComments(params)
            .then(()=>{
                this.is_submitting_comments = false;
                this.$bvModal.hide('reviewNotSubmissionPrompt');
                this.reloadCurrentDetailsPage();
            })
            .catch(()=>{
                this.is_submitting_comments = false;
            })
        },


        //* helpers
        setIdForType(parameter_object){
            let params = parameter_object;
            switch(this.details_page_type){
                case 'value':{
                    params['value_id'] = this.details_page_data.id
                    break;
                }
                case 'productivity':{
                    params['target_id'] = this.details_page_data.id
                    break;
                }
                case 'objective':{
                    params['objective_id'] = this.details_page_data.id
                    break;
                }
                default:{
                    break;
                }
            }

            return params;
        },
        getSubmitPromptText(type){
            let comment_count = 1;
            comment_count = this.numberToWordsDictionary[comment_count];
            let period_string = this.loaded_user_scorecard.scorecard_period_type;

            let is_final_submission = this.details_page_data.comment_status.employee_submitted && this.details_page_data.comment_status.manager_submitted;
            if(is_final_submission){
                let text = `<span style="color: #8881B3">
                    You are submitting
                    <span style="color: #F46A6A">${comment_count}</span> 
                    <strong class="text-capitalize">${period_string} Final Assessment Note</strong>
                    on this item. After you have done so you will not be able to make changes.
                    <br>
                    <br>
                    What would you like to do?
                    </span>`
                return text;
            }
            else{
                //set target user type - eg if manager is submitting, then target is employee
                let target_user_type = type == 'manager' ? 'employee' : 'manager';
                let text = `<span style="color: #8881B3">
                    You are submitting
                    <span style="color: #F46A6A">${comment_count}</span> 
                    <strong class="text-capitalize">${period_string} Self Assessment Note</strong> 
                    on this item. After you have done so you will not be able to make changes.
                    <br>
                    <br>
                    When your <strong class="text-capitalize">${target_user_type}</strong> has submitted their 
                    <br>
                    <strong class="text-capitalize">${period_string} Assessment Notes</strong>
                    you will both be able to see each other's submissions and this item will be ready for <strong>Final Review</strong>.
                    <br>
                    <br>
                    What would you like to do?
                </span>`
                return text;
            }

            
        },
        reloadCurrentDetailsPage: debounce(function(){
            this.loadUserScorecard({    
                user_id: this.loaded_user_scorecard.job_data.user_id,
                job_id: this.loaded_user_scorecard.job_data.job_id,
                template_id:  this.selected_scorecard_template_id,
            })

            let params = {
                scorecard_period_id : this.loaded_user_scorecard.scorecard_period_id
            };

            switch(this.details_page_type){
                case 'value':{
                    params['value_id'] = this.details_page_data.id
                    this.loadValuesDetails(params);
                    break;
                }
                case 'productivity':{
                    params['target_id'] = this.details_page_data.id
                    this.loadProductivityDetails(params);
                    break;
                }
                case 'objective':{
                    params['objective_id'] = this.details_page_data.id
                    this.loadObjectiveDetails(params);
                    break;
                }
                default:{
                    break;
                }
            }
        }, 500),
        getStatusClass(type){
            console.log('Fix user type classes', type);
            return '';
        },
        scorecardPeriod(period_key){
            return this.loaded_user_scorecard.scorecard_period_key == period_key;
        },
        processScores(){
            if(this.disableScores){
                //* stop calc of scores if disabled prop is passed in
                return;
            }

            for(let i = 1; i<=4; i++){
                this.employee[`q${i}`] = this.details_page_data.scores[i].employee;
                this.manager[`q${i}`] = this.details_page_data.scores[i].manager;
            }
        },
        countExititngComments(data){
            return data.filter(obj => obj.is_new === undefined || obj.is_new === false).length;
        },
        getUserName(id){
            let user = this.all_users.find( user => { return user.id == id });
            return user ? user.name : '';
        },
        getUserProfilePicture(id){
            let user = this.all_users.find( user => { return user.id == id });
            return user ? user.avatar_src : '';
        },
        hasSubmittedComments(type){
            if(type == 'manager'){
                if(this.details_page_data.comment_status.final_submitted)
                return this.details_page_data.comment_status.final_submitted;
            }
            else{
                return this.details_page_data.comment_status.user_submitted;
            }
        }
    },
    mounted(){
        this.processScores();
    },
}
</script>

<style>
.review-notes-prompt-text{
    font-size: 15px;
}
</style>