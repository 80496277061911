<template>
    <!-- <div class="d-flex m-1" style="align-items: center;">
        <span>
            {{ getValue(meta) }}
        </span>
        <span id="toggle_container_styles" class="btn-group m-1" style="">
                <span class="">
                    <b-button @click="emitClickAction(false)" size="sm" class="" :class="selected_value == false  ? 'yes_no_selected_styles toggle_styles':'toggle_outline_styles toggle_styles'" title="Calendar Year">
                        {{ meta.toggle_pair.first }}                   
                    </b-button>
                    <b-button @click="emitClickAction(true)" size="sm" class="" :class="selected_value == true  ? 'yes_no_selected_styles toggle_styles':'toggle_outline_styles toggle_styles'" style="margin-left: 5px" title="Financial">
                        {{ meta.toggle_pair.last }} 
                    </b-button>
                </span>
        </span>
    </div> -->
    <div class="element-wrapper d-flex">
        <span class="element-content">
            <span class="m-1" style="">
                <span class="toggle_label_container">
                    <span @click="emitClickAction(false)" size="sm" class="toggle_label_button" :class="selected_value == false  ? 'toggle_label_button_on':'toggle_label_button_off'" >
                        No                   
                    </span>
                    <span @click="emitClickAction(true)" size="sm" class="toggle_label_button" :class="selected_value == true  ? 'toggle_label_button_on':'toggle_label_button_off'">
                        Yes
                    </span>
                </span>
            </span>
        </span>
    </div>
</template>

<script>
import treeComponentMixin from '@/mixins/treeComponentMixin.js'
export default {
    name: 'toggle_label',
    mixins: [ treeComponentMixin ],
    data:() => ({
       selected_value: false,
    }),
    props:{
        meta:{}
    },
    computed:{

    },
    methods:{
        emitClickAction(value){
            // emit the opposite of the expanded state
            // let action = {
            //     //id: payload.id,
            //     value: !this.selected_value
            // }
            //this.$emit('handleClickAction', action);
            this.selected_value = value
            this.$emit('updatedValue',value );
        }
    },
    mounted(){
        if(this.meta && this.meta.value != undefined){
            if(!this.meta.value || this.meta.value == 'no'){
                this.selected_value = false;
            }
            else{
                this.selected_value = true;
            }
        }
    },
}
</script>

<style>
/* .button_styles {
    border-radius: 1rem;
    min-width: 10px;
} */
.toggle_styles {
    /* width: 2rem; */
    height: 1.5rem;
    font-size: 0.6rem !important;
    border-radius: 15px !important;
}
.selected_value {
    background-color: #928BB9;
}
#toggle_container_styles {
    border-radius: 15px;
    background-color: #736BA5;
}
.toggle_outline_styles {
    color: #736BA5 !important;
    background-color: #928BB9 !important;
}
.yes_no_selected_styles {
    color: #736BA5 !important;
    background-color: white !important;
}

.toggle_label_container{
    display: flex;
    width: 79px;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 20px;
    background: #8881B3;
}
.toggle_label_button{
    display: flex;
    width: 34px;
    height: 20px;
    padding: 4px 7px;
    justify-content: center;
    align-items: center;
    gap: 2px;
    flex-shrink: 0;
    border-radius: 20px;
}
.toggle_label_button_on{
    border: 1px solid #BFC8D6;
    background: #F4F4F4;
    border-radius: 20px;
}
.toggle_label_button_off{
    background: #928BB9;
}

</style>