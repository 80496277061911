<template>
    <div class="element-wrapper d-flex my-auto remove_new_row_grey" >
        <span class="element-content">
            <font-awesome-layers class="fa-lg " @click.stop="handleClick">
                <font-awesome-icon icon="fa-solid fa-circle" style="color: white !important;" />

                <font-awesome-icon v-if="is_empty_row" icon="fa-solid fa-plus" transform="shrink-6" :style="{ color: 'grey' }" />
                <font-awesome-icon v-if="!is_empty_row" icon="fa-solid fa-minus" transform="shrink-6" :style="{ color: 'grey' }" />
            </font-awesome-layers>
        </span>

    </div>
        
</template>

<script>

import treeComponentMixin from '@/mixins/treeComponentMixin.js'
export default {
    name: 'add_remove_button',
    mixins: [ treeComponentMixin ],
    props:{
        meta:{},
        row_data:{
            required: false,
        },
        row_position:{},
    },
    inject:['renderer_config'],
    components:{},
    data:() => ({
        new_row: false,
    }),
    computed:{
        is_empty_row(){
            return this.row_data == undefined;
        }
    },
    methods:{
        handleClick(){
            if(this.is_empty_row){
                //* add row action
                this.emitAddAction();
            }
            else{
                if(this.row_data.new_row){
                    //* unsaved row
                    let soft_delete = true;
                    this.emitDeleteAction(soft_delete);
                }
                //* delete row action
                this.$swal.fire({
                    title: "Delete row?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes, delete it!",
                }).then((result) => {
                    if (result.value) {
                        //* If user selects yes
                        this.emitDeleteAction();
                    }
                });
            }
        },
        emitClickAction(){
            let action = {
                action: 'expand',
                value: !this.isElementExpanded(this.parent_settings)
            }
            this.$emit('handleClickAction', action);
        },
        emitDeleteAction(soft_delete = false){
            let action = {
                action: 'delete_row',
                soft_delete: soft_delete,
                id: this.row_data.id,
            }
            this.$emit('handleAction', action);
        },
        emitAddAction(){
            let action = {
                action: 'add_row',
            }
            this.$emit('handleAction', action);
        }
    },
    mounted(){
        if(this.row_data){
            console.log('row_data', this.row_data);
        }
        console.log('index pos', this.row_position);

        console.log('RENDERER config', this.renderer_config);
    },
}
</script>

<style>
/* .scored_card_red {
    background-color: #F46A6A
}
.amber {
    background-color: #FFA800;
}
.green {
    background-color: #34C38F;
} */

.badge_styles_larger {
    max-width: 116px !important;
    font-size: 9px !important;
}

/* .badge_styles {
    color: white;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    font-size: 10px;
    display: flex;
    margin: 0 auto;
    min-width: 40px;
    min-height: 20px;
    border-radius: 0.15rem;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    text-wrap: nowrap;
} */

.remove_new_row_grey{
    
}
/* .row_height {
    min-height: 50px;
} */
/* .line {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
} */


</style>