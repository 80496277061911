<template>
    <div class="d-flex badge-container">
        <connectionLines v-if="hasConnectionLines(meta)" :row_index="row_position.index" :row_length="row_position.length" :connection_lines="meta.connection_lines"></connectionLines>
    </div>
</template>

<script>
import treeComponentMixin from '@/mixins/treeComponentMixin.js'
import connectionLines from './sub_elements/svgConnectionLines.vue'
export default {
    mixins: [ treeComponentMixin ],
    name: 'empty_block',
    components:{
        connectionLines
    },
    props:{
        meta:{},
        row_position:{},
    },
    data:() => ({}),
    computed:{
    },
    methods:{},
    mounted(){},
}
</script>

<style>

</style>