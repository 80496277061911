<template>
    <div>
        <ComponentHeader back-button @exit="$emit('exit')" class="mb-3" title="Performance Scorecard Builder" description="The performance scorecard shows your data and results for the selected role and period of your employment. Use your scorecard to keep track of what is required of you within your role. You can make notes on individual items, your manger also has access to your scorecard and may also add notes in preparation for a review.">
            <template #extra-content>
                <HeaderCard
                    @archive="handleArchiveEvent"
                    @duplicate="handleCopyEvent"
                    @save="handleSaveEvent"
                />
            </template>
            <template #extra-content2>
                <ScorecardDates />
            </template>
        </ComponentHeader>
        <!-- <b-button @click="getComponentData()">Debug</b-button> -->
        <TotalScore @updateSectionWeightingTotal="handleWeightingUpdate" ref="totalScore" :ref_value="'totalScoreComponent'"/>
        <Qualifiers ref="qualifiers" :ref_value="'qualifiersComponent'"/>
        <Modifiers ref="modifiers" :ref_value="'modifiersComponent'"/>
        
        <b-overlay no-wrap :show="is_loading"></b-overlay>
    </div>
</template>

<script>
import { scorecardMethods, scorecardComputed } from '@/state/helpers';
import TotalScore from './tableComponents/builder/totalScore.vue';
import Qualifiers from './tableComponents/builder/qualifiers.vue';
import Modifiers from './tableComponents/builder/modifiers.vue';

import HeaderCard from './tableComponents/builder/headerCard.vue';
import ComponentHeader from '../widgets/header/componentHeader.vue';
import scorecardDates from './pageComponents/scorecardDates.vue';

export default {
    
    components: {
        HeaderCard,
        ComponentHeader,
        scorecardDates,
        TotalScore,
        Qualifiers,
        Modifiers,
    },
    data:() => ({
        is_loading: false,
        section_weighting_total: 0,
    }),
    computed:{
        ...scorecardComputed,
    },
    methods:{
        ...scorecardMethods,
        handleWeightingUpdate(value){
            this.section_weighting_total = value;
        },
        handleArchiveEvent(id){
            this.is_loading = true;
            this.archiveScorecardTemplate({
                id: id
            })
            .then(()=>{
                this.loadScorecardTemplates({
                    show_archived: 1,
                })
                .then(()=>{
                    this.is_loading = false;
                    this.$emit('exit');
                })
            })
            .catch((e)=>{
                console.log(e);
                this.is_loading = false;
            })
        },
        handleCopyEvent(params){
            this.is_loading = true;
            let save_params = {
                id: this.loaded_template.id,
                name: params.name
            }
            this.duplicateScorecardTemplate(save_params)
            .then((new_id)=>{
                this.loadScorecardTemplates({
                    show_archived: 1,
                })
                this.setSelectedScorecardTemplateID(new_id);
                this.loadScorecardTemplate({
                    id: new_id,
                })
                .then(()=>{
                    this.is_loading = false;
                })
                .catch(()=>{
                    this.is_loading = false;
                })
            })
            .catch(()=>{
                this.is_loading = false;
            })
        },
        handleSaveEvent(scorecard_header){
            if(this.section_weighting_total != 100){
                this.$swal.fire('Please check that your enabled sections total 100%!');
                return;
            }
            //* get name/desc from header object
            let name = scorecard_header.name;
            if(name == '' || name == null){
                this.$swal.fire('Please add a title for the scorecard!');
                return;
            }

            this.is_loading = true;

            let description = scorecard_header.description;

            let component_data = this.getComponentData();

            let qualifiers = [];
            component_data.qualifiers.forEach( (item, index) => {
                qualifiers.push({
                    id: -1,
                    qualifier_text: item.qualifier_text,
                    order: index+1
                })
            })

            let modifiers = [];
            component_data.modifiers.forEach( (item, index) => {
                modifiers.push({
                    id: -1,
                    percentage: item.percentage,
                    modifier_text: item.modifier_text,
                    order: index+1
                })
            })

            let sections = component_data.scorecard_total;

            if(this.selected_scorecard_template_id == -1){
                //* unsaved scorecard
                this.saveScorecardTemplate({
                    archived: 0,
                    id: -1,
                    name: name,
                    description: description,
                })
                .then((response)=>{
                    //* refresh scorecard template list in the background
                    this.loadScorecardTemplates({
                        show_archived: 0,
                    })

                    //* Save sections for new scorecard
                    let sections_payload = {
                        template_id: response.template_id,
                        qualifiers: qualifiers,
                        modifiers: modifiers,
                        sections: sections,
                    }
                    this.saveClientScorecardTemplateSections(sections_payload)
                    .then(()=>{ this.is_loading = false; })
                    .catch(()=>{ this.is_loading = false; })
                })
                .catch(()=>{ this.is_loading = false; })
            }
            else{
                this.saveScorecardTemplate({
                    archived: 0,
                    id: this.selected_scorecard_template_id,
                    name: name,
                    description: description,
                })
                .then(()=>{
                    this.loadScorecardTemplates({
                        show_archived: 0,
                    })
                })
                //* existing scorecard
                let sections_payload = {
                    template_id: this.selected_scorecard_template_id,
                    qualifiers: qualifiers,
                    modifiers: modifiers,
                    sections: sections,
                }
                this.saveClientScorecardTemplateSections(sections_payload)
                .then(()=>{ this.is_loading = false; })
                .catch(()=>{ this.is_loading = false; })
            }

        },
        
        getComponentData(){
            let scorecard_total = this.$refs.totalScore.returnComponentData();

            let qualifiers =  this.is_qualifiers_enabled? this.$refs.qualifiers.returnComponentData() : [];

            let modifiers = this.is_modifiers_enabled ? this.$refs.modifiers.returnComponentData() : [];

            return {
                scorecard_total: scorecard_total,
                qualifiers: qualifiers,
                modifiers: modifiers,
            }
        }
    },
    mounted(){
        if(this.selected_scorecard_template_id != -1){
            this.loadScorecardTemplate({
                id: this.selected_scorecard_template_id,
            })
        }
    },
    beforeDestroy(){
        this.setSelectedScorecardTemplateID(-1);
    }
}
</script>

<style>
.scorecard_row_width{
    width: 850px;
}
</style>