<template>
    <div>
        <b-row>
            <b-col cols="6">
                <div class="d-flex h-100" style="flex-direction: column; justify-content: space-between;">
                    <span class="m-1 mt-2 pt-1">
                        <span class="blue_dark main_heading mt-2">
                            <span v-if="backButton" class="header_back_icon"><font-awesome-icon :icon="['fas', 'angle-left']" @click="$emit('exit')"  /></span><!--style="height: 25px !important; width: 25px !important; margin-top: 5px; color: #CB6CC3"-->
                        
                            {{ title }} <span style="display:inline-flex;"><font-awesome-icon style="width:20px" :icon="['fas', 'info-circle']" />
                        </span>
                    </span>
                    <span class="p-2" style=" height: 100%; width: 558px; white-space: normal;">
                        <p v-if="useHtml" v-html="description" class="text_styling main_text_blue" style="padding: 5px"></p>
                        <p v-else class="text_styling main_text_blue"  style="padding: 5px">
                            {{ description }}
                        </p>
                    </span>
                    </span>
                    <!-- This must be moved out, I tried using slots but it gave trouble  -->
                    <!-- <span class="d-flex">
                        <ScorecardDates />
                    </span> -->
                    <b-col class="d-flex">
                        <slot name="extra-content2"></slot>
                    </b-col>
                </div>
                
            </b-col>
            <b-col cols="6" class="d-flex justify-content-end" >
                <slot  name="extra-content"></slot>
            </b-col>
        </b-row>
        <div v-if="false" class="d-flex m-1 p-1 container_position">
            <b-col class="d-flex" style="flex-direction: column;">
                <span class="blue_dark main_heading">
                    {{ title }} <span style="display:inline-flex;"><font-awesome-icon style="width:20px" :icon="['fas', 'info-circle']" /></span>
                </span>
                <span style="width: 558px">
                    <p class="text_styling main_text_blue">
                        {{ description }}
                    </p>
                </span>
                <!-- <scoreCardFilter /> -->
            </b-col>
            <b-col class="d-flex userCard" style="flex-direction: column; justify-content: end;">
                <slot name="extra-content"></slot>
            </b-col>
        </div>
    </div> 
</template>

<script>
//import ScorecardDates from '../../scorecard/pageComponents/scorecardDates.vue';

export default {
    components: {
        //ScorecardDates
    },
    data:() => ({}),
    props:{
        backButton:{
            type: Boolean,
            default: false,
        },
        title:{
            default:()=>{
                return '';
            }
        },
        title_info:{
            default:()=>{
                return '';
            }
        },
        description:{
            default:()=>{
                return '';
            }
        },
        useHtml:{
            type: Boolean,
            default: false,
        }
    },
    computed:{},
    methods:{},
    mounted(){},
}
</script>

<style>
.text_styling {
    font-size:0.65rem;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
}
.container_position {
    margin-top: 15px !important; 
    padding-top: 15px !important;
    margin-bottom: 5px !important; 
    padding-bottom: 5px !important;
    width: 1400px
}
.gradient {
    background-image: linear-gradient(to right, #484F6A , #481886);
    /* background-attachment: fixed */
}

@media (max-width: 1413px) {
    .container_position {
        flex-direction: column;
    }
    .userCard {
        margin-top: 20px;
    }
}
</style>