import { render, staticRenderFns } from "./values.vue?vue&type=template&id=04c4f42d"
import script from "./values.vue?vue&type=script&lang=js"
export * from "./values.vue?vue&type=script&lang=js"
import style0 from "./values.vue?vue&type=style&index=0&id=04c4f42d&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports