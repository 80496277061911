<template>
    <div>
        <span style="margin-left: -6px;" :style="rating_value == null ? 'color: red;' : 'color: transparent;'">*</span>
        <b-dropdown ref="scoreDropdown" @show="validateCommentCount()" class="review-dropdown" size="sm" variant="outline-primary" style="width: 200px;">
            <template #button-content>
                <span v-if="rating_value == null" class="w-100">
                    <strong>1-10</strong>
                </span>
                <span v-else>
                    <strong>{{ rating_value }}</strong>
                </span>
            </template>
            <b-dropdown-item @click="setValue(item)" v-for="(item, index) in 10" :key="index">
                <strong>{{ item }}</strong>
            </b-dropdown-item>
        </b-dropdown>
    </div>
</template>

<script>
export default {
    props:{
        value:{},
        comment_length: {
            default: 0,
        }
    },
    
    data:() => ({
        rating_value: null,
    }),
    computed:{},
    watch:{
        value:{
            immediate: true,
            handler(){
                this.rating_value = this.value;
            }
        }
    },
    methods:{
        validateCommentCount(){
            if(this.comment_length == 0){
                this.$swal.fire({
                    title: 'Please add a review comment before saving a score',
                    //showDenyButton: true,
                    confirmButtonText: 'Ok',
                    //denyButtonText: `Add comment`,
                    confirmButtonColor: '#3085d6', // Secondary grey
                    //denyButtonColor: '#3085d6', // Primary blue
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$emit('addComment');
                    } 
                    // else if (result.isDenied) {
                    //     this.$emit('addComment');
                    // }
                });
                this.$refs.scoreDropdown.hide();
            }
        },
        setValue(item){
            
            this.rating_value = item;
            this.$emit('updated', item);
        }
    },
    mounted(){},
}
</script>

<style>
.review-dropdown > .dropdown-menu.show{
    top: auto !important;
    bottom: 100% !important;
    left: 0 !important;
}
</style>
