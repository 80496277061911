<template>
    <b-card no-body class="p-3 review-note-card" @mouseenter="is_hovering = true" @mouseleave="is_hovering = false">
        <!-- floating button -->
        <b-button v-show="showCloseButton" @click="removeComment" size="sm" variant="whitee" class="close-btn">x</b-button>
        <b-button v-show="canEditComment(comment) && showEditButton && is_hovering" @click="is_editing = true" size="sm" variant="whitee" class="close-btn">edit</b-button>

        <b-form-textarea ref="comment_input" v-show="is_editing || comment.is_new" v-model="local_comment" rows="5"></b-form-textarea>
        <p v-show="!is_editing && comment.is_new == undefined">
            {{ local_comment }}
        </p>
        <div class="d-flex">
            <b-button v-if="comment.is_new" variant="success" @click="saveComment" size="sm">Save</b-button>
            <b-button v-if="is_editing" variant="success" @click="updateComment" size="sm">Update</b-button>
            
            <b-button v-if="comment.is_new == undefined" variant="lightee" size="sm" class="ms-auto">
                <strong class="m-1">{{ getPeriodValue(comment.scorecard_period_id) }}</strong>
                <strong class="me-1" v-show="showFinalState && comment.final_comment">
                    FINAL REVIEW
                </strong>
                <span>{{ formatDateTime }}</span>
            </b-button>
        </div>
        <b-overlay no-wrap :show="is_saving_comment"></b-overlay>
    </b-card>
</template>

<script>
import { format } from 'date-fns';
import { scorecardComputed, scorecardMethods } from '@/state/helpers';
export default {
    props:{
        showFinalState:{
            required: false,
            type: Boolean,
            default: false,
        },
        comment:{},
    },
    data:() => ({
        is_saving_comment: false,
        local_comment: null,
        is_editing: false,
        is_hovering: false,
    }),
    computed:{
        ...scorecardComputed,
        formatDateTime() {
            const date = new Date(this.comment.created_at);
            return format(date, 'HH.mm :dd/MM/yyyy');
        },
        showCloseButton(){
            if(this.comment.is_new){
                return true;
            }
            return this.is_editing;
        },
        showEditButton(){
            if(this.comment.is_new){
                return false;
            }

            return this.is_editing == false;
        },
    },
    methods:{
        ...scorecardMethods,
        getPeriodValue(value) {
            console.log(value)
            let found_period = this.loaded_user_scorecard.scorecard_periods.find( period => {
                return period.id == value;
            })
            //set value based on key
            switch(found_period.period_key){
                case '1':{
                    return 'Q1'
                }
                case '2':{
                    return 'Q2'
                }
                case '3':{
                    return 'Q3'
                }
                case '4':{
                    return 'Q4'
                }
                case '5':{
                    return 'H1'
                }
                case '6':{
                    return 'H2'
                }
                case '7':{
                    return 'YR'
                }
            }
            return found_period;
        },
        focusInput(){
            setTimeout(()=>{
                this.$refs.comment_input.focus();
            }, 150)
        },
        updateComment(){
            let params = {
                comment: this.local_comment,
                comment_id: this.comment.id
            }
            this.is_saving_comment = true;
            this.updateScorecardComment(params)
            .then(() => {
                this.is_editing = false;
                this.is_saving_comment = false;
            })
            .catch(()=>{
                this.is_saving_comment = false;
            })
        },
        saveComment(){
            let params = {
                scorecard_period_id: this.loaded_user_scorecard.scorecard_period_id,
                item_type: this.details_page_type,
                comment: this.local_comment,
                item_id: this.details_page_data.id
            }
            this.is_saving_comment = true;
            this.saveScorecardComment(params)
            .then( comment_response => {
                this.is_saving_comment = false;
                let params = {
                    old_id: this.comment.id,
                    comment: comment_response.saved_comment
                }
                this.$emit('insertSavedComment', params);
            })
            .catch(()=>{
                this.is_saving_comment = false;
            })
        },
        removeComment(){
            if(this.is_editing){
                // simply stop edit state for exititng comment
                this.is_editing = false
            }
            else{
                // for new comment, remove exititng temp comment object
                this.$emit('removeComment')
            }
        },
        //eslint-disable-next-line
        canEditComment(comment){
            // can only edit own comments
            if(comment.comment_user_type != this.viewingUserType){
                return false;
            }

            if(this.viewingUserType == 'mnager'){
                // if comment is not final and manager comments are not submitted, allow edit
                if(comment.final_comment == false && this.details_page_data.comment_status.manager_submitted == false){
                    return true;
                }
                if(comment.final_comment && this.details_page_data.comment_status.final_submitted == false){
                    return true;
                }
            }
            else{
                //* employee
                return this.details_page_data.comment_status.employee_submitted == false;
            }

            return false;
        }
    },
    mounted(){
        this.local_comment = this.comment.comment;
    },
}
</script>

<style>
.review-note-card:hover{
    border-color:rgb(0, 123, 255);
}
.close-btn{
    position:absolute;
    top:0;
    right:0;
}
</style>