<template>
    <div class="w-100">
        <div class="header_card_container">
            <div class="header_card_title mb-2">
                {{ getCardTitle }}
            </div>
            <div class="d-flex mb-2">
                <div class="flex-grow-1">
                    <b-form-input v-model="scorecard_name" class="input_field" ref="scorecardNameInput" size="sm"></b-form-input>
                </div>
                <div class="me-1 ms-2"><font-awesome-icon style="height: 25px; width:20px" :icon="['fas', 'info-circle']" /></div>
            </div>
            <div class="small-text">
                <div class="validation_text">
                    Weightings cannot add up to more than 100%
                </div>
                <div class="validation_text">
                    Bonus qualifier text cannot be empty
                </div>
                <div class="validation_text">
                    Modifier text cannot be empty
                </div>
            </div>

            <div class="mt-4 d-flex">
                <span class="ms-auto">
                    <b-button @click="$emit('archive', selected_scorecard_template_id)" variant="info" :disabled="isNewScorecard" size="sm" class="me-1">
                    Archive
                    </b-button>
                    <b-button @click="showDuplicatePopup" variant="info" :disabled="isNewScorecard" size="sm" class="me-1">
                        Make a copy
                    </b-button>
                    <b-button variant="info" @click="emitSave" size="sm">
                        Save
                    </b-button>
                </span>
            </div>
        </div>
        
    </div>
</template>

<script>
import '../headerCard.css';
import { scorecardComputed, scorecardMethods } from '@/state/helpers';
export default {
    data:() => ({
        scorecard_name: '',
        scorecard_description: '',
    }),
    computed:{
        ...scorecardComputed,
        isNewScorecard(){
            return this.selected_scorecard_template_id == -1;
        },
        getCardTitle(){
            return this.isNewScorecard
            ? 'Name this Scorecard template:'
            : 'Name:'
        }
    },
    watch:{
        loaded_template(newValue){
            this.scorecard_name = newValue.name;
        }
    },
    methods:{
        ...scorecardMethods,
        emitSave(){
            //* pass name to save handler
            this.$emit('save', {
                is_new_scorecard: this.isNewScorecard,
                name: this.scorecard_name,
                description: this.scorecard_description,
            });
        },
        emitCopy(){

        },
        emitArchive(){

        },
        showDuplicatePopup(){
            let scorecard_title = this.loaded_template.name + ' - (Copy)';
            const self = this; // Reference to the Vue instance

            this.$swal.fire({
                title: "Enter Scorecard Name",
                input: "text",
                inputValue: scorecard_title, // Set the initial value of the input
                showCancelButton: true,
                confirmButtonText: "Duplicate",
                cancelButtonText: "Cancel",
                width: '62em',
                preConfirm: (value) => {
                if (!value) {
                    this.$swal.showValidationMessage("You need to enter a value!");
                }
                return value;
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    let params = {
                        name: result.value,
                    }
                    self.$emit('duplicate', params);
                //self.inputValue = result.value; // Update the variable with the new value
                }
            });
        }
    },
    mounted(){
        if(this.isNewScorecard){
            //* focus scorecard input for new name
            //! setTimeout used to set focus after details overlay transition finishes
            setTimeout(()=>{
                this.$refs.scorecardNameInput.focus();
            },500)
        }
        else{
            this.scorecard_name = this.loaded_template.name;
            this.scorecard_description = this.loaded_template.description;
            //*set scorecard name
        }
    },
}
</script>

<style>
</style>