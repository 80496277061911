<template>
    <div>
        <div v-html="reviewStatus"></div>
    </div>
</template>

<script>
import { scorecardComputed, adminComputed } from '@/state/helpers';
export default {
    data:() => ({}),
    computed:{
        ...scorecardComputed,
        ...adminComputed,
        employee_submitted(){
            return this.details_page_data.comment_status.user_submitted;
        },
        manager_submitted(){
            return this.details_page_data.comment_status.manager_submitted;
        },
        final_submitted(){
            return this.details_page_data.comment_status.final_submitted;
        },
        user_type(){
            //* user_id == logged in id => employee
            return this.loaded_user_scorecard.job_data.user_id == this.core.id ?
            'employee' : 'manager';
        },
        isForCurrentUser(){
            return this.loaded_user_scorecard.job_data.user_id == this.core.id;
        },
        isForManagerUser(){
            return this.loaded_user_scorecard.job_data.reports_to_user_id == this.core.id
        },
        reviewStatus(){
            let status = this.details_page_data.comment_status;
            if(status.final_submitted){
                //* if final review is submitted (use and manager should already be submitted)
                //* show 'Final' msg
                return `<span class="font-weight-bold text-success">Final Review Submitted</span> 
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check"><path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" class=""></path></svg>
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check"><path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" class=""></path></svg>
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check"><path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" class=""></path></svg>`;
            }
            else if(status.manager_submitted && status.user_submitted){
                //* if final is unsubmitted but user+manager has submitted
                return `<span class="font-weight-bold text-warning">Final Review Due</span>`;
            }
            switch(this.user_type){
                case 'employee':{
                    let line_1 = status.manager_submitted ? 'Manager Review Submitted' : 'Manager Review Due';
                    let line_2 = status.user_submitted ? 'Your Review Submitted' : 'Your Review Due';
                    return `<span class="font-weight-bold">
                                <div class="${this.getElementColor(status.manager_submitted)}">
                                    ${line_1}
                                    <svg v-if="${status.manager_submitted}" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check"><path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" class=""></path></svg>
                                </div>
                                <div class="${this.getElementColor(status.user_submitted)}">
                                    ${line_2}
                                    <svg v-if="${status.user_submitted}" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check"><path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" class=""></path></svg>
                                </div>
                            </span>`
                }
                case 'manager':{
                    let line_1 = status.manager_submitted ? 'Your Review Submitted' : 'Your Review Due';
                    let line_2 = status.user_submitted ? 'Employee Review Submitted' : 'Employee Review Due';
                    return `<span class="font-weight-bold">
                                <div class="${this.getElementColor(status.manager_submitted)}">
                                    ${line_1}
                                    <svg v-if="${status.manager_submitted}" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check"><path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" class=""></path></svg>
                                </div>
                                <div class="${this.getElementColor(status.user_submitted)}">
                                    ${line_2}
                                    <svg v-if="${status.user_submitted}" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check"><path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" class=""></path></svg>
                                </div>
                            </span>`
                }
                default:{
                    return '';
                }
            }
        },
    },
    methods:{
        getElementColor(status){
            return status == true ? 'text-success' : 'text-warning';
        }
    },
    mounted(){},
}
</script>

<style>

</style>