<template>
    <div class="element-wrapper d-flex my-auto" >
        <span v-if="useHtml(meta)" class="element-content" :class="[getColour, 'badge_styles']" v-html="html_string">
        </span>
        <span v-if="!useHtml(meta) && isRoundBadge" class="element-content round-badge-style quarter_high" >
            <span class="mx-auto my-auto">{{getValue(meta)}}</span>
        </span>
        <span v-if="!useHtml(meta) && !isRoundBadge && !hasNullState" class="element-content" :class="[getColour, 'badge_styles']">
            {{ getValue(meta) }}
        </span>
        <span v-else-if="!useHtml(meta) && !isRoundBadge && hasNullState" class="element-content" :class="['badge_styles', 'null-badge', 'p-0']">
            <span class="null-badge-span"></span>
        </span>


        <!-- <svg v-if="hasRightLine" class="line" height="3" width="100%">
            <line x1="50%" y1="1.5" x2="100%" y2="1.5" stroke="currentColor" stroke-width="3"/>
        </svg>
        <svg v-if="hasLeftLine" class="line" height="3" width="100%">
            <line x1="0%" y1="1.5" x2="50%" y2="1.5" stroke="currentColor" stroke-width="3"/>
        </svg> -->
        <connectionLines class="element-background" v-if="hasConnectionLines(meta)" :connection_lines="meta.connection_lines"></connectionLines>
    </div>
        
</template>

<script>
import connectionLines from './sub_elements/svgConnectionLines.vue'
import treeComponentMixin from '@/mixins/treeComponentMixin.js'
export default {
    name: 'display_badge',
    mixins: [ treeComponentMixin ],
    props:{
        meta:{},
        row_position:{},
    },
    components:{
        connectionLines
    },
    data:() => ({
        rag_threshholds:{
            // TODO - set up colour change if isRagEnabled == true
            r: 'quarter_low',
            a: 'quarter_medium',
            g: 'quarter_high'
        },
    }),
    computed:{
        priority_colours(){
            switch(this.meta.value){
                case 'low':{
                    return 'text-capitalize quarter_low';
                }
                case 'medium':{
                    return 'text-capitalize quarter_medium';
                }
                case 'high':{
                    return 'text-capitalize quarter_high';
                }
                default:{
                    return '';
                }
            }
        },
        html_string(){
            return this.getValue(this.meta);
        },
        isRoundBadge(){
            return this.meta && this.meta.round_badge;
        },
        //* get display value
        // getValue(){
        //     if(this.meta && this.meta.value){
        //         return this.meta.value;
        //     }
        //     return '';
        // },

        //* get colour from meta.colour
        getColour(){
            let el = this
            if(this.meta.priority_states){
                return this.priority_colours;
            }
            if(this.isRagEnabled == true && this.meta.config) {
                    let low = this.meta.config.low
                    let high = this.meta.config.high
                    let value = Number(this.getValue(this.meta))
                    if(Number(value) <= low && Number(value) >= 0) {
                        return el.rag_threshholds.r
                    }   
                    if (Number(value) <= high && Number(value) > low) {
                        return el.rag_threshholds.a
                    }
                    if(Number(value) > high) {
                        return el.rag_threshholds.g
                    }
            } else {
                if(this.isGroupWeight == true) {
                   return 'purple_dark_fill badge_styles_larger'
                } else {
                   return 'blue_dark_secondary_fill'
                }
                
            }
            return '';
        },
        isRagEnabled(){
            return this.meta.rag_enabled
        },
        isGroupWeight() {
            return this.meta.group_weight
        },
        hasNullState(){
            let is_null_or_0 = this.meta.value == null || this.meta.value == 0
            return this.meta.null_state && is_null_or_0;
        }

    },
    methods:{},
    mounted(){
    },
}
</script>

<style>
/* .scored_card_red {
    background-color: #F46A6A
}
.amber {
    background-color: #FFA800;
}
.green {
    background-color: #34C38F;
} */

.badge_styles_larger {
    max-width: 116px !important;
    font-size: 9px !important;
}

/* .badge_styles {
    color: white;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    font-size: 10px;
    display: flex;
    margin: 0 auto;
    min-width: 40px;
    min-height: 20px;
    border-radius: 0.15rem;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    text-wrap: nowrap;
} */
/* .row_height {
    min-height: 50px;
} */
/* .line {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
} */


.round-badge-style{
    margin-top: -4px;
    width: 28px;
    height: 28px;
    border-radius: calc(var(--row-border-radius)*1.6);
    display: flex;
}
.null-badge{
    background: white;
    box-shadow:inset 0px 0px 0px 1px #BFC8D6;
}
.null-badge-span{
    width: 102%;
    height: 1px;
    background: #BFC8D6;
    margin-left: -10px;
    margin-right: -10px;
    transform: rotate(-20deg);
}

</style>