<template>
    <div class="element-wrapper d-flex"  :class="isActive(meta)">
        <span v-if="!useHtml(meta)" class="element-content" :class="noWrapText(meta)" style="">
            {{ getValue(meta) }}
        </span>
        <span v-else class="element-content" v-html="html_string" :class="noWrapText(meta)"></span>
    </div>
</template>

<script>
import treeComponentMixin from '@/mixins/treeComponentMixin.js'
export default {
    name: 'text_label',
    mixins: [ treeComponentMixin ],
    data:() => ({}),
    props:{
        meta:{}
    },
    computed:{
        html_string(){
            return this.getValue(this.meta);
        }
    },
    methods:{
        
    },
    mounted(){},
}
</script>

<style>

</style>