var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('Swiper',{attrs:{"slides-per-view":6,"space-between":0,"loop":false,"pagination":{
        el: '.swiper-pagination',
        clickable: true,
    },"breakpoints":{
      0: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      1336: {
        slidesPerView: 3,
        spaceBetween: 0
      },
      1846: {
        slidesPerView: 4,
        spaceBetween: 0
      },
      2200: {
        slidesPerView: 4,
        spaceBetween: 0
      }
    },"navigation":{
        nextEl: '.manager-next',
        prevEl: '.manager-prev',
    }},on:{"swiper":_vm.onSwiper,"slideChange":_vm.onSlideChange}},_vm._l((_vm.data),function(item){return _c('SwiperSlide',{key:item.id},[_c('EmployeeCard',{attrs:{"item":item}})],1)}),1),_c('div',{staticClass:"carousel__controls"},[_c('font-awesome-icon',{staticClass:"my-button-prev manager-prev",attrs:{"icon":"fa-angle-left"}}),_c('div',{staticClass:"swiper-pagination"}),_c('font-awesome-icon',{staticClass:"my-button-next manager-next",attrs:{"icon":"fa-angle-right"}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }