<template>
    <span>
        <Layout>
            <div>
                <ComponentHeader title="Performance Scorecards" description="The performance scorecard shows your data and results for the selected role and period of your employment. Use your scorecard to keep track of what is required of you within your role. You can make notes on individual items, your manger also has access to your scorecard and may also add notes in preparation for a review.">
                    <template #extra-content>
                        <div class="w-100 h-100" style="flex-direction: column; justify-content: space-between;">
                            <div class="m-2" style="height: 35%;"></div>
                            <div class="m-2 d-flex" style="float: right">
                                <employeeCardFilter />
                            </div>
                        </div>
                    </template>
                    <template #extra-content2>
                        <ScorecardDates />
                    </template>
                </ComponentHeader>
                <b-tabs>
                    <b-tab active title="Employee Scorecards">
                        <ScorecardsEmployeesList
                            @openScorecard="handleOpenEmpolyeeScorecardEvent"
                            :filteredCards="filteredScorecards"
                        />
                    </b-tab>
                    <b-tab title="Templates">
                        <ScorecardTemplatesList
                            @openScorecard="handleOpenScorecardEvent"
                        />
                    </b-tab>
                </b-tabs>
            </div>

            <DetailsViewContainer ref="employee_scorecard" v-if="show_employee_scorecard" @exit="show_employee_scorecard = false;">
                <ScorecardEmployeeView @exit="$refs.employee_scorecard.handleExit()" />
            </DetailsViewContainer> <!--@exit="show_detailed_view = false; debug_payload = null"-->

            <DetailsViewContainer ref="scorecard_template" v-if="show_scorecard_builder" @exit="show_scorecard_builder = false;" >
                <ScorecardBuilderView @exit="$refs.scorecard_template.handleExit()"/>
            </DetailsViewContainer>
        </Layout>
    </span>
</template>

<script>
import Layout from "../../layouts/main.vue";
import DetailsViewContainer from "@/components/widgets/containers/detailsOverlayContainer.vue";
import ComponentHeader from "../../../components/widgets/header/componentHeader.vue";
import ScorecardBuilderView from "../../../components/scorecard/scorecardBuilderView.vue";
import ScorecardEmployeeView from "../../../components/scorecard/scorecardEmployeeView.vue";
import ScorecardTemplatesList from "../../../components/scorecard/pageComponents/scorecardTemplatesList.vue";
import ScorecardsEmployeesList from "../../../components/scorecard/pageComponents/scorecardsEmployeesList.vue";
import ScorecardDates from '../../../components/scorecard/pageComponents/scorecardDates.vue';

//import HeaderCard from "../../../components/widgets/header/headerCard.vue";

import { scorecardMethods, scorecardComputed } from '@/state/helpers';
import { levelComputed, menuMethods, adminComputed, adminMethods, treeComponentComputed, treeComponentMethods, activityComputed } from '@/state/helpers'
//eslint-disable-next-line
import _ from 'lodash'
// import Bread from './breadcrumb.vue'

//* remove these 
import employeeCardFilter from "../../../components/scorecard/pageComponents/employeeCardFilter.vue";

export default {
    name: "scorecard",
    components:{
        DetailsViewContainer,
        ComponentHeader,
        ScorecardEmployeeView,
        ScorecardBuilderView,
        ScorecardTemplatesList,
        ScorecardsEmployeesList,
        employeeCardFilter,
        ScorecardDates,
        // Bread,
        //Table,
        Layout,
    },
    mounted(){
        this.setLoading(false);
        this.origional_array = this.generateItems();
        this.buildObjectivesTree(this.origional_array);
        this.getData(true)
    },
    watch:{
        selected_level() {
            this.getData()
        },
        selected_milestone() {
            this.getData()
        },
        objective_viewing_quarter() {
            this.getData()
        },
        scorecardFilters() {
            this.$nextTick(() => {
                this.runFilter()
            })
        },
        scorecards() {
            this.$nextTick(() => {
                this.runFilter()
            })
        }
    },
    data: () => ({
        show_employee_scorecard: false,
        show_scorecard_builder: false,
        processed_array : [],
        filteredScorecards: [],
        period_type: 'year',
        backend_period: 1,
        triggerFilter: false,
    }),
    methods:{
        ...menuMethods,
        ...adminMethods,
        ...treeComponentMethods,
        ...scorecardMethods,
        getData() {
            if(this.selected_milestone) {
                this.findScorecardPeriodID(this.objective_viewing_quarter)
                setTimeout(() => {
                let params = {
                    level_id: this.selected_level.id ? this.selected_level.id : -1,
                    measurement_year: this.selected_milestone ? this.selected_milestone.year : -1,
                    period_type: this.period_type,
                    period_key: this.backend_period,
                    fetch_children: 0
                }
                this.loadLevelScorecards(params).then(() => {
                    // this.scorecards.forEach(card => {
                    //     if(card.scorecard_current_score == null) {
                    //         card.scorecard_current_score = 0
                    //     }
                    // })
                    //let card = this.scorecards
                    // if(mounted) {
                    //     this.triggerFilter = !this.triggerFilter
                    // } else {
                    //     card = _.orderBy(this.scorecards, ['scorecard_current_score', 'name'], ['desc', 'asc']);
                    // }
                    //let card1 = _.orderBy(card, ['scorecard_current_score'], ['desc']);
                    // card = _.orderBy(this.scorecards, ['scorecard_current_score', 'name'], ['desc', 'asc']);
                    //this.filteredScorecards = card
                })
                }, 500)
            }  
        },
        findScorecardPeriodID(quarter){
            console.log(quarter)
            if(quarter <= 4){
                this.period_type = 'quarter';
                this.backend_period = quarter;
            }
            else if(quarter <= 6){
                this.period_type = 'half_year';
                //* map half_year (5 - 6) to values 1 and 2
                this.backend_period = quarter == 5 ? 1 : 2;
            }
            else{
                this.backend_period = 1 
                this.period_type = 'year';
            }
        },
        handleOpenScorecardEvent(){
            this.show_scorecard_builder = true;
        },

        handleOpenEmpolyeeScorecardEvent(){
            this.show_employee_scorecard = true;
        },
        runFilter() {
            if(this.scorecardFilters) {
                let values = _.cloneDeep(this.scorecardFilters)
                this.$nextTick(() => {
                    let cards = this.scorecards
                        values.forEach(value => {
                            //cards = _.cloneDeep(this.scorecards)
                            switch (value.id) {
                                case "in_progress":
                                    if(!value.value) {
                                        console.log('filtered in progress')
                                        cards = cards.filter(item =>  item.scorecard_started == false && item.scorecard_in_progress == true)
                                    }
                                break;
                                case "reviewed":
                                    if(!value.value) {
                                        console.log('filtered reviewed')
                                        cards = cards.filter(item => item.scorecard_started == false && item.scorecard_in_progress == true)
                                    }
                                break;
                                case "not_started":
                                    if(!value.value) {
                                        console.log('not started')
                                        cards = cards.filter(item => item.scorecard_started != false && item.scorecard_in_progress != true)
                                    }
                                break;
                                default:
                                break;
                            }
                    })

                    if(values[0].value && values[1].value) {
                            cards = _.orderBy(cards, [( o ) => { return o.scorecard_current_score || ''}, 'name'], ['desc', 'asc']); 
                            //cards = _.orderBy(cards, ['scorecard_current_score', 'name'], ['desc', 'asc']);
                    } else if(values[0].value && !values[1].value) {
                            cards = _.orderBy(cards, [( o ) => { return o.scorecard_current_score || ''}], ['desc']);
                    } else {
                            cards = _.orderBy(cards, ['name'], ['asc']);
                    }
                    this.filteredScorecards = cards


                })
            }
           

        },
        notif(){
            this.loadNotifications(0);
        },
        async buildObjectivesTree(flat_objectives){
            const map = {};
            const nestedArray = [];
            let pre_processed_array = [];
            return new Promise( (resolve) => {
                if(flat_objectives.length == 0){
                    resolve();
                }       
                //* get unique by id
                pre_processed_array = _.uniqBy(flat_objectives, 'id');
                //* set to correct order
                //pre_processed_array = _.orderBy(pre_processed_array, ['item_order']);
                pre_processed_array = pre_processed_array.map((item) => {
                    let new_item = {
                        ...item,
                        "data": [],
                        "parent" : item.parent_id,
                        "text" : item.name,
                    }
                    map[item.id] = { ...new_item, };
                    return new_item;
                });
                pre_processed_array.forEach((item) => {
                    if (item.parent_id !== null) {
                        if (map[item.parent_id]) {
                            map[item.parent_id].data.push(map[item.id]);
                        }
                        else {
                            nestedArray.push(map[item.id]);
                        }
                    }
                    else {
                        nestedArray.push(map[item.id]);
                    }
                });
                //commit('setObjectivesTree', nestedArray);
                console.log(nestedArray)
                this.processed_array = nestedArray
                resolve();
            })
        },
        generateItems() {
            const items = [];
            const rootItems = [];

            // Helper function to generate a random date string
            function generateRandomDate() {
                const start = new Date(2022, 0, 1); // Start date (January 1, 2022)
                const end = new Date(); // Current date and time
                const randomDate = new Date(
                start.getTime() + Math.random() * (end.getTime() - start.getTime())
                );
                return randomDate.toISOString();
            }

            // Generate root items
            for (let i = 1; i <= 5; i++) {
                const item = {
                id: i,
                parent_id: null,
                name: `Root Item ${i}`,
                created_at: generateRandomDate(),
                };
                items.push(item);
                rootItems.push(i);
            }

            // Generate child items
            for (let i = 6; i <= 150; i++) {
                const parentIndex = Math.floor(Math.random() * rootItems.length);
                const parentID = rootItems[parentIndex];
                const item = {
                id: i,
                parent_id: parentID,
                name: `Child Item ${parentID}.${i - 75}`,
                created_at: generateRandomDate(),
                };
                items.push(item);
            }
            for (let i = 151; i <= 850; i++) {
                const parentIndex = Math.floor(Math.random() * items.length);
                const parentID = items[parentIndex].id;
                const item = {
                id: i,
                parent_id: parentID,
                name: `Child Item ${parentID}.${i - 75}`,
                created_at: generateRandomDate(),
                };
                items.push(item);
            }

            return items;
            }

    },


    computed:{
        ...levelComputed,
        ...adminComputed,
        ...treeComponentComputed,
        ...scorecardComputed,
        ...activityComputed,
        table_data(){
            return _.cloneDeep(this.processed_array);
        },
        columns(){
            
            let el = this;
            let cols = [
                {
                    id: 'id',
                },
                {
                    id: 'name',
                    template: (obj, common, value) => {
                        if(value != ''){
                            return common.treetable(obj, common) + "<span>"+value+"</span>";
                        }
                        else{
                            return '';
                        }
                    },
                },
                {
                    id: 'parent_id', template(obj){
                        if(obj.parent_id == null){
                            return 'N/a'
                        }
                        else{
                            let idx = _.findIndex(el.origional_array, { id: obj.parent_id});
                            if(idx != -1){
                                return el.origional_array[idx].name;
                            }
                            else{
                                return -1;
                            }
                        }
                    }
                },
                {
                    id: 'created_at'
                }
            ];
            return cols;
        }
    },
}

</script>

<style>
.animation-parent-container {
    position: relative;
    width: 100%;
    height: 100px;
    width: 100px; /* Adjust the height as needed */
    overflow: hidden; /* Ensure the rocket does not go beyond the parent's boundaries */
}
.rocket {
    position: absolute;
    bottom: 0;
    left: 0; /* Start at the bottom left corner */
    animation: flyDiagonally 5s linear infinite; /* Customize the animation duration as needed */
}

@keyframes flyDiagonally {
    0% {
        transform: translate(0, -50%); /* Start position at the bottom left */
    }
    100% {
        transform: translate(50%, -50%); /* End position at the top right */
    }
}

.scorecard-sidebar{
    border-right: 1px solid grey;
    width: 100%

}

.b-sidebar-outer > .b-sidebar-backdrop{
    background-color: rgb(20 88 155) !important;
}

.scorecard-sidebar .b-sidebar-body {
    display: flex;
    justify-content: center;
}

.custom-scorecard-class{
    font-size: 0.85rem !important;
    padding: 10px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 0 !important;
    border-bottom: 1px solid rgb(202, 202, 202);
}

/* .custom-scorecard-class > button{
    font-size: 1rem !important;
    border: 0 !important; 
} */
.component-filter-container{
    border: 1px solid grey;
}
.component-filter-container > div{
    padding: 0.25rem;
}

.scorecard_card_container {
    width: 365px;
    border: 1px solid rgb(187, 187, 187);
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 0.55rem;
    border-bottom-left-radius: 0.55rem;
    border-bottom-right-radius: 0.55rem;
}

.scorecard_card_end_margin{
    margin: 0.5rem;
}

</style>
