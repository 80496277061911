<template>
    <Renderer :ref="ref_value" :config="qualifiers_config"/>
</template>

<script>
import { scorecardComputed } from '@/state/helpers';
import Renderer from '../../../widgets/treeTable/renderer.vue';
export default {
    props:{
        ref_value:{
            default: () => {
                return window.webix.uid();
            },
        }
    },
    components:{
        Renderer,
    },
    data:() => ({}),
    computed:{
        ...scorecardComputed,
        qualifiers_children(){
            return this.loaded_template ? this.loaded_template.qualifiers : [];
        },
        qualifiers_config(){
            let cfg = {
                id: 'qualifiers',
                expanded: true,
                dark_header: true,
                child_type: 'row',
                borders:['right'],
                editable: true,
                master_checkbox_variable_id: 'checkbox',
                include_empty_row: true,
                empty_row_structure:{
                    id: -1,
                    name: '',
                    name2: '', 
                },
                columns:[
                    {
                        id: 'name',
                        width: 'fillspace',
                        header: {
                            classes: ['d-flex', 'w-100', 'my-auto' , 'ps-2'],
                            components: [
                                {
                                    component_id: 'checkbox_label',
                                    variable_id: 'checkbox',
                                    meta:{
                                        value: true,
                                    },
                                    element_classes: ['me-2']
                                },
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        no_wrap: true,
                                        value: 'Bonus Qualifiers',
                                    }
                                },
                                {
                                    component_id: 'display_badge',
                                    meta:{
                                        value: ' - '
                                    },
                                    element_classes:['ms-auto', 'me-2']
                                }
                            ]
                            
                        },
                        row:{
                            classes: ['scorecard_row_width', 'ps-1'],
                            components: [
                                {
                                    component_id: 'add_remove_button',
                                    element_classes: ['ms-1','me-2']
                                },
                                {
                                    component_id: 'text_input',
                                    meta:{
                                        placeholder: 'Define this bonus qualifier here (start typing)',
                                    },
                                    variable_id: 'qualifier_text',
                                },
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        no_wrap: true,
                                        use_html: true,
                                        value: `(Manager will answer <b>yes</b> or <b>no</b> here)`,
                                    },
                                    element_classes: ['ms-auto', 'my-auto', 'pe-2', 'me-1','end_row_border']
                                },
                                // {
                                //     component_id: 'yes_no_label',
                                //     variable_id: 'name',
                                //     element_classes: ['me-1','end_row_border']
                                // }
                            ],
                        }
                    },
                ],
                children: this.qualifiers_children,
            }
            return cfg;
        },
    },
    methods:{
        returnComponentData(){
            return this.$refs[this.ref_value].getComponentData();
        }
    },
    mounted(){},
}
</script>

<style>

</style>