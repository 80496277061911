<template>
    <div class="btn-group pt-0 m-3 ms-0" role="group" style="font-size: 0.65rem;">
        <div class="d-flex" style="flex-direction: column;">
            <span>
                Sort By
            </span>
            <span class="d-flex">
                <span v-show="!values[0].value"><span class="" title="" ></span><font-awesome-icon @click="values[0].value = true, changeFilter()" class="fa fa-toggle-off fa-lg fa-2x" :icon="['fa', 'toggle-off']" style="color: #0275d8" /></span>
                <span v-show="values[0].value"><span class="" title=""></span><font-awesome-icon @click="values[0].value = false, changeFilter()" class="fa fa-toggle-on fa-lg fa-2x" :icon="['fa', 'toggle-on']" style="color: #0275d8" /></span>
                <span class="m-1">
                    Final Score
                </span>
            </span>
            <span  class="d-flex">
                <span v-show="!values[1].value"><span class="" title="" ></span><font-awesome-icon @click="values[1].value = true, changeFilter()" class="fa fa-toggle-off fa-lg fa-2x" :icon="['fa', 'toggle-off']" style="color: #0275d8" /></span>
                <span v-show="values[1].value"><span class="" title=""></span><font-awesome-icon @click="values[1].value = false, changeFilter()" class="fa fa-toggle-on fa-lg fa-2x" :icon="['fa', 'toggle-on']" style="color: #0275d8" /></span>
                <span class="m-1">
                    A - Z
                </span>
            </span>
          
          
            
        </div>
        <div class="d-flex ms-1" style="flex-direction: column;">
            <span>
                Status
            </span> 
            <!-- <span class="d-flex">
                <input
                    class="form-check-input filter-checkbox-scorecard"
                    style="cursor:pointer;"
                    v-model="values[2].value"
                    @change="changeFilter()"
                    type="checkbox"
                />
                <span class="m-1">Overdue</span>
            </span> -->
            <span class="d-flex">
                <input
                    class="form-check-input filter-checkbox-scorecard"
                    style="cursor:pointer;"
                    v-model="values[2].value"
                    @change="changeFilter()"
                    type="checkbox"
                />
                <span class="m-1">In Progress</span>
            </span>
            <span class="d-flex">
                <input
                    class="form-check-input filter-checkbox-scorecard"
                    style="cursor:pointer;"
                    v-model="values[3].value"
                    @change="changeFilter()"
                    type="checkbox"
                />
                <span class="m-1">Reviewed</span>
            </span>
            <span class="d-flex">
                <input
                    class="form-check-input filter-checkbox-scorecard"
                    style="cursor:pointer;"
                    v-model="values[4].value"
                    @change="changeFilter()"
                    type="checkbox"
                />
                <span class="m-1">Not Started</span>
            </span>
        </div>
    </div>
</template>

<script>
import { activityComputed, scorecardComputed, scorecardMethods } from '@/state/helpers'
import _ from 'lodash'
export default {
name: "employeeCardFilter",
components:{
},
watch: {
    scorecards() {
        console.log("RUNNING HERE")
        this.$emit('changedFilter', this.values)
    },
},
props: {
},
data: () => ({
    values: [
        { id: 'final_score_sort', value: true },
        { id: 'alphabetical_sort', value: true },
        { id: 'in_progress', value: true },
        { id: 'reviewed', value: true },
        { id: 'not_started', value: true },
     ]
}),
computed:{
    ...activityComputed,
    ...scorecardComputed,
},
methods: {
    ...scorecardMethods,
    changeFilter() {
        let new_values = _.cloneDeep(this.values)
        this.$nextTick(() => {
           this.setScorecardFilterValues(new_values)
        })
        //this.$emit('changedFilter', this.values)
    }
},
mounted(){
    //this.values = this.data
    let new_values = _.cloneDeep(this.values)
    this.$nextTick(() => {
        this.setScorecardFilterValues(new_values)
    })
   
},
}

</script>

<style>
.filter-checkbox-scorecard {
    font-size: 18px !important;
}
</style>
