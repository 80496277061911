<template>
    <!-- <b-button @click="setComponentViewState(null)" size="lg" style="font-size: 55px; background-color: white">></b-button> -->
    <div class="w-100 d-flex flex-column" style="max-width: 1920px; justify-content: center;">
        <ComponentHeader back-button @exit="$emit('exit')" title="Objectives" useHtml :description="getDescription">
            <template #extra-content>
                <ScorecardDateSelector :viewType="viewType" />
            </template>
        </ComponentHeader>
        <span class="m-4 p-3"
            style="justify-content: center; background-color: #FFFFFF !important; overflow: auto !important; min-height: 80vh !important; overflow-x: hidden !important; border-top-left-radius: 3rem;">
            <span class="w-100 sizing_change" style="display: inline-flex; justify-content: center;">
                <b-col class="btn-group m-2 p-2 ms-3 d-flex" style="flex-direction: column;">
                    <span class="mt-2">
                        <b-dropdown id="objectivesItem" class="page_select_styling" no-caret variant="outline"
                            style="border: 1px solid grey; border-radius: 0.4rem;" size="lg" v-model="selected_item"
                            @change="changeItem" :text="getName">
                            <b-dropdown-item v-for="item in options" @click="changeItem(item.value)" :key="item.value"
                                style="width:100%">{{ item.text }}</b-dropdown-item>
                            <template #button-content>
                                <div class=""
                                    style="display:inline-flex;justify-content: space-between; width: 98%; align-items: center;">
                                    <div class="">
                                        {{ getName }}
                                    </div>
                                    <div class="" style="width: 35px; margin-top: 3px !important;">
                                        <!-- <i class="arrow-dropdown down"></i> -->
                                        <font-awesome-icon :icon="['fas', 'angle-down']"
                                            style="height: 16px !important; width: 16px !important; color: #50A5F1" />
                                    </div>
                                </div>
                            </template>
                        </b-dropdown>
                    </span>
                    <span class="d-flex w-100 mt-3">
                        <span class="d-flex col-3 m-3 ms-0" style="flex-direction: column;">
                            <span style="font-weight: bold">Review Submission Status (YR)</span>
                            <span class='red'>No Review Submitted</span>
                        </span>

                    </span>
                </b-col>
                <b-col class="d-flex" style="flex-direction: column;">
                    <editableBadgeCollection :viewType="viewType" />
                    <!-- <badge_collection />  -->
                </b-col>
            </span>

            <div>
                <b-tabs class='h-100 m-3'>
                    <b-tab class="" title="Sub Objectives" @click="selectedItem = ''" lazy>
                        <!-- <div class="w-100" style="display: inline-flex; align-items: center; justify-content: end;">
                            <span class="purple_dark">
                            (View KPI graphs at full size)
                            </span>
                            <span>
                                <toggle_label :meta="{toggle_pair: {first: 'collapse', last: 'expand'}}" />
                            </span>
                        </div> -->

                        <span style="height: 500px">
                            <scorecardSubObjectives :viewType="viewType" />
                        </span>
                    </b-tab>
                    <b-tab class="" title="Key Results" @click="selectedItem = ''" lazy>
                        <!-- <div class="w-100" style="display: inline-flex; align-items: center; justify-content: end;">
                            <span class="purple_dark">
                            (View KPI graphs at full size)
                            </span>
                            <span>
                                <toggle_label :meta="{toggle_pair: {first: 'collapse', last: 'expand'}}" />
                            </span>
                        </div> -->

                        <span style="height: 500px">
                            <scorecardKeyResults :viewType="viewType" />
                        </span>
                    </b-tab>
                    <b-tab class="" title="Assessment Notes" @click="selectedItem = ''" lazy>
                        <ReviewNotes disable-scores></ReviewNotes>
                    </b-tab>
                    <b-tab class="" title="Documents" @click="selectedItem = ''" lazy>
                        <scorecardDocuments :viewType="viewType" />
                    </b-tab>
                </b-tabs>
            </div>
        </span>
    </div>
</template>

<script>
import ComponentHeader from "@/components/widgets/header/componentHeader.vue";
import { treeComponentComputed, treeComponentMethods, scorecardComputed, scorecardMethods, activityComputed } from '@/state/helpers'
import ScorecardDateSelector from "../scorecardDateSelector.vue";
import editableBadgeCollection from "../details_badge_collection.vue";
import scorecardDocuments from '../scorecardDocuments.vue'
import scorecardKeyResults from "../scorecardKeyResults.vue";
import scorecardSubObjectives from "../scorecardSubObjectives.vue";
import ReviewNotes from './reviewNotes.vue'
import _ from 'lodash'
export default {
    name: "objectives",
    components: {
        ScorecardDateSelector,
        ComponentHeader,
        editableBadgeCollection,
        scorecardDocuments,
        scorecardKeyResults,
        scorecardSubObjectives,
        ReviewNotes,
    },
    props: {
        viewType: {
            required: true
        }
    },
    mounted() {
        if (this.details_page_data) {
            this.selected_item = this.details_page_data.id
            this.setOptions()
        }
    },
    data: () => ({
        selected_item: null,
        options: []
    }),
    methods: {
        ...treeComponentMethods,
        ...scorecardMethods,
        setOptions() {
            let loadedScoreCard = _.cloneDeep(this.loaded_user_scorecard)
            let sectionData = []
            loadedScoreCard.sections.forEach(section => {
                if (section.type_name == 'objectives') {
                    section.objectives.forEach(item => {
                        let modifiedItem = {}
                        modifiedItem.value = item.id
                        modifiedItem.text = item.name
                        sectionData.push(modifiedItem)

                    })
                }
            })
            if (sectionData) {
                this.options = sectionData
            }
        },
        changeItem(item) {
            let params = { objective_id: item, scorecard_period_id: this.loaded_user_scorecard.scorecard_period_id };
            this.loadObjectiveDetails(params).then(() => {
                this.show_detailed_view = true;
                this.selected_item = this.details_page_data.id
            })
        }
        // setFilter(value) {
        //     this.selected_filter = value
        // },
        // setYearFilter(value) {
        //     this.selected_year_filter = value
        // }
    },

    computed: {
        ...treeComponentComputed,
        ...scorecardComputed,
        ...activityComputed,
        getDescription() {
            let description = "You are viewing the an item in the objectives Category of your Performance Scorecard in a Yearly Review.";
            return description
        },
        getName() {
            let value = ''
            this.options.forEach(module => {
                if (module.value == this.selected_item) {
                    value = module.text
                }
            })
            return value
        }
    },
}

</script>

<style>
.page_select_styling {
    width: 666px !important;
    height: 50px !important
}

.custom_border {
    border: 1px solid #BFC8D6;
    box-shadow: 0 0 2px 2px #8881B3;
}

.page_container_styles {
    /* width: 1530px !important; */
    width: 90%;
    /* height: 100% !important; */
    padding: 0 auto
}

#objectivesItem .dropdown-menu {
    width: 100% !important;
}

/* .arrow_styles {
    width: 30px;
    height: 30px;
    border: 10px solid #CB6CC3;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
} */
/* .details-cards {
    background-color: #F4F4F4;
} */
@media (max-width: 1561px) {
    .sizing_change {
        flex-direction: column;
    }
}
</style>
