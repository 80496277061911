
  <template>
      <div class="d-flex flex-wrap justify-content-center item" :class="getDisabledState">
        <b-overlay :show="loading && selected_scorecard_user_id == item.id">
              <div class="scorecard_card_container d-flex flex-column" style="background-color: white;">
                <div class="p-1 m-1">
                  <div class="d-flex">
                      <div class="avatar-sm me-3">
                          <span class="avatar-title bg-secondary bg-soft rounded-circle font-size-16">
                              <b-img
                              style="width: 48px; height: 48px; padding: 2px;"
                                  fluid
                                  :src="item.avatar_src"
                                  alt
                                  onerror="src='/img/user.png'"
                                  class=" smaller-image-height avatar-md rounded-circle header-profile-user-view"
                                  id="image"
                                  >
                              </b-img>
                          </span> 
                      </div>
                      <div class="mt-2 pt-1" style="font-size: 0.7rem;">
                          {{ item.name }}
                      </div>
                      <!-- <font-awesome-icon class="ms-auto" :icon="['fas', 'angle-right']" @click="$emit('handleSelect', item.pivot.user_id, item.jobs[0].job_id)" style="height: 20px !important; width: 20px !important; margin-top: 5px; color: #CB6CC3" /> -->
                      <!-- <b-dropdown text="Role">
                        <b-dropdown-item @click="$emit('handleSelect', item.id, job.id)" v-for="job in item.jobs" :key="job.id">
                            {{ job.name }}
                        </b-dropdown-item>
                    </b-dropdown> -->
                    <b-dropdown id="cardHeaderItem" class="ms-2" no-caret variant="outline" style="border: 1px solid grey; min-width: 100px; height: 30px; border-radius: 0.4rem; margin-top: 7px" size="sm" text="Select Role">
                            <b-dropdown-item
                            v-for="job in item.jobs"
                            @click="$emit('handleSelect', item.id, job.job_id)"
                            :key="job.id"
                            style="width:100%">
                            {{ job.title }}
                            </b-dropdown-item>
                            <template #button-content>
                                <div class="" style="display:inline-flex;justify-content: space-between; width: 98%; align-items: center;">
                                    <div class="">
                                        Select Role
                                    </div>
                                    <div class="" style="width: 10px; margin-top: 3px !important;">
                                        <!-- <i class="arrow-dropdown down"></i> -->
                                        <font-awesome-icon :icon="['fas', 'angle-down']" style="height: 16px !important; width: 16px !important; color: #50A5F1" />
                                    </div>
                                </div>
                            </template>
                    </b-dropdown>
              
                      <!-- <div @click="handleScorecardClick(item.id)" style="width: 30px; height: 30px; margin-right: -10px; margin-top: -10px;" class="ms-auto bg-secondary">
                      </div> -->
                  </div>
                  <div class="d-flex ms-1" style="flex-direction: row;">
                        <!-- <div class="col-4">
                            <span>Your Reviews</span>
                            <span class="d-flex">
                                <b-progress :variant="getColor(25)" class="mt-2 w-50" :max="100" style="border: 1px solid grey">
                                    <b-progress-bar :value="25"></b-progress-bar>
                                </b-progress>
                                <span class="m-1 ms-2">
                                    %25
                                </span>
                            </span>
                            <span>Manager Review</span>
                            <span class="d-flex">
                                <b-progress :variant="getColor(75)"  class="mt-2 w-50" :max="100" style="border: 1px solid grey">
                                    <b-progress-bar :value="75"></b-progress-bar>
                                </b-progress>
                                <span class="m-1 ms-2">
                                    %75
                                </span>
                            </span>
                            <span>Final Review</span>
                            <span class="d-flex">
                                <b-progress :variant="getColor(11)" class="mt-2 w-50" :max="100" style="border: 1px solid grey">
                                    <b-progress-bar :value="11"></b-progress-bar>
                                </b-progress>
                                <span class="m-1 ms-2">
                                    %11
                                </span>
                            </span>
                        </div> -->
                        <div class="col-12 mt-1">
                            <div class="col-12 d-flex w-100" style="flex-direction: column; font-size: 0.65rem;" >
                                <!-- <span class="" style="margin: 1px">Job : <span>{{ item.jobs[0].title }}</span></span> -->
                                <span class="" style="margin: 1px">Status : <span v-if="item.scorecard_in_progress && item.scorecard_started">In Progress</span>
                                <span v-if="!item.scorecard_in_progress && item.scorecard_started">Reviewed</span>
                                <span v-if="!item.scorecard_started">Not Started</span></span>
                                <!-- <span class="" style="margin: 1px">Deadline : </span> -->
                                <span class="" style="margin: 1px">Division : {{ getDivision(item.pivot) }} </span>
                                <span class="" style="margin: 1px">Reporting to : {{ item.reports_to_user_name }} <span style="color: #CB6CC3"></span></span>
                                <span class="" style="margin: 1px">Bonus Status : <span v-if="item.scorecard_bonus_achieved">Achieved</span><span v-if="!item.scorecard_bonus_achieved">Qualifiers not achieved</span></span>
                            </div>
                        </div>
                        <div class="col-6 ">
                            <div class="col-3 d-flex w-100" style="flex-direction: column;">
                                
                            </div>
                        </div>
                      </div>
                    </div>
                        <div v-if="Number(item.scorecard_current_score) > 0" :class='[getColor(item.scorecard_current_score), "header_card_container_footer", "d-flex", "mt-1", "pt-1"]' style="height: 34px !important; background-color: grey">
                        <!-- <span v-if="!item.scorecard_in_progress" class="ms-auto my-auto text-white">
                            Current Score
                        </span> -->
                        <span class="ms-auto my-auto text-white">
                            Final Score
                        </span>
                        <span class="ms-2 my-auto text-white font-size-15">{{ item.scorecard_current_score }}</span>
                       </div>
                       <div class="mt-1 pt-1 d-flex header_card_container_footer" v-if="item.scorecard_current_score == 0 || item.scorecard_current_score == null" style="height: 36px; color: black; border: 0px !important">
                        <span class="ms-auto my-auto me-2">
                            Final Score -
                        </span>
                              
                       </div>
              </div>
          </b-overlay>
      </div>
</template>

<script>
import treeComponentMixin from '@/mixins/treeComponentMixin.js'
import { levelComputed, activityComputed, scorecardComputed, scorecardMethods } from '@/state/helpers';
import _ from 'lodash'
export default {
    name: 'employeeCard',
    mixins: [ treeComponentMixin ],
    props: {
        item: {
            required: true
        },
        loading: {
            default: false
        }
    },
    data:() => ({    
    }),
    computed:{
      ...levelComputed,
      ...activityComputed,
      ...scorecardComputed,
      getDisabledState(){
          if(!this.hasYearSelected || !this.hasValidPeriodSelected){
              return 'bg-secondary bg-soft disabled-cursor';
          }
          return '';
      },
      hasYearSelected(){
          return this.selected_milestone != null;
      },
      hasValidPeriodSelected(){
          return this.objective_viewing_quarter != 0;
      },
    },
    methods:{
      ...scorecardMethods,
      getDivision(item) {
        let level = _.find(this.levels, {
            id: item.level_id,
        });
        return level.name
      },
      getColor(item) {
        if (item > 6) {
        return 'quarter_high'
        } else if (item > 3) {
        return 'quarter_medium'
        } else if (item > 0) {
        return 'quarter_low'
        }
        return ''
      },
    //   getReportsTo(item) {
    //     let id = item.report_to_user_id
    //     let user_object = this.all_users.find((user) => {
    //         return user.id == id;
    //     });
    //     return user_object.name
    //   },
    },
    created() {
    },
    beforeDestroy() {
    },
}
</script>


<style>
.item {
    display: inline-flex;
    margin: 5px;
    /* height: 233px; */
    /* width: 340px; */
    /* border: 1px solid black; */
    border-radius: 0.2rem;
}

</style>