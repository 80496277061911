<template>
    <div class="d-flex m-1 purple_dark" style="align-items: center;" >
        <span>
            ( {{ getValue(meta) }} Items)
        </span>
    </div>
</template>

<script>
import treeComponentMixin from '@/mixins/treeComponentMixin.js'
export default {
    name: 'item_count',
    mixins: [ treeComponentMixin ],
    data:() => ({}),
    props:{
        meta:{}
    },
    computed:{
    },
    methods:{},
    mounted(){},
}
</script>

<style>

</style>