<template>
  <div>
    <div class="m-1">
      <div class="mt-2">Showing <b>All Documents</b> uploaded on your <b>Performance Scorecard</b> across all <b>items,
          sections and categories.</b><br>
        You cannot remove documents that <b>were added by your Manager or </b></div>
      <div class="d-flex w-100" style="justify-content: end;">
        <b-button size="sm" @click="deleteCard" variant="danger" v-if="selectedId != -1" right
          class="me-1 dropdown-menu-end">
          <strong>Delete Selected Card</strong>
        </b-button>
        <!-- <b-button @click="addDoc" size="sm" variant="outline" right class="me-1 dropdown-menu-end">
            <strong>Add Document +</strong>
        </b-button> -->
        <b-button @click="uploadFile" size="sm" variant="outline" style="color: #50A5F1" right
          class="me-1 dropdown-menu-end">
          add Document to Scorecard +
        </b-button>
        <b-form-file variant="outline" id="uploadFile" style="display: none" v-model="document"
          accept=".jpg, .png, .pdf" class="mt-3" browse-text="Add Document +" v-on:change="filesChange()"></b-form-file>
      </div>
      <div>
        <span class="">Uploaded by You</span>
        <b-alert class="m-2" variant="primary" :show="userData.length == 0">
          No Documents for User
        </b-alert>
      </div>
      <Swiper id="userSwiper" v-if="userData.length > 0" :slides-per-view="4" :space-between="20"
        :allowTouchMove="false" :draggable="false" :loop="false" :pagination="{
          el: '.swiper-pagination2',
          clickable: true,
        }" :breakpoints="{
        0: {
          slidesPerView: 1,
          spaceBetween: 0
        },
        1090: {
          slidesPerView: 2,
          spaceBetween: 0
        },
        1440: {
          slidesPerView: 3,
          spaceBetween: 0
        },
        1780: {
          slidesPerView: 4,
          spaceBetween: 0
        },
        2200: {
          slidesPerView: 5,
          spaceBetween: 0
        }
      }" :navigation="{
        nextEl: '.user-next',
        prevEl: '.user-prev',
      }" @swiper="onSwiper" @slideChange="onSlideChange">
        <SwiperSlide v-for="item in userData" :key="item.id" class="test" :class="{ test_2: true }">
          <b-card @click="selectCard(item.id)"
            :class="selectedId == item.id ? 'selected_doc_card m-2 p-2 document_card_styles' : 'm-2 p-2 document_card_styles'">
            <div class="d-flex" style="justify-content: space-between;">
              <span class="d-flex" style="flex-direction: column;">
                <span class="" style="margin: 2px">
                  {{ item.document_name }}
                </span>
                <span class="" style="margin: 2px">
                  {{ getDate(item.created_at) }}
                </span>
                <span class="" style="margin: 2px">
                  {{ getUser(item.upload_user_id) }}
                </span>
              </span>
            </div>
          </b-card>
        </SwiperSlide>
      </Swiper>
      <div class="carousel__controls" v-if="userData.length > 0">
        <font-awesome-icon class="my-button-prev user-prev" icon="fa-angle-left"></font-awesome-icon>
        <div class="swiper-pagination2"> </div>
        <font-awesome-icon class="my-button-next user-next" icon="fa-angle-right"></font-awesome-icon>
      </div>
    </div>
    <div>
      <div class="m-1">
        <span>Uploaded by Manager</span>
        <b-alert class="m-2" variant="primary" :show="managerData.length == 0">
          No Documents for Manager
        </b-alert>
      </div>
      <Swiper v-if="managerData.length > 0 && !loading" :slides-per-view="4" :space-between="20" :loop="false"
        :pagination="{
          el: '.swiper-pagination1',
          clickable: true,
        }" :breakpoints="{
          0: {
            slidesPerView: 1,
            spaceBetween: 0
          },
          1090: {
            slidesPerView: 2,
            spaceBetween: 0
          },
          1440: {
            slidesPerView: 3,
            spaceBetween: 0
          },
          1780: {
            slidesPerView: 4,
            spaceBetween: 0
          },
          2200: {
            slidesPerView: 5,
            spaceBetween: 0
          }
        }" :navigation="{
        nextEl: '.manager-next',
        prevEl: '.manager-prev',
      }" @swiper="onSwiper" @slideChange="onSlideChange">
        <SwiperSlide v-for="item in managerData" :key="item.id" class="test" :class="{ test_2: true }">
          <b-card @click="selectCard(item.id)"
            :class="selectedId == item.id ? 'selected_doc_card m-2 p-2 document_card_styles' : 'm-2 p-2 document_card_styles'">
            <div class="d-flex" style="justify-content: space-between;">
              <span class="d-flex" style="flex-direction: column;">
                <span class="" style="margin: 2px">
                  {{ item.document_name }}
                </span>
                <span class="" style="margin: 2px">
                  {{ getDate(item.created_at) }}
                </span>
                <span class="" style="margin: 2px">
                  {{ getUser(item.upload_user_id) }}
                </span>
              </span>
            </div>
          </b-card>
        </SwiperSlide>
      </Swiper>
      <div class="carousel__controls" v-if="managerData.length > 5">
        <font-awesome-icon class="my-button-prev manager-prev" icon="fa-angle-left"></font-awesome-icon>
        <div class="swiper-pagination1"> </div>
        <font-awesome-icon class="my-button-next manager-next" icon="fa-angle-right"></font-awesome-icon>
      </div>
    </div>
    <!-- <scorecardDocumentModal v-if="show_document_modal" :selectedId="selectedId" :body_content="body" /> -->
    <ScorecardDocDetails v-if="show_document_modal" :data="modal_payload" :type="viewType" />
  </div>

</template>

<script>
import { Navigation, Pagination } from 'swiper'
//import scorecardDocumentModal from './scorecardDocumentModal.vue'
import { scorecardComputed, scorecardMethods, activityComputed, activityMethods, levelComputed } from "@/state/helpers";
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import ScorecardDocDetails from './scorecardDocDetails.vue';
import { format, parseISO } from 'date-fns'

// Import Swiper styles
import 'swiper/swiper-bundle.css'


SwiperCore.use([Navigation, Pagination])

export default {
  props: {
    viewType: {
      required: true
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    ScorecardDocDetails,
  },
  computed: {
    ...scorecardComputed,
    ...activityComputed,
    ...levelComputed
  },
  watch: {
    details_page_data() {
      this.loading = true
      this.$nextTick(() => {
        this.userData = this.details_page_data.employee_docs
        this.managerData = this.details_page_data.manager_docs
        this.loading = false
      })
    }
  },
  mounted() {
    this.userData = this.details_page_data.employee_docs
    this.managerData = this.details_page_data.manager_docs
  },
  data: () => ({
    selectedItem: -1,
    show_document_modal: false,
    total: [],
    managerData: [],
    userData: [],
    document: null,
    selectedId: -1,
    body: '',
    is_dragingg: false,
    loading: false,
    modal_payload: null
  }),
  methods: {
    ...scorecardMethods,
    ...activityMethods,
    getUser(id) {
      let user_object = this.all_users.find((user) => {
        return user.id == id;
      });
      return user_object.name;
    },
    getDate(date) {
      //let dateValue = new Date(date)
      return format(parseISO(date), 'dd/MMM/yyyy')
    },
    refresh() {
      let params = {}
      switch (this.viewType) {
        case 'productivity': {
          params = { target_id: this.details_page_data.id, scorecard_period_id: this.objective_viewing_quarter };
          this.loadProductivityDetails(params).then(() => {
            this.userData = this.details_page_data.employee_docs
            this.managerData = this.details_page_data.manager_docs
          })
          break;
        }
        case 'values': {
          params = { value_id: this.details_page_data.id, scorecard_period_id: this.objective_viewing_quarter };
          this.loadValuesDetails(params).then(() => {
            this.userData = this.details_page_data.employee_docs
            this.managerData = this.details_page_data.manager_docs
          })
          break;
        }
        case 'objectives': {
          params = { objective_id: this.details_page_data.id, scorecard_period_id: this.objective_viewing_quarter };
          this.loadObjectiveDetails(params).then(() => {
            this.userData = this.details_page_data.employee_docs
            this.managerData = this.details_page_data.manager_docs
          })
          break;
        }
      }
    },
    uploadFile() {
      document.getElementById('uploadFile').click()
    },
    onSwiper(swiper) {
      console.log(swiper)
    },
    onSlideChange() {
      console.log('slide change')
    },
    selectCard(id) {
      if (id == this.selectedId) {
        this.selectedId = -1
      } else {
        this.selectedId = id
      }

    },
    deleteCard() {
      window.axios.post('api/scorecards/delete_document', { document_id: this.selectedId }, {
      }).then(() => {
        this.refresh()
      })

    },
    filesChange() {
      setTimeout(() => {
        const form = new FormData();
        //let user_id = this.selected_user_id != null ? this.selected_user_id : this.$route.params.user_id
        console.log(this.document)
        form.append('document', this.document)
        form.append('item_id', this.details_page_data.id)
        form.append('scorecard_period_id', 7)
        form.append('item_type', 'productivity')

        window.axios.post('api/scorecards/upload_document', form, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }, 100).then(() => {
          this.refresh()
        })
      })

    },
    showDoc(item) {
      this.modal_payload = item
      setTimeout(() => {
        this.show_document_modal = true
        this.$bvModal.show('scorecardDocDetails');
        this.$root.$once("bv::modal::hidden", (event) => {
          if (event.type == "hidden" && event.componentId == "scorecardDocDetails") {
            //this.setSelectedDocumentId({ type: null });
            this.modal_payload = null
            this.show_document_modal = false
          }
        });
      }, 500)
    }
  }
}
</script>

<style lang="scss">
.img-fluid {
  max-width: 100%;
  height: auto;
}

.w-100 {
  width: 100%;
}

.ml-auto,
.mx-auto {
  margin-left: auto;
}

.mr-auto,
.mx-auto {
  margin-right: auto;
}

.document_card_styles {
  border: 1px solid #BFC8D6 !important;
  border-radius: 0.2rem;
  max-height: 100px
}

.selected_doc_card {
  border: 2px solid #50A5F1 !important;
}

.carousel {
  &__controls {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-top: 1px;
  }
}

// .my-button-prev,
// .my-button-next {
//     height: 26px !important;
//     width: 34px !important;
// }

.swiper-pagination {
  width: unset;
  position: unset;
  display: flex;
}

#userSwiper {
  max-height: 116px;
}

.swiper-pagination-bullet {
  margin: 4px !important;
}
</style>