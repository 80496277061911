<template>
    <div class="d-flex m-1" style="justify-content: center; flex-direction: column;">
        <!-- Debug scores -->
        <!-- <pre>{{ details_page_data.scores }}</pre>
        <pre>{{ base_scores }}</pre> -->
        
        <!-- HEADER ROW -->
        <div class="details_badge_collection_styling" style="height: 18px !important">
            <span class="badge_cell column_heading_styles">Q1</span>
            <span class="badge_cell column_heading_styles">Q2</span>
            <span class="badge_cell column_heading_styles">Q3</span>
            <span class="badge_cell column_heading_styles">Q4</span>
            <span v-if="viewType == 'productivity'" class="badge_cell ms-2">CUMULATIVE/TARGET</span>
            <span v-if="viewType == 'values'" class="badge_cell ms-2">AVERAGES</span>
            <span v-if="viewType == 'objectives'" class="badge_cell ms-2"></span>
        </div>

       <!-- PRODUCTIVITY ROW -->
        <div v-if="!loading && viewType == 'productivity'" class="details_badge_collection_styling">
            
                <div v-for="(quarter, index) in base_scores"  :key="index">
                    <span type="text" class="input-class"
                    :id="index"
                    :placeholder="quarter.target" 
                    :class="['badge_styles', 'badge_cell', 'data_badge_cell']">
                    {{ quarter.target }}
                    </span>
                </div>
                <span :class="['badge_styles', 'badge_cell', 'ms-2', 'data_badge_cell']" style="color: black;">{{ calculateAverage('target', false) }}</span>      
        </div>
        <div class="details_badge_collection_styling" style="height: 15px !important" v-if="viewType == 'productivity'">
            <span class="badge_cell column_heading_styles"></span>
            <span class="badge_cell column_heading_styles"></span>
            <span class="badge_cell column_heading_styles"></span>
            <span class="badge_cell column_heading_styles"></span>
            <span class="badge_cell ms-2" style="height: 15px !important">CUMULATIVE/ACHIEVED</span>
        </div>
        <div v-if="!loading && viewType == 'productivity'"  class="details_badge_collection_styling">
            <div v-for="(quarter, index) in base_scores"  :key="index">
                <span type="text" class="input-class"
                :id="index"
                :placeholder="quarter.achieved" 
                :class="['badge_styles', 'badge_cell', 'data_badge_cell']">
                {{ quarter.achieved }}
                </span>
            </div>
            <!-- <div class="d-flex" style="flex-direction: column;"> -->
                <!-- <span class="editable_badge_style_collection ms-2">CUMULATIVE/TARGET</span> -->
                <span :class="['badge_styles', 'badge_cell', 'ms-2', 'data_badge_cell']" style="color: black;">{{ calculateAverage('achieved', false) }}</span>   
            <!-- </div> --> 
        </div>
        <!-- PERCENTAGE ROW FOR PRODUCTIVITY AND OBJECTIVES -->
        <div class="details_badge_collection_styling" style="height: 15px !important" v-if="viewType != 'values'">
            <span class="badge_cell column_heading_styles"></span>
            <span class="badge_cell column_heading_styles"></span>
            <span class="badge_cell column_heading_styles"></span>
            <span class="badge_cell column_heading_styles"></span>
            <span  v-if="viewType != 'objectives'" class="badge_cell ms-2" style="height: 15px !important">Final score %</span>
            <span v-if="viewType == 'objectives'" class="badge_cell ms-2" style="height: 15px !important">Percentage Complete</span>
        </div>
        <div v-if="!loading && viewType != 'values'" class="details_badge_collection_styling">
            
            <div v-for="(quarter, index) in base_scores"  :key="index">
                <span type="text" class="input-class"
                :id="index"
                :placeholder="calculatedScorePercentage(quarter.score)" 
                :class="[getColour(quarter.score),'badge_styles', 'badge_cell', 'data_badge_cell']">
                {{ calculatedScorePercentage(quarter.score) }}%
                </span>
            </div>
            <!-- <div class="d-flex" style="flex-direction: column;"> -->
                <!-- <span class="editable_badge_style_collection ms-2">CUMULATIVE/TARGET</span> -->
                <span :class="['badge_styles', 'badge_cell', 'ms-2', 'data_badge_cell']" style="color: black;">{{ calculateAverage('score', true) }}%</span>   
            <!-- </div> --> 
        </div>

        <!-- VALUES ROWS -->
        <div class="details_badge_collection_styling" style="height: 15px !important" v-if="viewType == 'values'">
            <span class="badge_cell column_heading_styles"></span>
            <span class="badge_cell column_heading_styles"></span>
            <span class="badge_cell column_heading_styles"></span>
            <span class="badge_cell column_heading_styles"></span>
            <span class="badge_cell ms-2" style="height: 15px !important">Employee Rating</span>
        </div>
        <div v-if="!loading && viewType == 'values'" class="details_badge_collection_styling">
            
            <div v-for="(quarter, index) in value_scores"  :key="index">
                <span type="text" class="input-class"
                :id="index"
                :placeholder="quarter.employee" 
                :class="[getColour(quarter.employee, 'employee'),'badge_styles', 'badge_cell', 'data_badge_cell']">
                {{ quarter.employee }}
                </span>
            </div>
            <!-- <div class="d-flex" style="flex-direction: column;"> -->
                <!-- <span class="editable_badge_style_collection ms-2">CUMULATIVE/TARGET</span> -->
                <span :class="[getColour(calculateAverage('employee', false), 'employee'),'badge_styles', 'badge_cell', 'ms-2', 'data_badge_cell']" style="color: black;">{{ calculateAverage('employee', false) }}</span>   
            <!-- </div> --> 
        </div>
        <div class="details_badge_collection_styling" style="height: 15px !important" v-if="viewType == 'values'">
            <span class="badge_cell column_heading_styles"></span>
            <span class="badge_cell column_heading_styles"></span>
            <span class="badge_cell column_heading_styles"></span>
            <span class="badge_cell column_heading_styles"></span>
            <span class="badge_cell ms-2" style="height: 15px !important">Manager Rating</span>
        </div>
        <div v-if="!loading && viewType == 'values'" class="details_badge_collection_styling" >
            
            <div v-for="(quarter, index) in value_scores"  :key="index">
                <span type="text" class="input-class"
                :id="index"
                :placeholder="quarter.manager" 
                :class="[getColour(quarter.manager, 'manager'),'badge_styles', 'badge_cell', 'data_badge_cell']">
                {{ quarter.manager }}
                </span>
            </div>
            <!-- <div class="d-flex" style="flex-direction: column;"> -->
                <!-- <span class="editable_badge_style_collection ms-2">CUMULATIVE/TARGET</span> -->
                <span :class="[getColour(calculateAverage('manager', false), 'manager'), 'badge_styles', 'badge_cell', 'ms-2', 'data_badge_cell']" style="color: black;">{{ calculateAverage('manager', false) }}</span>   
            <!-- </div> --> 
        </div>

        <div  class="details_badge_collection_styling" style="height: 15px !important" v-if="viewType == 'values'">
            <span class="badge_cell column_heading_styles"></span>
            <span class="badge_cell column_heading_styles"></span>
            <span class="badge_cell column_heading_styles"></span>
            <span class="badge_cell column_heading_styles"></span>
            <span class="badge_cell ms-2" style="height: 15px !important">Final Score</span>
        </div>
        <div v-if="!loading && viewType == 'values'" class="details_badge_collection_styling" >
            
            <div v-for="(quarter, index) in value_scores"  :key="index">
                <span type="text" class="input-class"
                :id="index"
                :placeholder="quarter.final" 
                :class="[getColour(quarter.final, 'final'),'badge_styles', 'badge_cell', 'data_badge_cell']">
                {{ quarter.final }}
                </span>
            </div>
            <!-- <div class="d-flex" style="flex-direction: column;"> -->
                <!-- <span class="editable_badge_style_collection ms-2">CUMULATIVE/TARGET</span> -->
                <span :class="[getColour(calculateAverage('final'), 'final'),'badge_styles', 'badge_cell', 'ms-2', 'data_badge_cell']" style="color: black;">{{ calculateAverage('final', false) }}</span>   
            <!-- </div> --> 
        </div>

        <!-- TOTAL SCORE ROW -->
        <div class="details_badge_collection_styling" style="height: 15px !important" v-if="viewType != 'values'">
            <span class="badge_cell column_heading_styles"></span>
            <span class="badge_cell column_heading_styles"></span>
            <span class="badge_cell column_heading_styles"></span>
            <span class="badge_cell column_heading_styles"></span>
            <span v-if="viewType != 'objectives'" class="badge_cell ms-2" style="height: 15px !important">Final Score</span>
            <span v-if="viewType == 'objectives'" class="badge_cell ms-2" style="height: 15px !important">Review Score</span>
        </div>
        <div v-if="!loading & viewType != 'values'" class="details_badge_collection_styling">
            
            <div v-for="(quarter, index) in base_scores"  :key="index">
                <span type="text" class="input-class"
                :id="index"
                :placeholder="quarter.score" 
                :class="[getColour(quarter.score, 'final'), 'badge_styles', 'badge_cell', 'data_badge_cell']">
                {{ quarter.score }}
                </span>
            </div>
            <!-- <div class="d-flex" style="flex-direction: column;"> -->
                <!-- <span class="editable_badge_style_collection ms-2">CUMULATIVE/TARGET</span> -->
                <span :class="['badge_styles', 'badge_cell', 'ms-2', 'data_badge_cell']" style="color: black;">{{ calculateAverage('score', false) }}</span>   
            <!-- </div> --> 
        </div>
    </div>
    
</template>

<script>
import treeComponentMixin from '@/mixins/treeComponentMixin.js'
import { scorecardComputed, scorecardMethods } from '@/state/helpers'
import _ from 'lodash'
export default {
    name: 'details_badge_collection',
    mixins: [ treeComponentMixin ],
    props:{
        viewType: {
            required: true
        }  
    },
    watch: {
        details_page_data() {
            this.setScore()
        }
    },
    data:() => ({
        loading: false,
        updatedValue: null,
        invalid: false,
        average: 0,
        rag_threshholds:{
            // TODO - set up colour change if isRagEnabled == true
            r: 'border_low',
            a: 'border_medium',
            g: 'border_high'
        },
        meta:{low: 30, high: 60},
        base_scores: [{achieved: 0, score: 0, target: 0},
                 {achieved: 0, score: 0, target: 0},
                 {achieved: 0, score: 0, target: 0},
                 {achieved: 0, score: 0, target: 0}],
        value_scores: [{employee: 0, manager: 0, final: 0},
                        {employee: 0, manager: 0, final: 0},
                        {employee: 0, manager: 0, final: 0},
                        {employee: 0, manager: 0, final: 0}]
    }),
    computed:{
        ...scorecardComputed,
        showEmployeeRating(){
            let employee_submitted = this.details_page_data.comment_status.user_submitted;
            let manager_submitted = this.details_page_data.comment_status.manager_submitted
            let final_submitted = this.details_page_data.comment_status.final_submitted;
            if(this.viewingUserType == 'manager'){
                //* only show employee rating when employee and manager has submitted
                return final_submitted || employee_submitted && manager_submitted;
            }
            else{
                //* Always show OWN rating
                return true;
            }
            
        },
        showManagerRating(){
            let employee_submitted = this.details_page_data.comment_status.user_submitted;
            let manager_submitted = this.details_page_data.comment_status.manager_submitted
            let final_submitted = this.details_page_data.comment_status.final_submitted;
            if(this.viewingUserType == 'employee'){
                //* only show manager rating when employee and manager has submitted
                return final_submitted || employee_submitted && manager_submitted;
            }
            else{
                // alwasy show own
                return true;
            }
        },
        showFinalRating(){
            let final_submitted = this.details_page_data.comment_status.final_submitted;
            return final_submitted;
        },
    },
    methods:{
        ...scorecardMethods,
        calculateAverage(key, percentage) {
            let count = 0
            let total = 0
            switch(this.viewType) {
                case 'values' : {
                    this.value_scores.forEach((value_score) => {
                        if(value_score[key] && value_score[key] != null) {
                           count += 1
                           total += Number(value_score[key])
                        }
                    })
                    if(count > 0) {
                        total = Number(total)/Number(count)
                    }
                    return total.toFixed(2)
                }
                case 'objectives' : {
                    let count = 0
                    let total = 0
                    this.base_scores.forEach((value_score) => {
                        if(value_score[key] && value_score[key] != null) {
                           count += 1
                           total += value_score[key]
                        }
                    })
                    if(count > 0) {
                        total = Number(total)/Number(count)
                    }
                    if(percentage) {
                        total = total * 10
                    }
                    return total.toFixed(2)
                }
                case 'productivity' : {
                    let count = 0
                    let total = 0
                    this.base_scores.forEach((value_score) => {
                        if(value_score[key] && value_score[key] != null) {
                           count += 1
                           total += value_score[key]
                        }
                    })

                    if(count > 0 && key == "score") {
                        total = Number(total)/Number(count)
                    }
                    if(percentage) {
                        total = total * 10
                    }
                    return total.toFixed(2)
                }
                default : {
                    return 0
                }

            }
        },
        calculatedScorePercentage(value) {
            if(value == null) {
                return null
            }
            let percentage = value * 10
            return percentage
        },
        getColour(score, type) {
            let scoreValue = score
            console.log(score);
            console.log(type)
            switch(type) {
                case 'employee' : {
                    if(!this.showEmployeeRating) {
                         return 'disabled_badge'
                    }
                    break
                }
                case 'manager' : {
                    if(!this.showManagerRating) {
                        return 'disabled_badge'
                    }
                    break
                }
                case 'final' : {
                    if(!this.showFinalRating) {
                        return 'disabled_badge'
                    }
                    break
                }
            }

            // if(status) {
            //     return 'disabled_badge'
            // }
            //let el = this
             //return 'border_medium'
             if(this.details_page_data.scores) {
                     let low = this.meta.low
                     let high = this.meta.high
                     let value = this.calculatedScorePercentage(scoreValue)
                     if(value == null) {
                         return 'disabled_badge'
                     }
                     if(Number(value) <= low && Number(value) >= 0) {
                         return 'quarter_low'
                     }   
                     if (Number(value) <= high && Number(value) > low) {
                         return 'quarter_medium'
                     }
                     if(Number(value) > high) {
                         return 'quarter_high'
                     }
             } else {
                 return ''
             }
             return '';
        },
         isStateValid(value) {
            if(value >= 0 && value <= 100) {
                return null
            }
            this.invalid = false
            return false
         },
         setScore() {
            let base = null
            let scores = this.details_page_data.scores
            console.log(this.viewType)
            if(this.viewType == "values") {
                base = _.cloneDeep(this.value_scores)
                base.forEach((value_score, base_index) => {
                    let scores = this.details_page_data.scores
                    let index = base_index + 1
                    value_score.employee = Math.round(scores[index].employee)
                    value_score.final = Math.round(scores[index].final)
                    value_score.manager = Math.round(scores[index].manager)
                })
                this.value_scores = base
            } else {
                base = _.cloneDeep(this.base_scores)
                base.forEach((base_score, base_index) => {
                    let index = base_index + 1 
                    // if(this.viewType == "objectives") {
                    //     if(scores[index]){
                    //         base_score.score = scores[index]
                    //     } else {
                    //         base_score.disabled = true
                    //     }
                    // } else {
                   
                    base_score.achieved = Math.round(scores[index].achieved)
                    base_score.score = Math.round(scores[index].score)
                    base_score.target = Math.round(scores[index].target)
                    // if(scores[index].score == null) {
                    //     base_score.disabled = true
                    //     base_score.score = Math.round(scores[index].score)
                    // }
                    //}
                })
            }
            
            
            this.base_scores = base
         }
    },
    mounted(){
        this.setScore()
        // this.quarter_data = this.details_page_data.scores
    },
}
</script>

<style>
.details_badge_collection_styling {
    display: flex;
    flex-direction: grid; 
    justify-content: center;
    font-size: 0.5rem;
    padding: 0
}
.badge_cell {
    min-width: 110px !important; 
    width: 110px;
    height: 20px;
    margin: 5px;
    
    text-align: center;
}
.data_badge_cell {
    border: 1px solid grey;
    color: black;
}
.column_heading_styles {
   font-size: 0.7rem;
}
.border_low {
    border: 2px solid #F46A6A
}
.border_low:focus {
    border: 2px solid #F46A6A
}
.border_medium {
    border: 2px solid #FFA800
}
.border_medium:focus {
    border: 2px solid #FFA800
}
.border_high {
    border: 2px solid #34C38F
}
.border_high:focus {
    border: 2px solid #34C38F
}
.disabled_badge {
    background-color: white !important;
    color: white !important;
    font-size: 0 !important;
    border: 1px solid #DFE4EB
}
/* .form-control:invalid {
  background-color: ivory;
  border: none;
  outline: 2px solid black !important;
} */
</style>