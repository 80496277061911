<template>
    <div class="element-wrapper d-flex"  :class="isActive(meta)">
        <span class="element-content" :class="noWrapText(meta)" style="">
            <div class="d-flex flex-column text-capitalize compact-font scorecard-tags-color" >
                <div class="d-flex mx-auto">
                    <span class="me-1">{{ tags[0] }}</span>
                    <span>{{ tags[1] }}</span>
                </div>
                <div class="d-flex mx-auto">
                    <span class="me-1">{{ tags[2] }}</span>
                    <span>{{ tags[3] }}</span>
                </div>
            </div>
        </span>
    </div>
</template>

<script>
import treeComponentMixin from '@/mixins/treeComponentMixin.js'
export default {
    name: 'tags',
    mixins: [ treeComponentMixin ],
    props:{
        meta:{}
    },
    data:() => ({
    }),
    computed:{
        tags(){
            //* takes 4 tags
            let tag_array = new Array(4)
            if(this.meta.value){
                this.meta.value.forEach( (tag, index) => {
                    tag_array[index] = tag;
                })

                // if(tag_array[4]){
                //     tag_array[3] += ', '
                // }
                if(tag_array[3]){
                    tag_array[2] += ', '
                }
                // if(tag_array[2]){
                //     tag_array[1] += ', '
                // }
                if(tag_array[1]){
                    tag_array[0] += ', '
                }
            }
            return tag_array;
        }
    },
    methods:{},
    mounted(){},
}
</script>

<style>
.scorecard-tags-color{
    color: #6E84A7;
}

</style>