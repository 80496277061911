<template>
    <div class="element-wrapper d-flex my-auto" >
        <span class="element-content">
            <input
                class="form-check-input"
                style="cursor:pointer;"
                v-model="local_value"
                type="checkbox"
            />
        </span>
    </div>
</template>

<script>
import treeComponentMixin from '@/mixins/treeComponentMixin.js'
export default {
    name: 'checkbox_label',
    mixins: [ treeComponentMixin ],
    props:{
        meta:{},
    },
    inject:['renderer_config'],
    data:() => ({
        local_value: false,
        has_mounted: false,
    }),
    watch:{
        local_value(new_value){
            if(this.has_mounted){
                console.log('emit')
                this.$emit('updatedValue',new_value );
            }
        }
    },
    computed:{
        requiresInfo() {
            if(this.meta && this.meta.requiresInfo){
                return this.meta.requiresInfo;
            }
            return '';
        }
    },
    methods:{},
    mounted(){
        this.local_value = this.meta.value ? this.meta.value : false;

        this.$nextTick(()=>{
            this.has_mounted = true;
        })
    }
}
</script>

<style>

</style>