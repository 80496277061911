<template>
  <div>
    <div class="m-1">
      <div class="mb-2 mt-2">
        Showing <b>five</b> KPI graphs for for a <b>Productivity Target Item</b> in a <b>Yearly Review</b> on your
        <b>Performance Scorecard.</b>
      </div>
      <div>
        <span class="mt-2">KPIs</span>
        <b-alert class="m-2" variant="primary" :show="userData.length == 0">
          No KPIs for User
        </b-alert>
      </div>
      <Swiper id="kpiSwiper" v-if="userData.length > 0" :slides-per-view="4" :space-between="20"
        :allowTouchMove="false" :draggable="false" :loop="false" :pagination="{
          el: '.swiper-pagination2',
          clickable: true,
        }" :breakpoints="{
          0: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          1090: {
            slidesPerView: 2,
            spaceBetween: 10
          },
          1440: {
            slidesPerView: 4,
            spaceBetween: 10
          },
          1780: {
            slidesPerView: 5,
            spaceBetween: 10
          },
          2200: {
            slidesPerView: 5,
            spaceBetween: 10
          }
        }" :navigation="{
          nextEl: '.user-next',
          prevEl: '.user-prev',
        }" @swiper="onSwiper" @slideChange="onSlideChange">
        <SwiperSlide v-for="item in userData" :key="item.id">
          <b-card @click="selectCard(item.id)"
            class="kpi_card_styles">
              <apexchart
                  v-if="item.tracking.length > 1 && !loading"
                  type="line"
                  height="100"
                  width="337"
                  :options="chartOptions"
                  :series="[{name: 'Activity',data: sparkline_series(item)}]">
              </apexchart>
              <b-alert class="mt-1 mb-0" show v-else>
                  Not enough data to display graph
              </b-alert>
          </b-card>
        </SwiperSlide>
      </Swiper>
      <div class="carousel__controls" v-if="userData.length > 0">
        <font-awesome-icon class="my-button-prev user-prev" icon="fa-angle-left"></font-awesome-icon>
        <div class="swiper-pagination2"> </div>
        <font-awesome-icon class="my-button-next user-next" icon="fa-angle-right"></font-awesome-icon>
      </div>
    </div>
  </div>

</template>

<script>
import { Navigation, Pagination } from 'swiper'
import { scorecardComputed, scorecardMethods, activityComputed, activityMethods, levelComputed } from "@/state/helpers";
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import graphMixin from '@/mixins/graphHelpers.js'
// Import Swiper styles
//import apexchart from "vue-apexcharts";
import 'swiper/swiper-bundle.css'
//import TargetGraph from '../../widgets/objectives/targetGraph.vue';

SwiperCore.use([Navigation, Pagination])

export default {
  mixins:[graphMixin],
  props: {
    viewType: {
      required: true
    }
  },
  components: {
    // eslint-disable-next-line
    Swiper,
    // eslint-disable-next-line
    SwiperSlide,
    //apexchart
    //TargetGraph
  },
  computed: {
    ...scorecardComputed,
    ...activityComputed,
    ...levelComputed
  },
  watch: {
    details_page_data() {
      this.loading = true
      setTimeout(() => {
        this.userData = this.details_page_data.kpis
        this.loading = false
      }, 800)
    }
  },
  mounted() {
    //setTimeout(() => {
      this.userData = this.details_page_data.kpis
      //this.loading = false
    //}, 800)
    
  },
  data: () => ({
    selectedItem: -1,
    total: [],
    managerData: [],
    userData: [],
    document: null,
    selectedId: -1,
    body: '',
    is_dragingg: false,
    loading: false,
    chartOptions: {
          chart: {
              sparkline: {
                  enabled: true
              }
          },
          stroke: {
              curve: 'straight',
              width: 1,
          },
          tooltip: {
              enabled: false
          },
          noData: {
              text: 'No Chart Data',
              align: 'center',
              verticalAlign: 'middle',
              //offsetX: 0,
              //offsetY: -10,
              style: {
                  color: 'grey',
                  fontSize: '10px',
                  //fontFamily: undefined
              }
          },
      }
  }),
  methods: {
    ...scorecardMethods,
    ...activityMethods,
    sparkline_series(item){
      console.log(JSON.parse(JSON.stringify(item)))
        return this.processSparklineSeries(item.tracking);
    },
    refresh() {
      let params = {}
      switch (this.viewType) {
        case 'productivity': {
          params = { target_id: this.details_page_data.id, scorecard_period_id: this.objective_viewing_quarter };
          this.loadProductivityDetails(params).then(() => {
            this.userData = this.details_page_data.employee_docs
            this.managerData = this.details_page_data.manager_docs
          })
          break;
        }
        case 'values': {
          params = { value_id: this.details_page_data.id, scorecard_period_id: this.objective_viewing_quarter };
          this.loadValuesDetails(params).then(() => {
            this.userData = this.details_page_data.employee_docs
            this.managerData = this.details_page_data.manager_docs
          })
          break;
        }
        case 'objectives': {
          params = { objective_id: this.details_page_data.id, scorecard_period_id: this.objective_viewing_quarter };
          this.loadObjectiveDetails(params).then(() => {
            this.userData = this.details_page_data.employee_docs
            this.managerData = this.details_page_data.manager_docs
          })
          break;
        }
      }
    },
    uploadFile() {
      document.getElementById('uploadFile').click()
    },
    onSwiper(swiper) {
      console.log(swiper)
    },
    onSlideChange() {
      console.log('slide change')
    },
    selectCard(id) {
      this.selectedId = id
    }

  }
}
</script>

<style lang="scss">
.img-fluid {
  max-width: 100%;
  height: auto;
}

.w-100 {
  width: 100%;
}

.ml-auto,
.mx-auto {
  margin-left: auto;
}

.mr-auto,
.mx-auto {
  margin-right: auto;
}

.kpi_card_styles {
  border: 1px solid #BFC8D6 !important;
  border-radius: 0.2rem;
  min-height: 150px;
  width: 350px
}

.selected_doc_card {
  border: 2px solid #50A5F1 !important;
}

.carousel {
  &__controls {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-top: 1px;
  }
}

// .my-button-prev,
// .my-button-next {
//     height: 26px !important;
//     width: 34px !important;
// }

.swiper-pagination {
  width: unset;
  position: unset;
  display: flex;
}

#kpiSwiper {
  max-height: 200px;
}

.swiper-pagination-bullet {
  margin: 4px !important;
}
</style>