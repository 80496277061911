<template>
    <div>
        <div class="m-1 pe-1 pt-1 d-flex justify-content-end" style="width: 97%;">
            <b-button v-if="selected_scorecard_template_id != -1" @click="deleteTemplate" size='sm' variant="danger">Delete Selected Scorecard Template</b-button>
            <b-button class="ms-1" size='sm' variant="primary" @click="openNewScorecard">Add Scorecard Template +</b-button>
        </div>
        <div class="d-flex m-1 flex-wrap">
            <!-- <div class="m-1 scorecard_card_container scorecard_card_end_margin d-flex" @click="openNewScorecard">
                <font-awesome-icon class="add_template_icon" icon="fa-plus"></font-awesome-icon>
            </div> -->
            <div class="" v-for="(item, idx) in scorecard_templates" :key="idx">
                <b-overlay :show="is_loading && selected_scorecard_template_id == item.id">
                    <div @click="selected_scorecard_template_id == item.id ? setSelectedScorecardTemplateID(-1) : setSelectedScorecardTemplateID(item.id)" 
                    class="m-1 scorecard_card_container scorecard_card_end_margin d-flex flex-column" 
                    :style="selected_scorecard_template_id == item.id ? 'border: 1px solid #50A5F1; margin: 5px; background-color: white' : 'margin: 5px; background-color: white'">
                        <div class="d-flex m-1">
                            <div class="avatar-sm me-3">
                                <span class="avatar-title bg-secondary bg-soft rounded-circle font-size-16">
                                    
                                <b-img
                                style="width: 48px; height: 48px; padding: 2px;"
                                    fluid
                                    :src="getAvatar(item)"
                                    alt
                                    onerror="src='/img/user.png'"
                                    class=" smaller-image-height avatar-md rounded-circle header-profile-user-view"
                                    id="image"
                                    >
                                </b-img>
                            </span> 
                            </div>
                            <div class="mt-2" style="font-size: 0.7rem;">
                                {{ item.name }}
                            </div>
                            <font-awesome-icon class="ms-auto" :icon="['fas', 'angle-right']" @click="showTemplate(item, $event)" style="height: 20px !important; width: 20px !important; margin-top: 5px; color: #CB6CC3" />
                            <!-- <div @click="openScorecard(item.id)"
                                style="width: 30px; height: 30px;"
                                class="ms-auto bg-secondary">
                            </div> -->
                        </div>


                        <div style="font-size: 0.65rem;" class="m-2 main_text_blue mt-auto">
                            <div>
                                Created by : {{ getUserName(item) }}
                            </div>
                            <div>
                                Create date : {{ getFormattedDate(item.created_at) }}
                            </div>
                            <div>
                                Live applications : <strong>{{ 0 }}</strong>
                            </div>
                        </div>
                        <!-- <b-button size="sm" @click="debugLink(item.id)">Debug Link</b-button> -->
                    </div>



                </b-overlay>

            </div>
        </div>
    </div>
</template>

<script>
//import { format } from 'lodash';
import { format, parseISO } from 'date-fns'
import { scorecardComputed, scorecardMethods, levelComputed } from '@/state/helpers';
export default {
    data: () => ({
        is_loading: false,
        show_archived: false,
    }),
    computed: {
        ...scorecardComputed,
        ...levelComputed,
       
        // getUserImage() {
        //     let user = this.all_users.find((user) => {
        //         return user.id = this.item.created_by_user_id 
        //     })
        //     user.avatar_src
        // },
    },
    methods: {
        ...scorecardMethods,
        showTemplate(item,event = null){
            this.openScorecard(item.id)
            if(event != null){
                event.stopPropagation();
            }     
        },
        deleteTemplate() {
            let params = {
                id: this.selected_scorecard_template_id
            }
            this.deleteScorecardTemplate(params).then(() => {
                this.setSelectedScorecardTemplateID(-1);
                this.loadScorecardTemplates({
                    show_archived: 1,
                })
            })
        },
        getUserName(item) {
            let user = this.all_users.find((user) => {
                return user.id = item.created_by_user_id 
            })
            return user.name
        },
        getAvatar(item) {
            let user = this.all_users.find((user) => {
                return user.id = item.created_by_user_id 
            })
            return user.avatar_src
        },
        openNewScorecard(){
            this.setSelectedScorecardTemplateID(-1);
            this.$nextTick(()=>{
                this.$emit('openScorecard');
            })
            
        },
        openScorecard(id) {
            this.is_loading = true;
            this.setSelectedScorecardTemplateID(id);
            this.loadScorecardTemplate({
                id: id,
            })
                .then(() => {
                    this.is_loading = false;
                    this.$emit('openScorecard');
                })
                .catch(() => {
                    this.is_loading = false;
                })
        },
        fetchScorecards() {
            this.loadScorecardTemplates({
                show_archived: 1,
            })
        },
        getFormattedDate(date) {
            if (date) {
                return format(parseISO(date), 'dd/MMM/yyyy')
            } else {
                return date;
            }
            
            // if(!date){ return 'N/A'}
            // return format(new Date(date), 'dd/MM/yy');
        },
        debugLink(temp_id) {
            let params = {
                job_id: 65,
                template_id: temp_id,
            }
            this.saveDefaultJobScorecardTemplate(params);

            // let params2 = {
            //     job_id: 65,
            //     templates:[ temp_id ]
            // }
            // this.saveJobScorecardTemplates(params2);
        }
    },
    mounted() {
        if (this.scorecard_templates.length == 0) {
            this.fetchScorecards();
        }
    },
}
</script>

<style scoped>
.add_template_icon {
    border: 1px solid rgb(194, 194, 194);
    margin: auto;
    border-radius: 35px;

    height: 70px;
    width: 70px;
    color: rgb(194, 194, 194);
}

.add_template_icon:hover {
    color: grey;
    border: 1px solid grey;
    transition: all 0.2s linear;
}
</style>