<template>
    <b-modal
    size="xl"
    scrollable
    title='title'
    id='scorecardWeightingModal'
    hide-footer>
    <pre>{{ objectives_data }}</pre>
        <ComponentHeader v-if="false" class="mb-3" useHtml title="Set Scorecard Weighting" :description="getDescription">
            <template #extra-content>
                <div class="header_card_container d-flex flex-column" style="height: 187px;">
                    User Card

                    <div class="d-flex mt-auto">
                        <b-button size="sm" variant="info" class="ms-auto me-2">Revert to Default Weightings</b-button>
                        <b-button size="sm" variant="info">Save Weightings</b-button>
                    </div>
                </div>
            </template>
        </ComponentHeader>
        <Renderer :config="root_config" @dataUpdateEvent="handleDataUpdate"></Renderer>
    </b-modal>

</template>

<script>
import Renderer from '@/components/widgets/treeTable/renderer.vue';
import ComponentHeader from '../../../../widgets/header/componentHeader.vue';
import { scorecardComputed, activityComputed } from '@/state/helpers';
import { getQuarter } from 'date-fns';
import { cloneDeep } from 'lodash';
export default {
    components:{
        Renderer,
        ComponentHeader,
    },
    data:() => ({
        show: false,
        section_weightings:{
            values:0,
            objectives: 0,
            productivity: 0,
            total: 0,
        },
        values_data:[],
        productivity_data:[],
        objectives_data:{
            1:[], 2:[], 3:[], 4:[]
        },
    }),
    watch:{
        values_children:{
            immediate: true,
            handler(newValue){
                this.values_data = cloneDeep(newValue);
            }
        },
        productivity_children:{
            immediate: true,
            handler(newValue){
                this.productivity_data = cloneDeep(newValue);
            }
        },
        grouped_objectives:{
            immediate: true,
            handler(newValue){
                this.objectives_data = cloneDeep(newValue);
            }
        }
    },
    computed:{
        ...activityComputed,
        ...scorecardComputed,
        groupWeightingsTotal(){
            return this.section_weightings.total;
        },
        objectives_children(){
            return this.weight_modal_data.objective_data
        },
        productivity_children(){
            let calculated_total_weight = 0;
            let data = cloneDeep(this.weight_modal_data.productivity_data);
            data.forEach( (item) => {
                calculated_total_weight += item.weighting;
            })
            data[0]['calculated_total_weight'] = calculated_total_weight;
            return data;
            //return this.weight_modal_data.productivity_data
        },
        values_children(){
            let calculated_total_weight = 0;
            let data = cloneDeep(this.weight_modal_data.value_data);
            data.forEach( (item) => {
                calculated_total_weight += item.weighting;
            })
            data[0]['calculated_total_weight'] = calculated_total_weight;
            return data;
        },
        grouped_objectives(){
            const quarterMap = {
                1: [],
                2: [],
                3: [],
                4: [],
                5: [],
            };
            this.objectives_children.forEach((objective)=>{
                let quarter = getQuarter(new Date(objective.start_date));
                if(isNaN(quarter)){
                    quarterMap[5].push(objective);
                }
                else{
                    quarterMap[quarter].push(objective);
                }
            })
            for(var i = 1; i <= 4; i++){
                let calculated_total_weight = 0;
                quarterMap[i].forEach( (item) => {
                    calculated_total_weight += item.weighting;
                });
                if(quarterMap[i].length < 0){
                    quarterMap[i][0]['calculated_total_weight'] = calculated_total_weight;
                }
            }
            return quarterMap;
        },

        getDescription(){
            return `You are setting the weightings for the scorecard of <strong>Bruce Wayne</strong>. 
                    You can adjust  the weighting of any Group  or item in a Group. 
                    Please note you will be unable to save changes you make if the sum of item weightings do not equal 100%. 
                    <br><br>
                    Weightings you have changed from default values are indicated by a revert button/icon.`
        },
        root_config(){
            let cfg = {
                id: 'root_weighting',
                expanded: true,
                child_type: 'component',
                hide_header: true,
                borders: ['right'],
                columns:[
                    {
                        id: 'name',
                        width: 'fillspace',
                        header: {
                            classes: ['d-flex', 'my-auto' , 'ps-2', 'font-weight-bold', 'font-size-14'],
                            components: [
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        value: 'Total Score',
                                    }
                                },
                            ]
                            
                        },
                        row:{
                            components: [
                                {
                                    component_id: 'text_label',
                                }
                            ],
                        }
                    },
                    {
                        id: 'total_score',
                        width: '370px',                     
                        header: {
                            text: '',
                            classes: ['d-flex', 'ms-auto', 'pe-2'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        no_wrap: true,
                                        value: '(Sum of : Values, Skills, Objectives, Productivity)',
                                    }
                                },
                            ]
                        }
                    }
                ],
                children:[
                    this.values_config,
                    this.objectives_config_q1,
                    this.objectives_config_q2,
                    this.objectives_config_q3,
                    this.objectives_config_q4,
                    this.productivity_config,
                ],
            }

            return cfg;
        },
        objectives_config_q1(){
            let cfg = {
                ref: 'weighting_objectives_q1',
                id: 'weighting_objectives_q1',
                expanded: true,
                borders:['right'],
                type: 'values',
                child_type: 'row', // component or row
                //hide_header: false, // should only check for true and undefined
                columns:[
                    {
                        id: 'name',
                        width: 'fillspace',
                        header: {
                            classes:['d-flex','my-auto','purple_dark', 'ps-1' , 'font-weight-bold', 'font-size-14'],
                            text: '',
                            components: [
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        no_wrap: true,
                                        value: 'Objectives',
                                    }
                                },
                            ]
                        },
                        row:{
                            classes:['d-flex','w-100','row_borders', 'ps-2'],
                            components: [
                                {
                                    component_id: 'text_label',
                                    meta:{

                                    },
                                    variable_id: 'name'
                                }
                            ],
                        }
                    },
                    {
                        id: 'priority',
                        width: '139px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'INDIVIDUAL OBJECTIVE PRIORITY'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'side_row_borders_left', 'center_badge'],
                            components:[
                                {
                                    component_id: 'display_badge',
                                    meta:{
                                        priority_states: true,
                                    },
                                    variable_id: 'priority'
                                }
                            ]
                        }
                    },
                    {
                        id: 'weighting',
                        width: '139px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'SET/ADJUST INDIVIDUAL ITEMS WEIGHTING'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'side_row_borders_left', 'center_badge'],
                            components:[
                                {
                                    component_id: 'text_input',
                                    meta:{
                                        placeholder: ' - % ',
                                        width: '80',
                                        //reset_icon: true,
                                    },
                                    variable_id: 'weighting'
                                }
                            ]
                        }
                    },
                    {
                        id: 'weighting_total',
                        width: '121px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'INDIVIDUAL WEIGHTINGS TOTAL'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'side_row_borders_left', 'center_badge'],
                            components:[
                                {
                                    component_id: 'header_value_label',
                                    meta:{
                                        display_on_first_row: true,
                                        prepend_text: 'Q1:',
                                        postpend_text: '%',
                                    },
                                    variable_id: 'calculated_total_weight'
                                }
                            ]
                        }
                    },
                    {
                        id: 'group_weight',
                        width: '180px',
                        header:{
                            classes:["d-flex", "ms-auto", 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'SET GROUP WEIGHTING'
                                    }
                                },
                                {
                                    component_id: 'text_input',
                                    meta:{
                                        value: this.section_weightings.objectives,
                                        width: '80px',
                                        // connection_lines:{
                                        //     left: true,
                                        // }
                                    },
                                    element_classes:['ps-2', 'pe-2']
                                }
                            ]
                        },
                        row:{
                            classes:['side_row_borders_left', 'empty_element'],
                            components:[
                                {
                                    component_id: 'empty_block',
                                    meta:{
                                        
                                    },
                                }
                            ]
                        }
                    },
                    {
                        id: 'group_weightings_total',
                        width: '90px',
                        header:{
                            classes:["d-flex", "ms-auto", 'my-auto'],
                            components:[
                                {
                                    component_id: 'empty_block',
                                    meta:{},
                                    element_classes:['ps-2', 'pe-2']
                                }
                            ]
                        },
                        row:{
                            classes:['empty_element'],
                            components:[
                                {
                                    component_id: 'empty_block',
                                    meta:{
                                        
                                    },
                                }
                            ]
                        }
                    }
                ],
                children: this.grouped_objectives[1],
            }
            return cfg;
        },
        objectives_config_q2(){
            let cfg = {
                ref: 'weighting_objectives_q2',
                id: 'weighting_objectives_q2',
                expanded: true,
                borders:['right'],
                type: 'values',
                hide_header: true,
                child_type: 'row', // component or row
                //hide_header: false, // should only check for true and undefined
                columns:[
                    {
                        id: 'name',
                        width: 'fillspace',
                        header: {
                            classes:['d-flex','my-auto','purple_dark', 'ps-1' , 'font-weight-bold', 'font-size-14'],
                            text: '',
                            components: [
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        no_wrap: true,
                                        value: 'Objectives',
                                    }
                                },
                            ]
                        },
                        row:{
                            classes:['d-flex','w-100','row_borders', 'ps-2'],
                            components: [
                                {
                                    component_id: 'text_label',
                                    meta:{

                                    },
                                    variable_id: 'name'
                                }
                            ],
                        }
                    },
                    {
                        id: 'priority',
                        width: '139px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'INDIVIDUAL OBJECTIVE PRIORITY'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'side_row_borders_left', 'center_badge'],
                            components:[
                                {
                                    component_id: 'display_badge',
                                    meta:{
                                        priority_states: true,
                                    },
                                    variable_id: 'priority'
                                }
                            ]
                        }
                    },
                    {
                        id: 'weighting',
                        width: '139px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'SET/ADJUST INDIVIDUAL ITEMS WEIGHTING'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'side_row_borders_left', 'center_badge'],
                            components:[
                                {
                                    component_id: 'text_input',
                                    meta:{
                                        placeholder: ' - % ',
                                        width: '80',
                                        //reset_icon: true,
                                    },
                                    variable_id: 'weighting'
                                }
                            ]
                        }
                    },
                    {
                        id: 'weighting_total',
                        width: '121px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'INDIVIDUAL WEIGHTINGS TOTAL'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'side_row_borders_left', 'center_badge'],
                            components:[
                                {
                                    component_id: 'header_value_label',
                                    meta:{
                                        display_on_first_row: true,
                                        prepend_text: 'Q2:',
                                        postpend_text: '%',
                                    },
                                    variable_id: 'calculated_total_weight'
                                }
                            ]
                        }
                    },
                    {
                        id: 'group_weight',
                        width: '180px',
                        header:{
                            classes:["d-flex", "ms-auto", 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'SET GROUP WEIGHTING'
                                    }
                                },
                                {
                                    component_id: 'text_input',
                                    meta:{
                                        value: this.section_weightings.objectives,
                                        width: '80px',
                                        // connection_lines:{
                                        //     left: true,
                                        // }
                                    },
                                    element_classes:['ps-2', 'pe-2']
                                }
                            ]
                        },
                        row:{
                            classes:['side_row_borders_left', 'empty_element'],
                            components:[
                                {
                                    component_id: 'empty_block',
                                    meta:{
                                        
                                    },
                                }
                            ]
                        }
                    },
                    {
                        id: 'group_weightings_total',
                        width: '90px',
                        header:{
                            classes:["d-flex", "ms-auto", 'my-auto'],
                            components:[
                                {
                                    component_id: 'empty_block',
                                    meta:{},
                                    element_classes:['ps-2', 'pe-2']
                                }
                            ]
                        },
                        row:{
                            classes:['empty_element'],
                            components:[
                                {
                                    component_id: 'empty_block',
                                    meta:{
                                        
                                    },
                                }
                            ]
                        }
                    }
                ],
                children:this.grouped_objectives[2],
            }
            return cfg;
        },
        objectives_config_q3(){
            let cfg = {
                ref: 'weighting_objectives_q3',
                id: 'weighting_objectives_q3',
                expanded: true,
                borders:['right'],
                type: 'values',
                hide_header: true,
                child_type: 'row', // component or row
                //hide_header: false, // should only check for true and undefined
                columns:[
                    {
                        id: 'name',
                        width: 'fillspace',
                        header: {
                            classes:['d-flex','my-auto','purple_dark', 'ps-1' , 'font-weight-bold', 'font-size-14'],
                            text: '',
                            components: [
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        no_wrap: true,
                                        value: 'Objectives',
                                    }
                                },
                            ]
                        },
                        row:{
                            classes:['d-flex','w-100','row_borders', 'ps-2'],
                            components: [
                                {
                                    component_id: 'text_label',
                                    meta:{

                                    },
                                    variable_id: 'name'
                                }
                            ],
                        }
                    },
                    {
                        id: 'priority',
                        width: '139px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'INDIVIDUAL OBJECTIVE PRIORITY'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'side_row_borders_left', 'center_badge'],
                            components:[
                                {
                                    component_id: 'display_badge',
                                    meta:{
                                        priority_states: true,
                                    },
                                    variable_id: 'priority'
                                }
                            ]
                        }
                    },
                    {
                        id: 'weighting',
                        width: '139px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'SET/ADJUST INDIVIDUAL ITEMS WEIGHTING'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'side_row_borders_left', 'center_badge'],
                            components:[
                                {
                                    component_id: 'text_input',
                                    meta:{
                                        placeholder: ' - % ',
                                        width: '80',
                                        //reset_icon: true,
                                    },
                                    variable_id: 'weighting'
                                }
                            ]
                        }
                    },
                    {
                        id: 'weighting_total',
                        width: '121px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'INDIVIDUAL WEIGHTINGS TOTAL'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'side_row_borders_left', 'center_badge'],
                            components:[
                                {
                                    component_id: 'header_value_label',
                                    meta:{
                                        display_on_first_row: true,
                                        prepend_text: 'Q3:',
                                        postpend_text: '%',
                                    },
                                    variable_id: 'calculated_total_weight'
                                }
                            ]
                        }
                    },
                    {
                        id: 'group_weight',
                        width: '180px',
                        header:{
                            classes:["d-flex", "ms-auto", 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'SET GROUP WEIGHTING'
                                    }
                                },
                                {
                                    component_id: 'text_input',
                                    meta:{
                                        value: this.section_weightings.objectives,
                                        width: '80px',
                                        // connection_lines:{
                                        //     left: true,
                                        // }
                                    },
                                    element_classes:['ps-2', 'pe-2']
                                }
                            ]
                        },
                        row:{
                            classes:['side_row_borders_left', 'empty_element'],
                            components:[
                                {
                                    component_id: 'empty_block',
                                    meta:{
                                        
                                    },
                                }
                            ]
                        }
                    },
                    {
                        id: 'group_weightings_total',
                        width: '90px',
                        header:{
                            classes:["d-flex", "ms-auto", 'my-auto'],
                            components:[
                                {
                                    component_id: 'empty_block',
                                    meta:{},
                                    element_classes:['ps-2', 'pe-2']
                                }
                            ]
                        },
                        row:{
                            classes:['empty_element'],
                            components:[
                                {
                                    component_id: 'empty_block',
                                    meta:{
                                        
                                    },
                                }
                            ]
                        }
                    }
                ],
                children:this.grouped_objectives[3],
            }
            return cfg;
        },
        objectives_config_q4(){
            let cfg = {
                ref: 'weighting_objectives_q4',
                id: 'weighting_objectives_q4',
                expanded: true,
                borders:['right'],
                type: 'values',
                hide_header: true,
                child_type: 'row', // component or row
                //hide_header: false, // should only check for true and undefined
                columns:[
                    {
                        id: 'name',
                        width: 'fillspace',
                        header: {
                            classes:['d-flex','my-auto','purple_dark', 'ps-1' , 'font-weight-bold', 'font-size-14'],
                            text: '',
                            components: [
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        no_wrap: true,
                                        value: 'Objectives',
                                    }
                                },
                            ]
                        },
                        row:{
                            classes:['d-flex','w-100','row_borders', 'ps-2'],
                            components: [
                                {
                                    component_id: 'text_label',
                                    meta:{

                                    },
                                    variable_id: 'name'
                                }
                            ],
                        }
                    },
                    {
                        id: 'priority',
                        width: '139px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'INDIVIDUAL OBJECTIVE PRIORITY'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'side_row_borders_left', 'center_badge'],
                            components:[
                                {
                                    component_id: 'display_badge',
                                    meta:{
                                        priority_states: true,
                                    },
                                    variable_id: 'priority'
                                }
                            ]
                        }
                    },
                    {
                        id: 'weighting',
                        width: '139px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'SET/ADJUST INDIVIDUAL ITEMS WEIGHTING'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'side_row_borders_left', 'center_badge'],
                            components:[
                                {
                                    component_id: 'text_input',
                                    meta:{
                                        placeholder: ' - % ',
                                        width: '80',
                                        //reset_icon: true,
                                    },
                                    variable_id: 'weighting'
                                }
                            ]
                        }
                    },
                    {
                        id: 'weighting_total',
                        width: '121px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'INDIVIDUAL WEIGHTINGS TOTAL'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'side_row_borders_left', 'center_badge'],
                            components:[
                                {
                                    component_id: 'header_value_label',
                                    meta:{
                                        display_on_first_row: true,
                                        prepend_text: 'Q3:',
                                        postpend_text: '%',
                                    },
                                    variable_id: 'calculated_total_weight'
                                }
                            ]
                        }
                    },
                    {
                        id: 'group_weight',
                        width: '180px',
                        header:{
                            classes:["d-flex", "ms-auto", 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'SET GROUP WEIGHTING'
                                    }
                                },
                                {
                                    component_id: 'text_input',
                                    meta:{
                                        value: this.section_weightings.objectives,
                                        width: '80px',
                                        // connection_lines:{
                                        //     left: true,
                                        // }
                                    },
                                    element_classes:['ps-2', 'pe-2']
                                }
                            ]
                        },
                        row:{
                            classes:['side_row_borders_left', 'empty_element'],
                            components:[
                                {
                                    component_id: 'empty_block',
                                    meta:{
                                        
                                    },
                                }
                            ]
                        }
                    },
                    {
                        id: 'group_weightings_total',
                        width: '90px',
                        header:{
                            classes:["d-flex", "ms-auto", 'my-auto'],
                            components:[
                                {
                                    component_id: 'empty_block',
                                    meta:{},
                                    element_classes:['ps-2', 'pe-2']
                                }
                            ]
                        },
                        row:{
                            classes:['empty_element'],
                            components:[
                                {
                                    component_id: 'empty_block',
                                    meta:{
                                        
                                    },
                                }
                            ]
                        }
                    }
                ],
                children:this.grouped_objectives[4],
            }
            return cfg;
        },
        productivity_config(){
            let cfg = {
                ref: 'weighting_productivity',
                id: 'weighting_productivity',
                expanded: true,
                borders:['right'],
                type: 'values',
                child_type: 'row', // component or row
                //hide_header: false, // should only check for true and undefined
                columns:[
                    {
                        id: 'name',
                        width: 'fillspace',
                        header: {
                            classes:['d-flex','my-auto','purple_dark', 'ps-1' , 'font-weight-bold', 'font-size-14'],
                            text: '',
                            components: [
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        no_wrap: true,
                                        value: 'Productivity',
                                    }
                                },
                            ]
                        },
                        row:{
                            classes:['d-flex','w-100','row_borders', 'ps-2'],
                            components: [
                                {
                                    component_id: 'text_label',
                                    meta:{

                                    },
                                    variable_id: 'name'
                                }
                            ],
                        }
                    },
                    {
                        id: 'weighting',
                        width: '139px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'SET/ADJUST INDIVIDUAL ITEMS WEIGHTING'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'side_row_borders_left', 'center_badge'],
                            components:[
                                {
                                    component_id: 'text_input',
                                    meta:{
                                        placeholder: ' - % ',
                                        width: '80',
                                        //reset_icon: true,
                                    },
                                    variable_id: 'weighting'
                                }
                            ]
                        }
                    },
                    {
                        id: 'weighting_total',
                        width: '121px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'INDIVIDUAL WEIGHTINGS TOTAL'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'side_row_borders_left', 'center_badge'],
                            components:[
                                {
                                    component_id: 'header_value_label',
                                    meta:{
                                        display_on_first_row: true,
                                        postpend_text: '%',
                                    },
                                    variable_id: 'calculated_total_weight'
                                }
                            ]
                        }
                    },
                    {
                        id: 'group_weight',
                        width: '180px',
                        header:{
                            classes:["d-flex", "ms-auto", 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'SET GROUP WEIGHTING'
                                    }
                                },
                                {
                                    component_id: 'text_input',
                                    meta:{
                                        value: this.section_weightings.productivity,
                                        width: '80px',
                                        // connection_lines:{
                                        //     left: true,
                                        // }
                                    },
                                    element_classes:['ps-2', 'pe-2']
                                }
                            ]
                        },
                        row:{
                            classes:['side_row_borders_left', 'empty_element'],
                            components:[
                                {
                                    component_id: 'empty_block',
                                    meta:{
                                        
                                    },
                                }
                            ]
                        }
                    },
                    {
                        id: 'group_weightings_total',
                        width: '90px',
                        header:{
                            classes:["d-flex", "ms-auto", 'my-auto'],
                            components:[
                                {
                                    component_id: 'empty_block',
                                    meta:{},
                                    element_classes:['ps-2', 'pe-2']
                                }
                            ]
                        },
                        row:{
                            classes:['empty_element'],
                            components:[
                                {
                                    component_id: 'empty_block',
                                    meta:{
                                        
                                    },
                                }
                            ]
                        }
                    }
                ],
                children:this.productivity_data,
            }
            return cfg;
        },
        values_config(){
            let cfg = {
                ref: 'weighting_values',
                id: 'weighting_values',
                expanded: true,
                borders:['right'],
                type: 'values',
                child_type: 'row', // component or row
                //hide_header: false, // should only check for true and undefined
                columns:[
                    {
                        id: 'name',
                        width: 'fillspace',
                        header: {
                            classes:['d-flex','my-auto','purple_dark', 'ps-1' , 'font-weight-bold', 'font-size-14'],
                            text: '',
                            components: [
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        no_wrap: true,
                                        value: 'Values',
                                    }
                                },
                            ]
                        },
                        row:{
                            classes:['d-flex','w-100','row_borders', 'ps-2'],
                            components: [
                                {
                                    component_id: 'text_label',
                                    meta:{

                                    },
                                    variable_id: 'value'
                                }
                            ],
                        }
                    },
                    {
                        id: 'weighting',
                        width: '139px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'SET/ADJUST INDIVIDUAL ITEMS WEIGHTING'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'side_row_borders_left', 'center_badge'],
                            components:[
                                {
                                    component_id: 'text_input',
                                    meta:{
                                        placeholder: ' - % ',
                                        width: '80',
                                        //reset_icon: true,
                                    },
                                    variable_id: 'weighting'
                                }
                            ]
                        }
                    },
                    {
                        id: 'calculated_total_weight',
                        width: '121px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'INDIVIDUAL WEIGHTINGS TOTAL'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'side_row_borders_left', 'center_badge'],
                            components:[
                                {
                                    component_id: 'header_value_label',
                                    meta:{
                                        display_on_first_row: true,
                                        postpend_text: '%',
                                    },
                                    variable_id: 'calculated_total_weight'
                                }
                            ]
                        }
                    },
                    {
                        id: 'group_weight',
                        width: '180px',
                        header:{
                            classes:["d-flex", "ms-auto", 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'SET GROUP WEIGHTING'
                                    }
                                },
                                {
                                    component_id: 'text_input',
                                    meta:{
                                        value: this.section_weightings.values,
                                        width: '80px',
                                        // connection_lines:{
                                        //     left: true,
                                        // }
                                    },
                                    element_classes:['ps-2', 'pe-2']
                                }
                            ]
                        },
                        row:{
                            classes:['side_row_borders_left', 'empty_element'],
                            components:[
                                {
                                    component_id: 'empty_block',
                                    meta:{
                                        
                                    },
                                }
                            ]
                        }
                    },
                    {
                        id: 'group_weightings_total',
                        width: '90px',
                        header:{
                            classes:["d-flex", "ms-auto", 'my-auto'],
                            components:[
                                {
                                    component_id: 'header_value_label',
                                    meta:{
                                        value: this.groupWeightingsTotal,
                                        postpend_text: '%',
                                        // connection_lines:{
                                        //     left: true,
                                        // }
                                    },
                                    element_classes:['ps-2', 'pe-2']
                                }
                            ]
                        },
                        row:{
                            classes:['empty_element'],
                            components:[
                                {
                                    component_id: 'empty_block',
                                    meta:{
                                        
                                    },
                                }
                            ]
                        }
                    }
                ],
                children: this.values_data,
            }
            return cfg;
        },
        
        getGroupWeight(){
            return 0
        }
    },
    methods:{
        handleDataUpdate(payload){
            console.log(payload);
            if(payload.type == 'header_value'){
                //update value based on config id
                let value = Number(payload.data.value);
                switch(payload.id){
                    case 'weighting_values':{
                        this.section_weightings.values = value;
                        break;
                    }
                    case 'weighting_objectives_q1':{
                        this.section_weightings.objectives = value;
                        break;
                    }
                    case 'weighting_productivity':{
                        this.section_weightings.productivity = value;
                        break;
                    }
                    default: {
                        break;
                    }
                }
                this.setWeightingTotals(true);
            }
            else{
                console.log('Row values', payload);
                switch(payload.id){
                    case 'weighting_values':{
                        console.log('$set', this.values_data[payload.data.row_index][payload.data.variable_id]);
                        this.$set(
                            this.values_data[payload.data.row_index],
                            payload.data.variable_id,
                            Number(payload.data.value)
                        )
                        this.values_data[0]['calculated_total_weight'] = this.recalculateSectionTotals(this.values_data);
                        //console.log( this.values_data, JSON.stringify(payload.data), this.values_data[payload.data.row_index]);
                        break;
                    }
                    case 'weighting_objectives_q1':{
                        //this.section_weightings.objectives = value;
                        break;
                    }
                    case 'weighting_objectives_q2':{
                        //this.section_weightings.objectives = value;
                        break;
                    }
                    case 'weighting_objectives_q3':{
                        //this.section_weightings.objectives = value;
                        break;
                    }
                    case 'weighting_objectives_q4':{
                        //this.section_weightings.objectives = value;
                        break;
                    }
                    case 'weighting_productivity':{
                        //this.section_weightings.productivity = value;
                        break;
                    }
                    default: {
                        break;
                    }
                }
            }
        },
        recalculateSectionTotals(data){
            let calculated_total_weight = 0
            data.forEach( (item) => {
                calculated_total_weight += item.weighting;
            })
            return calculated_total_weight;
        },
        setWeightingTotals(recalculate = false){
            //* if recalculate is TRUE -> Get TOTALS from exiting section values array
            if(recalculate){
                let total = 0;
                total = ( this.section_weightings.values + this.section_weightings.objectives + this.section_weightings.productivity );
                this.section_weightings.total = total;

            }
            else{
                this.loaded_user_scorecard.sections.forEach( section =>{
                    this.section_weightings[section.type_name] = section.section_weighting;
                    this.section_weightings.total += section.section_weighting;
                })
            }
            
        },
        save(){},
},
    mounted(){
        this.setWeightingTotals();
    },
}
</script>

<style>
/* set smaller padding on modal */
#scorecardWeightingModal > .modal-dialog{
    margin-top: 8px;
    margin-bottom: 8px;
}


/* //* Custom header to only show X button */
/* hide header title */
#scorecardWeightingModal > .modal-dialog > div > header > h5{
    visibility: hidden;
}
/* remove bottom padding and line from modal header */
#scorecardWeightingModal > .modal-dialog > div > header{
    padding-bottom: 0px;
    border-bottom: 0px;
}
/* remove uper padding from modal body */
#scorecardWeightingModal > .modal-dialog > div > div{
    padding-top: 0px;
}
</style>