
export default {
    methods:{
        getValue(data){
            if(data && data.value){
                return data.value;
            }
            return '';
        },
        //eslint-disable-next-line
        hasConnectionLines(data){
            return data.connection_lines != undefined;
            //return false;
        },
        noWrapText(data){
            if(!data){
                return '';
            }
            return data.no_wrap ? 'no-wrap' : '';
        },
        isActive(data){
            let is_active = data && data.is_active;
            if(is_active == undefined){
                return '';
            }

            return is_active ? 'header-item-active' : 'header-item-inactive';
        },
        isElementExpanded(data){
            if(data && data.is_expanded){
                return data.is_expanded
            }
            return false;
        },

        useHtml(data){
            if(!data){
                return false;
            }
            return data.use_html;
        }

    }
}