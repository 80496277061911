<template>
    <div class="element-wrapper d-flex"  :class="isActive(meta)">
        <span class="element-content compact-font" :class="noWrapText(meta)" style="">
            <div v-html="reviewStatus()"></div>
        </span>
    </div>
</template>

<script>
import treeComponentMixin from '@/mixins/treeComponentMixin.js'
export default {
    name: 'review_status',
    mixins: [ treeComponentMixin ],
    data:() => ({}),
    props:{
        meta:{}
    },
    computed:{

    },
    methods:{
        reviewStatus(){
            let status = this.meta.value
            if(status.final_submitted){
                //* if final review is submitted (use and manager should already be submitted)
                //* show 'Final' msg
                return `<span class="font-weight-bold text-success">Final Review Submitted</span> 
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check"><path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" class=""></path></svg>
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check"><path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" class=""></path></svg>
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check"><path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" class=""></path></svg>`;
            }
            else if(status.manager_submitted && status.user_submitted){
                //* if final is unsubmitted but user+manager has submitted
                return `<span class="font-weight-bold text-warning">Final Review Due</span>`;
            }
            switch(this.meta.viewing_user){
                case 'employee':{
                    let line_1 = status.manager_submitted ? 'Manager Review Submitted' : 'Manager Review Due';
                    let line_2 = status.user_submitted ? 'Your Review Submitted' : 'Your Review Due';
                    return `<span class="font-weight-bold">
                                <div class="${this.getElementColor(status.manager_submitted)}">
                                    ${line_1}
                                    <svg v-if="${status.manager_submitted}" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check"><path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" class=""></path></svg>
                                </div>
                                <div class="${this.getElementColor(status.user_submitted)}">
                                    ${line_2}
                                    <svg v-if="${status.user_submitted}" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check"><path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" class=""></path></svg>
                                </div>
                            </span>`
                }
                case 'manager':{
                    let line_1 = status.manager_submitted ? 'Your Review Submitted' : 'Your Review Due';
                    let line_2 = status.user_submitted ? 'Employee Review Submitted' : 'Employee Review Due';
                    return `<span class="font-weight-bold">
                                <div class="${this.getElementColor(status.manager_submitted)}">
                                    ${line_1}
                                    <svg v-if="${status.manager_submitted}" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check"><path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" class=""></path></svg>
                                </div>
                                <div class="${this.getElementColor(status.user_submitted)}">
                                    ${line_2}
                                    <svg v-if="${status.user_submitted}" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check"><path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" class=""></path></svg>
                                </div>
                            </span>`
                }
                default:{
                    return '';
                }
            }
            // switch(this.meta.value){
            //     case 0:{
            //         return 'Final Review Due'
            //     }
            //     case 1:{
            //         return 'You Submitted'
            //     }
            //     default:{
            //         return 'Final Review Submitted'
            //     }
                
            // }
        },
        getElementColor(status){
            return status == true ? 'text-success' : 'text-warning';
        }
    },
    mounted(){},
}
</script>

<style>

</style>