<template>
    <div class="btn-group pt-0 mb-3 ms-0" role="group" style="display: flex; justify-content: center;">
        <div class="mt-3 pt-2 w-100" style="display: flex; flex-direction: column; justify-content: center;">
            <span class="btn-group ps-1 pe-1 d-flex" style="justify-content: center; font-size: 18px;">
                <div class="d-flex" style="flex-direction: column;">
                    <span>
                        {{ selectedMilestoneName }}
                    </span>
                    <span>
                        Calendar Year
                    </span>
                </div>
                <span class="ms-1">
                    <i class="bi bi-hexagon scorecard-hex" v-show="!objective_viewing_quarter" data-content="" style="color: white" ></i>
                    <i class="bi bi-hexagon scorecard-hex" v-show="objective_viewing_quarter == 1" data-content="Q1" :style="objective_viewing_quarter == 1 ? 'color: white' : ''" ></i>
                    <i class="bi bi-hexagon scorecard-hex" v-show="objective_viewing_quarter == 2" data-content="Q2" :style="objective_viewing_quarter == 2 ? 'color: white' : ''" ></i>
                    <i class="bi bi-hexagon scorecard-hex" v-show="objective_viewing_quarter == 3" data-content="Q3" :style="objective_viewing_quarter == 3 ? 'color: white' : ''" ></i>
                    <i class="bi bi-hexagon scorecard-hex" v-show="objective_viewing_quarter == 4" data-content="Q4" :style="objective_viewing_quarter == 4 ? 'color: white' : ''" ></i>
                    <i class="bi bi-hexagon scorecard-hex" v-show="objective_viewing_quarter == 5" data-content="H1" :style="objective_viewing_quarter == 5 ? 'color: white' : ''" ></i>
                    <i class="bi bi-hexagon scorecard-hex" v-show="objective_viewing_quarter == 6" data-content="H2" :style="objective_viewing_quarter == 6 ? 'color: white' : ''" ></i>
                    <i class="bi bi-hexagon scorecard-hex" v-show="objective_viewing_quarter == 7" data-content="YR" :style="objective_viewing_quarter == 7 ? 'color: white' : ''" ></i>
                </span>
            </span>
        </div>
    </div>
</template>

<script>
import { activityComputed, activityMethods, scorecardComputed, scorecardMethods } from '@/state/helpers'
import _ from 'lodash'
export default {
name: "scorecardDates",
components:{
},
props: {
},

data: () => ({
}),
computed:{
    ...activityComputed,
    ...scorecardComputed,
    selectedMilestoneName(){
        if(this.pinned_milestone_id == -1){
            return 'No year';
        }
        else{
            if(this.company_milestones) {
                let milestone = this.company_milestones.find( item => item.id == this.pinned_milestone_id);
                if(!milestone){
                    return '';
                }
                else{
                    if(milestone.name != null) {
                        let till = Number(milestone.year) + 1
                        return `${milestone.year} - ${till}`;
                    } else {
                        return `${milestone.year}`;
                    }
                }
            }
            return 'No Year'
            
        }
    },
    canShowComponent(){
        let milestone = this.company_milestones.find( item => item.id == this.pinned_milestone_id);
        return milestone ? true : false;
    },
    hasMilestoneSelected(){
        return this.pinned_milestone_id == -1 ? false : true;
    },
    getYear() {
        let value = ''
        this.year_options.forEach(year => {
            if(year.value == this.selected_year) {
                value = year.text
            }
        })
        return value
    },
    getYearType() {
        let value = ''
        this.year_type_options.forEach(type => {
            if(type.value == this.selected_year_type) {
                value = type.text
            }
        })
        return value
    }
},
methods:{
    ...activityMethods,
    ...scorecardMethods,
    setFilter(value) {
        this.selected_filter = value
    },
    setYearFilter(value) {
        this.selected_year_filter = value
    },
    setMilestoneEvent: _.debounce(function(milestone_id){
        this.setPinnedMilestone(milestone_id);
    }, 50),
    showAddMilestoneModal(milestone_payload, event = null){
        if(event != null){
            event.stopPropagation();
        }
        this.milestone_modal_payload = milestone_payload;
        this.show_modal = true;
        this.$nextTick(()=>{
            this.$bvModal.show('milestoneModal');
            this.$root.$once("bv::modal::hidden", (event) => {
                if (event.type == "hidden" && event.componentId == "milestoneModal") {
                    this.show_modal = false;
                }
            });
        })
        
    },
    getMilestoneString(milestone_object){
        let till = Number(milestone_object.year) + 1
        return `${milestone_object.year} - ${till}`;    
    },
    getMilestoneYear(milestone_object){
            return `${milestone_object.year}`;    
    },
},
mounted(){
    //this.setLoading(false);
    let has_company_level_id = this.company_id != null;
    let has_no_milestones_loaded = this.company_milestones == null;

    if(has_company_level_id && has_no_milestones_loaded){
        this.loadCompanyMilestones(this.company_id).then(()=>{
            //* Get and set the milestone for the current year ( replaced core.default_milestone check)
            this.restoreCurrentYearSelection();
        })
    }
    this.$eventHub.$on('selectedNewMilestone', this.setMilestoneEvent);
},
}

</script>

<style>
.selected_date_style {
border: 2px solid #50A5F1
}
.selected_date_style:focus {
border: 2px solid #50A5F1
}

.scorecard_decorated_select {
    text-align: center; 
    /* color: #CBC3FF !important; */
    border-radius: 0.5rem; 
    font-size: 0.7rem !important;
    width: 100%
}
.scorecard_date_toggle_class {
    text-align: center; ;
    /* color: #CBC3FF !important; */
    border: 1px solid #CBC3FF;
    padding: 1px !important;
    padding-bottom: 4px !important;
    border-radius: 0.5rem; 
    font-size: 0.75rem !important;
    width: 100%
}
.scorecard_custom_date_menu_class {
    /* color: #CBC3FF !important; */
    margin-left: 3px;
    padding: 0px;
    margin-top: 0px !important;
    border: 1px solid #CBC3FF;
    width: 100%
}

/* .decorated_select:hover {
border: 1px solid #CBC3FF
} */
.scorecard_decorated_select:focus {
border: 1px solid #CBC3FF
}
.scorecard_decorated_select .dropdown-toggle {
border: 1px solid #CBC3FF !important;
}
.scorecard_decorated_select .btn-secondary:focus {
box-shadow: none;
}
.scorecard-hex::before {
display: inline-flex;
justify-content: center;
vertical-align: middle;
content: attr(data-content);
padding-top: 6px;
padding-right: 4px;
background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='55' height='55' fill='%23736BA5' stroke='%2350A5F1' stroke-width='0.8' class='bi bi-hexagon-fill' style='transform: rotate(90deg)' viewBox='0 0 17 16'><path fill-rule='evenodd' d='M8.5.134a1 1 0 0 0-1 0l-6 3.577a1 1 0 0 0-.5.866v6.846a1 1 0 0 0 .5.866l6 3.577a1 1 0 0 0 1 0l6-3.577a1 1 0 0 0 .5-.866V4.577a1 1 0 0 0-.5-.866z'/></svg>");
background-repeat: no-repeat;
background-size: 3.1rem 3.1rem;
font-style: initial;
font-size: 24px;
width: 55px;
height: 55px
}
</style>
