<template>
    <div class="element-wrapper d-flex my-auto">
        <span class="element-content">
            <div v-if="showElement" :class="getValidationClass" class="header_value_label_styles">
                <span v-if="prependText">{{ prependText }}&nbsp;&nbsp;</span>
                {{ getValue(meta) }}
                <span v-if="postpendText">{{ postpendText }}</span>
                </div>

        </span>
        <connectionLines class="element-background" v-if="hasConnectionLines(meta)" :connection_lines="meta.connection_lines"></connectionLines>
    </div>
</template>

<script>
import validationRules from '../validationRules.js'
import connectionLines from './sub_elements/svgConnectionLines.vue'
import treeComponentMixin from '@/mixins/treeComponentMixin.js'
export default {
    name: 'header_value_label',
    components:{
        connectionLines
    },
    mixins: [ treeComponentMixin ],
    props:{
        meta:{},
        row_position:{},
    },
    // watch:{
    //     'meta.value'(newValue){
    //         alert(newValue)
    //     }
    // },
    data:() => ({
    }),
    computed:{
        getValidationClass(){
            if(this.meta && this.meta.validation){
                let validation_rule = this.meta.validation
                return validationRules[validation_rule](Number(this.meta.value)) ? '':'header_value_label_error'
            }
            
            return '';
        },
        prependText(){
            return this.meta.prepend_text != undefined ? this.meta.prepend_text : false;
        },
        postpendText(){
            return this.meta.postpend_text != undefined ? this.meta.postpend_text : false;
        },
        showElement(){
            if(this.meta.display_on_first_row){
                // Only show if index is 0
                return this.row_position.index == 0;
            }
            else{
                // Always show
                return true;
            }
        },


        // hasConnectionLines(){
        //     return this.meta.connection_lines != undefined;
        // },

    },
    methods:{},
    mounted(){
        // if(this.showElement){
        //     let index = this.row_position != undefined ? this.row_position.index : 'na';
        //     alert(index + ' - ' +this.meta.value);
        // }
    },
}
</script>

<style>
.header_value_label_styles {
    display: flex;
    margin: 0 auto;
    min-width: 64px;
    align-items: center;
    justify-content: center;
    border: 1px solid #BFC8D6;
    border-radius: 0.3rem;
    background-color: #f4f4f4;
    color: rgb(75, 75, 75);
    height: 20px;
}
.header_value_label_error{
    border-color: #F46A6A;
    border-color: #F46A6A;
}
</style>