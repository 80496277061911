<template>
    <div>
        <pre>{{ getGroupScore('productivity') }}</pre>
        <b-overlay :show="is_loading_details">
            <Renderer :config="root_config" @showDetailedView="handleShowDetailedView"/>
        </b-overlay>
        <DetailsViewContainer ref="details_view" v-if="show_detailed_view" :show="show_detailed_view" @exit="show_detailed_view = false; debug_payload = null">
            <productivity v-if="debug_payload.type == 'productivity'" :viewType="debug_payload.type" @exit="$refs.details_view.handleExit()" />
            <values v-if="debug_payload.type == 'values'"   :viewType="debug_payload.type" @exit="$refs.details_view.handleExit()" />
            <objectives v-if="debug_payload.type == 'objectives'"  :viewType="debug_payload.type" @exit="$refs.details_view.handleExit()" />
        </DetailsViewContainer>
    </div>
    
</template>

<script>
import Renderer from '../../../widgets/treeTable/renderer.vue'
import DetailsViewContainer from "@/components/widgets/containers/detailsOverlayContainer.vue";
import productivity from '../../pageComponents/detailedView/productivity.vue';
import values from '../../pageComponents/detailedView/values.vue'
import objectives from '../../pageComponents/detailedView/objectives.vue'
import { cloneDeep } from 'lodash';
import { scorecardComputed, scorecardMethods, activityComputed } from '@/state/helpers';
export default {
    inject: ['is_values_enabled', 'is_productivity_enabled', 'is_objectives_enabled'],
    components:{
        Renderer,
        DetailsViewContainer,
        productivity,
        objectives,
        values
    },
    data:() => ({
        show_detailed_view: false,
        debug_payload: null,
        is_loading_details: false,
    }),
    watch:{
        debug_payload(newValue){
            let type = newValue != null ? newValue.type : null;

            //* lazy fix for values and objectives not needing the plural name.. oops :)
            if(type == 'values'){ type = 'value'}
            if(type == 'objectives'){ type = 'objective'}
            this.setDetailsPageType(type);
        }
    },
    computed:{
        ...scorecardComputed,
        ...activityComputed,
        getEnabledSectionsText(){
            let sections = [];
            let text_start = '(Sum of : ';
            let text_middle = '';
            let text_end = ')'

            // insert enabled section text
            if(this.is_values_enabled){
                sections.push('Values');
            }
            if(this.is_productivity_enabled){
                sections.push('Productivity');
            }
            if(this.is_objectives_enabled){
                sections.push('Objectives');
            }
            if(sections.length == 0){
                return '';
            }
            
            sections.forEach( (item,idx) =>{
                //logic for adding in commas for multiple items
                text_middle += idx+1 == sections.length ? item : `${item} ,`;
            })

            return `${text_start}
                    ${text_middle}
                    ${text_end}`;
        },
        getSections(){
            let sections = [];
            //* only add enabled sections

            if(this.is_values_enabled){
                sections.push(this.values_config)
            }
            if(this.is_productivity_enabled){
                sections.push(this.productivity_config)
            }
            if(this.is_objectives_enabled){
                sections.push(this.objectives_config)
            }
            return sections.sort((a, b) => a.order - b.order);
        },
        root_config(){
            let cfg = {
                id: 'root_employee_scorecard',
                expanded: true,
                dark_header: true,
                child_type: 'component',
                hide_header: false,
                borders: ['right'],
                columns:[
                    {
                        id: 'name',
                        width: 'fillspace',
                        header: {
                            classes: ['d-flex', 'my-auto' , 'ps-2', 'font-weight-bold', 'font-size-14'],
                            components: [
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        value: 'Total Score',
                                    }
                                },
                            ]
                            
                        },
                        row:{
                            components: [
                                {
                                    component_id: 'text_label',
                                }
                            ],
                        }
                    },
                    {
                        id: 'total_score',
                        width: '370px',                     
                        header: {
                            text: '',
                            classes: ['d-flex', 'ms-auto', 'pe-2'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        no_wrap: true,
                                        value: this.getEnabledSectionsText,
                                    }
                                },
                            ]
                        }
                    }
                ],
                children: this.getSections,
            }

            return cfg;
        },
        objectives_config(){
            let cfg = {
                ref: 'employee_scorecard_objectives',
                id: 'employee_scorecard_objectives',
                expanded: true,
                borders:['right'],
                type: 'objectives',
                child_type: 'row', // component or row
                hide_header: false, // should only check for true and undefined
                columns:[
                    {
                        id: 'expand',
                        width: '25px',
                        header:{
                            classes:['mx-auto', 'my-auto', 'light_pink',],
                            text: '',
                            components: [
                                {
                                    component_id: 'expand_arrow',
                                    meta:{}
                                }
                            ]
                        },
                    },
                    {
                        id: 'name',
                        width: 'fillspace',
                        header: {
                            classes:['d-flex','my-auto','purple_dark', 'ps-1' ,'font-weight-bold', 'font-size-14'],
                            text: '',
                            components: [
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        no_wrap: true,
                                        is_header: true,
                                        value: 'Objectives',
                                    }
                                },
                            ]
                        },
                        row:{
                            classes:['d-flex','w-100','row_borders', 'side_row_borders_left', 'ps-2'],
                            components: [
                                {
                                    component_id: 'text_label',
                                    meta:{

                                    },
                                    variable_id: 'name'
                                }
                            ],
                        }
                    },
                    {
                        id: 'tags',
                        width: '120px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'PROPERTIES & TAGS'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'side_row_borders_left', 'center_badge'],
                            components:[
                                {
                                    component_id: 'tags',
                                    meta:{},
                                    variable_id: 'tags'
                                }
                            ]
                        }
                    },
                    {
                        id: 'status',
                        width: '140px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'REVIEW SUBMISSION STATUS'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'side_row_borders_left', 'center_badge'],
                            components:[
                                {
                                    component_id: 'review_status',
                                    meta:{
                                        viewing_user: this.viewingUserType,
                                    },
                                    variable_id: 'comments'
                                }
                            ]
                        }
                    },
                    {
                        id: 'quarter_1',
                        width: '50px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        is_active: this.isQuarterActive(1),
                                        value: 'Q1'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'side_row_borders_left', 'center_badge'],
                            components:[
                                {
                                    component_id: 'display_badge',
                                    meta:{
                                        rag_enabled: true, 
                                        connection_lines:{
                                            right: true,
                                        },
                                        null_state: true,
                                        config: {low: 3, high: 6}
                                    },
                                    variable_id: 'quarter_1'
                                }
                            ]
                            
                        }
                    },
                    {
                        id: 'quarter_2',
                        width: '50px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        is_active: this.isQuarterActive(2),
                                        value: 'Q2'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'center_badge'],
                            components:[
                                {
                                    component_id: 'display_badge',
                                    meta:{
                                        rag_enabled: true, 
                                        connection_lines:{
                                            right: true,
                                        },
                                        null_state: true,
                                        config: {low: 3, high: 6}
                                    },
                                    variable_id: 'quarter_2'
                                }
                            ]
                            
                        }
                    },
                    {
                        id: 'quarter_3',
                        width: '50px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        is_active: this.isQuarterActive(3),
                                        value: 'Q3'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'center_badge'],
                            components:[
                                {
                                    component_id: 'display_badge',
                                    meta:{
                                        rag_enabled: true, 
                                        connection_lines:{
                                            right: true,
                                        },
                                        null_state: true,
                                        config: {low: 3, high: 6}
                                    },
                                    variable_id: 'quarter_3'
                                }
                            ]
                            
                        }
                    },
                    {
                        id: 'quarter_4',
                        width: '50px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        is_active: this.isQuarterActive(4),
                                        value: 'Q4'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'center_badge'],
                            components:[
                                {
                                    component_id: 'display_badge',
                                    meta:{
                                        rag_enabled: true, 
                                        connection_lines:{
                                            right: true,
                                        },
                                        null_state: true,
                                        config: {low: 3, high: 6}
                                    },
                                    variable_id: 'quarter_4'
                                }
                            ]
                        }
                    },
                    {
                        id: 'score',
                        width: '60px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'SCORE'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'side_row_borders_left', 'center_badge'],
                            components:[
                                {
                                    component_id: 'display_badge',
                                    meta:{
                                        rag_enabled: true, 
                                        connection_lines:{
                                            right: true,
                                        },
                                        config: {low: 3, high: 6}
                                    },
                                    variable_id: 'score'
                                }
                            ]
                        }
                    },
                    {
                        id: 'weighting',
                        width: '65px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'Individual Weighting'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'side_row_borders_left','center_badge'],
                            components:[
                                {
                                    component_id: 'display_badge',
                                    meta:{
                                        
                                    },
                                    variable_id: 'weighting'
                                }
                            ]
                        }
                    },
                    {
                        id: 'group_weight',
                        width: '180px',
                        header:{
                            classes:["d-flex", "ms-auto", 'my-auto'],
                            components:[
                                {
                                    component_id: 'display_badge',
                                    meta:{
                                        use_html: true,
                                        value: this.getGroupWeight('objectives'),
                                    }
                                },
                                {
                                    component_id: 'header_value_label',
                                    meta:{
                                        value: this.getGroupScore('objectives'),
                                        // connection_lines:{
                                        //     left: true,
                                        // }
                                    },
                                    element_classes:['ps-2', 'pe-2']
                                }
                            ]
                        },
                        row:{
                            classes:['side_row_borders_left', 'empty_element'],
                            components:[
                                {
                                    component_id: 'empty_block',
                                    meta:{
                                        
                                    },
                                }
                            ]
                        }
                    }
                ],
                children:this.objective_children,
            }
            return cfg;
        },
        productivity_config(){
            let cfg = {
                ref: 'employee_scorecard_productivity',
                id: 'employee_scorecard_productivity',
                expanded: true,
                borders:['right'],
                type: 'productivity',
                child_type: 'row', // component or row
                hide_header: false, // should only check for true and undefined
                columns:[
                    {
                        id: 'expand',
                        width: '25px',
                        header:{
                            classes:['mx-auto', 'my-auto', 'light_pink'],
                            text: '',
                            components: [
                                {
                                    component_id: 'expand_arrow',
                                    meta:{}
                                }
                            ]
                        },
                    },
                    {
                        id: 'name',
                        width: 'fillspace',
                        header: {
                            classes:['d-flex','my-auto','purple_dark', 'ps-1', 'font-weight-bold', 'font-size-14'],
                            text: '',
                            components: [
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        no_wrap: true,
                                        value: 'Productivity',
                                    }
                                },
                            ]
                        },
                        row:{
                            classes:['d-flex','w-100','row_borders', 'ps-2'],
                            components: [
                                {
                                    component_id: 'text_label',
                                    meta:{

                                    },
                                    variable_id: 'name'
                                }
                            ],
                        }
                    },
                    {
                        id: 'tags',
                        width: '120px',
                        header:{
                            classes:['mx-auto', 'my-auto', 'purple_dark'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'PROPERTIES & TAGS'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders','side_row_borders_left', 'center_badge'],
                            components:[
                                {
                                    component_id: 'tags',
                                    meta:{},
                                    variable_id: 'tags'
                                }
                            ]
                        }
                    },
                    {
                        id: 'status',
                        width: '140px',
                        header:{
                            classes:['mx-auto', 'my-auto', 'purple_dark'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'REVIEW SUBMISSION STATUS'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'side_row_borders_left', 'center_badge'],
                            components:[
                                {
                                    component_id: 'review_status',
                                    meta:{
                                        viewing_user: this.viewingUserType,
                                    },
                                    variable_id: 'comments'
                                }
                            ]
                        }
                    },
                    {
                        id: 'quarter_1',
                        width: '50px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        is_active: this.isQuarterActive(1),
                                        value: 'Q1'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'center_badge'],
                            components:[
                                {
                                    component_id: 'display_badge',
                                    meta:{
                                        rag_enabled: true, 
                                        connection_lines:{
                                            right: true,
                                        },
                                        null_state: true,
                                        config: {low: 3, high: 6}
                                    },
                                    variable_id: 'quarter_1'
                                }
                            ]
                            
                        }
                    },
                    {
                        id: 'quarter_2',
                        width: '50px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        is_active: this.isQuarterActive(2),
                                        value: 'Q2'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'center_badge'],
                            components:[
                                {
                                    component_id: 'display_badge',
                                    meta:{
                                        rag_enabled: true, 
                                        connection_lines:{
                                            right: true,
                                        },
                                        null_state: true,
                                        config: {low: 3, high: 6}
                                    },
                                    variable_id: 'quarter_2'
                                }
                            ]
                            
                        }
                    },
                    {
                        id: 'quarter_3',
                        width: '50px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        is_active: this.isQuarterActive(3),
                                        value: 'Q3'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'center_badge'],
                            components:[
                                {
                                    component_id: 'display_badge',
                                    meta:{
                                        rag_enabled: true, 
                                        connection_lines:{
                                            right: true,
                                        },
                                        null_state: true,
                                        config: {low: 3, high: 6}
                                    },
                                    variable_id: 'quarter_3'
                                }
                            ]
                            
                        }
                    },
                    {
                        id: 'quarter_4',
                        width: '50px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        is_active: this.isQuarterActive(4),
                                        value: 'Q4'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'center_badge'],
                            components:[
                                {
                                    component_id: 'display_badge',
                                    meta:{
                                        rag_enabled: true, 
                                        connection_lines:{
                                            right: true,
                                        },
                                        null_state: true,
                                        config: {low: 3, high: 6}
                                    },
                                    variable_id: 'quarter_4'
                                }
                            ]
                        }
                    },
                    {
                        id: 'score',
                        width: '60px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'Score'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'center_badge'],
                            components:[
                                {
                                    component_id: 'display_badge',
                                    meta:{
                                        rag_enabled: true, 
                                        connection_lines:{
                                            right: true,
                                        },
                                        config: {low: 3, high: 6}
                                    },
                                    variable_id: 'score'
                                }
                            ]
                        }
                    },
                    {
                        id: 'weighting',
                        width: '65px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'Individual Weighting'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'center_badge'],
                            components:[
                                {
                                    component_id: 'display_badge',
                                    meta:{
                                        
                                    },
                                    variable_id: 'weighting'
                                }
                            ]
                        }
                    },
                    {
                        id: 'group_weight',
                        width: '180px',
                        header:{
                            classes:["d-flex", "ms-auto", 'my-auto'],
                            components:[
                                {
                                    component_id: 'display_badge',
                                    meta:{
                                        use_html: true,
                                        value: this.getGroupWeight('productivity'),
                                    }
                                },
                                {
                                    component_id: 'header_value_label',
                                    meta:{
                                        value: this.getGroupScore('productivity'),
                                        // connection_lines:{
                                        //     left: true,
                                        // }
                                    },
                                    element_classes:['ps-2', 'pe-2']
                                }
                            ]
                        },
                        row:{
                            classes:['side_row_borders_left', 'empty_element'],
                            components:[
                                {
                                    component_id: 'empty_block',
                                    meta:{
                                        
                                    },
                                }
                            ]
                        }
                    }
                ],
                children:this.productivity_children,
            }
            return cfg;
        },
        values_config(){
            let cfg = {
                ref: 'employee_values_objectives',
                id: 'employee_values_objectives',
                expanded: true,
                borders:['right'],
                type: 'values',
                child_type: 'row', // component or row
                hide_header: false, // should only check for true and undefined
                columns:[
                    {
                        id: 'expand',
                        width: '25px',
                        header:{
                            classes:['mx-auto', 'my-auto', 'light_pink'],
                            text: '',
                            components: [
                                {
                                    component_id: 'expand_arrow',
                                    meta:{}
                                }
                            ]
                        },
                    },
                    {
                        id: 'name',
                        width: 'fillspace',
                        header: {
                            classes:['d-flex','my-auto','purple_dark', 'ps-1' , 'font-weight-bold', 'font-size-14'],
                            text: '',
                            components: [
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        no_wrap: true,
                                        value: 'Values',
                                    }
                                },
                            ]
                        },
                        row:{
                            classes:['d-flex','w-100','row_borders', 'ps-2'],
                            components: [
                                {
                                    component_id: 'text_label',
                                    meta:{

                                    },
                                    variable_id: 'value'
                                }
                            ],
                        }
                    },
                    {
                        id: 'tags',
                        width: '120px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'PROPERTIES & TAGS'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'center_badge'],
                            components:[
                                {
                                    component_id: 'tags',
                                    meta:{},
                                    variable_id: 'tags'
                                }
                            ]
                        }
                    },
                    {
                        id: 'status',
                        width: '140px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'REVIEW SUBMISSION STATUS'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'center_badge'],
                            components:[
                                {
                                    component_id: 'review_status',
                                    meta:{
                                        viewing_user: this.viewingUserType,
                                    },
                                    variable_id: 'comments'
                                }
                            ]
                        }
                    },
                    {
                        id: 'quarter_1',
                        width: '50px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        is_active: this.isQuarterActive(1),
                                        value: 'Q1'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'center_badge'],
                            components:[
                                {
                                    component_id: 'display_badge',
                                    meta:{
                                        rag_enabled: true, 
                                        connection_lines:{
                                            right: true,
                                        },
                                        null_state: true,
                                        config: {low: 3, high: 6}
                                    },
                                    variable_id: 'quarter_1'
                                }
                            ]
                            
                        }
                    },
                    {
                        id: 'quarter_2',
                        width: '50px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'Q2',
                                        is_active: this.isQuarterActive(2)
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'center_badge'],
                            components:[
                                {
                                    component_id: 'display_badge',
                                    meta:{
                                        rag_enabled: true, 
                                        connection_lines:{
                                            right: true,
                                        },
                                        null_state: true,
                                        config: {low: 3, high: 6}
                                    },
                                    variable_id: 'quarter_2'
                                }
                            ]
                            
                        }
                    },
                    {
                        id: 'quarter_3',
                        width: '50px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        is_active: this.isQuarterActive(3),
                                        value: 'Q3'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'center_badge'],
                            components:[
                                {
                                    component_id: 'display_badge',
                                    meta:{
                                        rag_enabled: true, 
                                        connection_lines:{
                                            right: true,
                                        },
                                        null_state: true,
                                        config: {low: 3, high: 6}
                                    },
                                    variable_id: 'quarter_3'
                                }
                            ]
                            
                        }
                    },
                    {
                        id: 'quarter_4',
                        width: '50px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        is_active: this.isQuarterActive(4),
                                        value: 'Q4'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'center_badge'],
                            components:[
                                {
                                    component_id: 'display_badge',
                                    meta:{
                                        rag_enabled: true, 
                                        connection_lines:{
                                            right: true,
                                        },
                                        null_state: true,
                                        config: {low: 3, high: 6}
                                    },
                                    variable_id: 'quarter_4'
                                }
                            ]
                        }
                    },
                    {
                        id: 'score',
                        width: '60px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'Score'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'side_row_borders_left', 'center_badge'],
                            components:[
                                {
                                    component_id: 'display_badge',
                                    meta:{
                                        rag_enabled: true, 
                                        connection_lines:{
                                            right: true,
                                        },
                                        config: {low: 3, high: 6}
                                    },
                                    variable_id: 'score'
                                }
                            ]
                        }
                    },
                    {
                        id: 'weighting',
                        width: '65px',
                        header:{
                            classes:['mx-auto', 'my-auto'],
                            components:[
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        is_header: true,
                                        value: 'Individual Weighting'
                                    }
                                }
                            ]
                        },
                        row:{
                            classes:['d-flex', 'row_borders', 'side_row_borders_left', 'center_badge'],
                            components:[
                                {
                                    component_id: 'display_badge',
                                    meta:{
                                        
                                    },
                                    variable_id: 'weighting'
                                }
                            ]
                        }
                    },
                    {
                        id: 'group_weight',
                        width: '180px',
                        header:{
                            classes:["d-flex", "ms-auto", 'my-auto'],
                            components:[
                                {
                                    component_id: 'display_badge',
                                    meta:{
                                        use_html: true,
                                        value: this.getGroupWeight('values'),
                                    }
                                },
                                {
                                    component_id: 'header_value_label',
                                    meta:{
                                        value: this.getGroupScore('values'),
                                        // connection_lines:{
                                        //     left: true,
                                        // }
                                    },
                                    element_classes:['ps-2', 'pe-2']
                                }
                            ]
                        },
                        row:{
                            classes:['side_row_borders_left', 'empty_element'],
                            components:[
                                {
                                    component_id: 'empty_block',
                                    meta:{
                                        
                                    },
                                }
                            ]
                        }
                    }
                ],
                children: this.values_children,
            }
            return cfg;
        },
        
        objective_children(){
            // * get objecives array from sections array inside template
            let children = [];
            if(this.loaded_user_scorecard){
                let section_index = this.loaded_user_scorecard.sections.findIndex( section => {
                    return section.type_name == 'objectives';
                })
                if(section_index != -1){
                    children = cloneDeep(this.loaded_user_scorecard.sections[section_index].objectives)
                    children = children.filter(( child )=> child.priority != null);
                }
            }
            children.forEach( child => {
                this.processScores(child)
            })
            return children;
        },
        productivity_children(){
            // * get objecives array from sections array inside template
            let children = [];
            console.log("Children,",this.loaded_user_scorecard);
            if(this.loaded_user_scorecard){
                let section_index = this.loaded_user_scorecard.sections.findIndex( section => {
                    return section.type_name == 'productivity';
                })
                if(section_index != -1){
                    children = cloneDeep(this.loaded_user_scorecard.sections[section_index].targets)
                }
            }
            children.forEach( child => {
                this.processScores(child);
            })
            return children;
        },
        values_children(){
            let children = [];
            if(this.loaded_user_scorecard){
                let section_index = this.loaded_user_scorecard.sections.findIndex( section => {
                    return section.type_name == 'values';
                })
                if(section_index != -1){
                    children = cloneDeep(this.loaded_user_scorecard.sections[section_index].values)
                }
            }
            children.forEach( child => {
                this.processScores(child)
            })
            return children;
        },
        debug_children(){
            let row = {
                "id":580,
                "level_milestone_id":27,
                "level_id":57,
                "parent_objective_id":577,
                "name":"Formal and informal team building",
                "status":"0",
                "year":"-1",
                "quarter":"2024-01-01T00:00:00.000Z",
                "month":"-1",
                "item_order":null,
                "start_date":"2024-01-01 00:00:00",
                "objective_plan":null,
                "guideline":null,
                "deadline":"2024-03-31 00:00:00",
                "draft":0,
                "owner_name":"Gemma",
                "owner_user_id":40,
                "created_at":"2024-01-29T22:15:42.000000Z",
                "updated_at":"2024-10-04T07:56:21.000000Z",
                "notes":"",
                "objective_type":"goal",
                "deleted_at":null,
                "wrap_up_notes":null,
                "category":null,
                "locked":0,
                "scoring_year":"2024",
                "scoring_time_period":"quarter",
                "scoring_period_key":"4",
                "scores":{"1":13,"2":13,"3":13,"4":13},
                "comments":{"employee":{"status":null},"manager":{"status":null}},
                "tags":["No Category","1 Children","4 Key Results"]}

            return row;
        }
    },
    methods:{
        ...scorecardMethods,
        handleShowDetailedView(payload){
            this.is_loading_details = true;
            this.debug_payload = payload;
            console.log(payload)
            let params = {};
            switch(this.debug_payload.type){
                case 'productivity':{
                    params = {target_id: payload.id, scorecard_period_id: this.loaded_user_scorecard.scorecard_period_id};
                    this.loadProductivityDetails(params).then(() => {
                        this.show_detailed_view = true;
                        this.is_loading_details = false;
                    })
                    break;
                }
                case 'values':{
                    params = {value_id: payload.id, scorecard_period_id: this.loaded_user_scorecard.scorecard_period_id};
                    this.loadValuesDetails(params).then(() => {
                        this.show_detailed_view = true;
                        this.is_loading_details = false;
                    })
                    break;
                }
                case 'objectives':{
                    params = {objective_id: payload.id, scorecard_period_id: this.loaded_user_scorecard.scorecard_period_id};
                    this.loadObjectiveDetails(params)
                    .then(() => {
                        this.show_detailed_view = true;
                        this.is_loading_details = false;
                    })
                    break;
                }
            } 
        },
        getScoreTotal(child){
            switch(child.scoring_time_period){
                case 'quarter':{
                    return child[`quarter_${child.scoring_period_key}`];
                }
                default: {
                    return 0
                }
            }
        },
        processScores(child){
            
            //* loop through quarters score object via index as key
            for( let i = 1; i <= 4; i++){
                let quarter_value = child.scores[`${i}`];
                //* set quarter to its point value
                quarter_value = quarter_value == 0 ? quarter_value : quarter_value/10;
                //* finally set quarter value to the matching variable key

                // if iterated quarter == the selected quarter - set value - else set to null to draw dashed line
                // if(i == child.scoring_period_key){
                //     child[`quarter_${i}`] = quarter_value;
                // }
                // else{
                //     child[`quarter_${i}`] = null;
                // }
                child[`quarter_${i}`] = quarter_value * 10;
            }
            child.score = this.getScoreTotal(child);
        },
        getGroupScore(type){
            let value = 0;
            let section = this.loaded_user_scorecard.sections.find( section => {
                return section.type_name == type;
            })
            if(section){
                // lazy fix for badge rendering 0 as empty
                value = section.group_score != 0 ? section.group_score : ' 0';
            }
            return value;
        },
        getGroupWeight(type){
            let value = 0;
            let section = this.loaded_user_scorecard.sections.find( section => {
                return section.type_name == type;
            })
            if(section){
                value = section.section_weighting
            }

            let html = `<span class="d-flex my-auto">
                            <div class="d-flex flex-column badge-small-text">
                                <div>GROUP</div>
                                <div>WEIGHT</div>
                            </div>
                            <div class="ms-2 my-auto">
                                X <strong>${value}%</strong>
                            </div>
                        </span>`;
            return html;
        },
        getComponentData(){
            let payload = {};
            if(this.is_values_enabled){
                payload['value_data'] = this.values_children;
            }
            if(this.is_productivity_enabled){
                payload['productivity_data'] = this.productivity_children;
            }
            if(this.is_objectives_enabled){
                payload['objective_data'] = this.objective_children;
            }
            return payload;
        },
        isQuarterActive(quarter){
            let scorecard_period_type = this.loaded_user_scorecard.scorecard_period_type;
            let scorecard_period_key = this.loaded_user_scorecard.scorecard_period_key;

            switch(scorecard_period_type){
                case 'quarter':{
                    return quarter == scorecard_period_key
                }
                case 'half_year':{
                    if((quarter == 1 || quarter == 2 ) && scorecard_period_key == 1 ){
                        return true;
                    }
                    else if((quarter == 3 || quarter == 4 ) && scorecard_period_key == 2 ){
                        return true;
                    }
                    return false;
                }
                case 'year':{
                    return true;
                }
                default:{
                    //* should never be this case
                    console.log('Unhandled case for isActiveQuarter');
                    return false;
                }
            }
        },
    },
    mounted(){
        
    },
}
</script>

<style>
.badge-small-text>div{
    font-size: 0.575rem;
    line-height: 0.575rem;
}
.badge-small-text{
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
}

</style>