<template>
    <div>
        <Renderer :config="modifiers_config" @dataUpdateEvent="handleUpdateEvent"/>
        <b-overlay :show="is_loading" no-wrap></b-overlay>
    </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { scorecardComputed, scorecardMethods } from '@/state/helpers';
import Renderer from '../../../widgets/treeTable/renderer.vue';
export default {
    props:{
        ref_value:{
            default: () => {
                return window.webix.uid();
            },
        }
    },
    components:{
        Renderer,
    },
    data:() => ({
        modifiers_children: [],
        is_loading: false,
    }),
    computed:{
        modifiers_average(){
            // total of percent where the enabled value is yes
            let total = 0;
            let count = 0;
            this.modifiers_children.forEach( item => {
                if(item.saved_value){
                    total += item.percentage;
                    count++;
                }
            })
            if(count > 0 && total > 0){
                total = total / count;
            }
            else{
                total = ' 0';
            }
            return total;
        },
        ...scorecardComputed,
        //modifiers_children(){
        //    return cloneDeep(this.loaded_user_scorecard.modifiers);
        //},
        modifiers_config(){
            let cfg = {
                id: 'modifiers',
                expanded: true,
                dark_header: true,
                child_type: 'row',
                borders:['right'],
                editable: true,
                include_empty_row: false,
                empty_row_structure:{
                    id: -1,
                    name: '',
                    name2: '', 
                },
                columns:[
                    {
                        id: 'name',
                        width: 'fillspace',
                        header: {
                            classes: ['d-flex', 'w-100', 'my-auto' , 'ps-2'],
                            components: [
                                {
                                    component_id: 'text_label',
                                    meta:{
                                        no_wrap: true,
                                        value: 'Modifiers',
                                    }
                                },
                                {
                                    component_id: 'display_badge',
                                    meta:{
                                        value: this.modifiers_average
                                    },
                                    element_classes:['ms-auto', 'me-2']
                                }
                            ]
                            
                        },
                        row:{
                            classes: ['scorecard_row_width', 'ps-1'],
                            components: [
                                {
                                    component_id: 'text_input',
                                    variable_id: 'modifier_text',
                                },
                                {
                                    component_id: 'toggle_label',
                                    meta:{},
                                    variable_id: 'saved_value',
                                    element_classes: ['ms-auto','me-1']
                                },
                                {
                                    component_id: 'text_input',
                                    variable_id: 'percentage',
                                    meta:{
                                        placeholder: ' - % ',
                                        width: '40'
                                    },
                                    element_classes:['me-1', 'pe-2', 'end_row_border']
                                },
                            ],
                        }
                    },
                ],
                children: this.modifiers_children,
            }
            return cfg;
        },
    },
    methods:{
        ...scorecardMethods,
        returnComponentData(){
            return this.$refs[this.ref_value].getComponentData();
        },
        handleUpdateEvent(event){
            let index = event.data.row_index;
            let variable = event.data.variable_id;
            let value = event.data.value;
            this.$set(this.modifiers_children[index], variable, value );

            //* save to backend
            let params = {
                scorecard_period_id: this.loaded_user_scorecard.scorecard_period_id,
                value: value ? 'yes' : 'no',
                item_type: 'modifier',
                item_id: this.modifiers_children[index].id,
                percentage: this.modifiers_children[index].percentage,
            }

            this.is_loading = true;
            this.saveScorecardData(params)
            .then(()=>{
                this.is_loading = false;
            })
            .catch(()=>{
                this.is_loading = false;
            })
        }
    },
    mounted(){
        this.modifiers_children = cloneDeep(this.loaded_user_scorecard.modifiers);
    },
}
</script>

<style>

</style>